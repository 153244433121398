export const handleEnterKeyPress = (handleEnter, e, ...rest) => {
  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
    handleEnter(...rest);
  }
};

export const handleFocus = (e) => {
  e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const focusOnNextRef = (inputRefs, currIndex, handleLastInput) => {
  if (currIndex + 1 < inputRefs.current.length) {
    const nextIndex = currIndex + 1;
    inputRefs.current[nextIndex].focus();
  } else {
    handleLastInput();
  }
};
