export const breakpoints = ['768px', '1024px', '1400px', '1920px'];

[breakpoints.sm, breakpoints.md, breakpoints.lg, breakpoints.xl] = breakpoints;

export const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints.sm})`,
  medium: `@media screen and (min-width: ${breakpoints.md})`,
  large: `@media screen and (min-width: ${breakpoints.lg})`,
  xlarge: `@media screen and (min-width: ${breakpoints.xl})`,
};
