import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledColumn = styled.div`
  ${css({
    alignItems: 'center',
    display: 'flex',
    '&.start': {
      justifyContent: 'flex-start',
    },
    '&.center': {
      justifyContent: 'center',
    },
    '&.end': {
      justifyContent: 'flex-end',
    },
  })}
`;
export const StyledHeader = styled.header(
  css({
    color: 'buttercream',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    height: '48px',
    justifyContent: 'center',
    width: '100vw',
    zIndex: 'overlay',
    '&.blue': {
      bg: 'rxBrandBlue',
    },
    '&.black': {
      bg: 'coldBrews.900',
    },
  })
);
export const StyledIcon = styled.a(
  css({
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'default',
    fontSize: 'medium',
    fontWeight: 'black',
    justifyContent: 'middle',
    pl: ['small', 'xLarge'],
  })
);
export const StyledTypography = styled.p(
  css({
    alignItems: 'center',
    color: 'buttercream',
    display: ['none', 'flex'],
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  })
);
