import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import theme from '../../../themes';

import { Box } from '../../Box';
import { Button } from './styles';
import Icon from '../../Icon';
import Typography from '../../Typography';

const Header = forwardRef(({ children, withClose = false, handleClose, px = 'xLarge' }, ref) => {
  const RenderClose = () => (
    <Box
      alignItems="center"
      display="flex"
      height="xxLarge"
      justifyContent="center"
      ml="xLarge"
      width="xxLarge"
    >
      <Button type="button" onClick={handleClose}>
        <Icon
          color={theme.colors.rxInteractionBlue}
          hoverColor={theme.colors.rxBrandBlue}
          icon="close"
          title="close"
        />
      </Button>
    </Box>
  );
  return (
    <Box
      bg="buttercream"
      display="flex"
      justifyContent="space-between"
      p="xLarge"
      pb="large"
      px={px}
      position="sticky"
      top="0"
      zIndex="top"
      ref={ref}
    >
      <Typography variant={['primaryLarge']}>{children}</Typography>
      {withClose && <RenderClose />}
    </Box>
  );
});

Header.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func,
  px: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  withClose: PropTypes.bool,
};

export default Header;
