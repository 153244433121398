import React from 'react';
import PropTypes from 'prop-types';
import { Box, FlexBox } from '../Box';
import Typography from '../Typography';
import Button from '../Button';
import Card from '../Card';
import { StyledContainer, StyledLink } from './styles';
import Icon from '../Icon';
import { useClientStore } from '../../store/store';
import { L10n } from '../L10n';
import colors from '../../themes/keys/colors';
import { formatTime } from '../../utils/formatTime';
import FormatDate from '../FormatDate';
import FormatDayOfWeek from '../FormatDayOfWeek';

const KM_TO_MI_CONVERSION = 1.609344;
const APPT_HOLD_TIME = 20;

const SearchResultCard = ({ pharmacy, filteredProducts }) => {
  const { apptInfo, hideModal, showModal, updateModal, uiState } = useClientStore((state) => ({
    apptInfo: state.apptDetails,
    hideModal: state.hideModal,
    showModal: state.showModal,
    updateModal: state.updateModal,
    uiState: state.uiState,
  }));
  const selected = apptInfo.FSEvent__c?.Id === pharmacy.event.Id;
  const openDateTimeModal = () => {
    updateModal('dateTimeSelectionModal', {
      handleClose: hideModal,
      locationData: pharmacy.event,
      filteredProducts,
    });
    showModal();
  };
  const apptTime = apptInfo.Appointment_Time__c.replace(' ', '')
    .replace('AM', 'am')
    .replace('PM', 'pm')
    .replace(/^0+/, '');
  const getTime = () => {
    if (apptInfo.FS_Session_Hold__c.Expiration__c) {
      return new Date(apptInfo.FS_Session_Hold__c.Expiration__c);
    }
    const time = new Date();
    return new Date(time.setMinutes(time.getMinutes() + APPT_HOLD_TIME));
  };

  return (
    <Card variant={selected ? 'successOutline' : 'muted'} margin="0" borderRadius="small">
      <StyledContainer data-testid="result-card">
        <Box display="grid" gridGap="small" gridColumn={['1/-1', '1/3']}>
          <Box display="grid" gridGap="xxSmall">
            <Typography tag="h1" variant={['secondaryBase']}>
              {pharmacy.event.Name}
            </Typography>
            {pharmacy.distance !== undefined && (
              <Typography tag="h2" variant={['primarySmall']}>
                {(pharmacy.distance / KM_TO_MI_CONVERSION).toFixed(1)}{' '}
                <L10n tokenID="general.miles" />
              </Typography>
            )}
          </Box>
          <Box display={['grid', 'flex']} gridGap={['8px', '16px']}>
            <Box maxWidth="250px">
              <Typography tag="p" variant={['primaryMedium']}>
                {pharmacy.event.Event_Address__c.replace(/-\d{4}$/, '')}
              </Typography>
            </Box>
            <Box display={['none', 'block']} width="1px" height="100%" bg="coldBrews.200" />
            <StyledLink
              href={`tel:${
                pharmacy.event.Location__r?.Pharmacy_Phone__c || pharmacy.event.Phone_Number__c
              }`}
              target="_blank"
            >
              <Typography tag="p" variant={['primaryMedium']}>
                {pharmacy.event.Location__r?.Pharmacy_Phone__c || pharmacy.event.Phone_Number__c}
              </Typography>
            </StyledLink>
          </Box>
        </Box>
        {!selected && (
          <FlexBox flexDirection="column-reverse" gridColumn={['1/-1', '3/3']}>
            <Button styling="outlined" size="tertiaryFill" handleClick={openDateTimeModal}>
              <L10n tokenID="page.location_selection.select_pharmacy" />
            </Button>
          </FlexBox>
        )}
        {selected && (
          <>
            <FlexBox gridColumn={['1/4']} alignItems="center" justifyContent="space-between">
              <FlexBox gridGap="small" alignItems="center">
                <Icon scale={[0.8, 1]} color={colors.hatch} icon="checkCircle" />
                {uiState.langPref === 'en-us' ? (
                  <Typography tag="p" variant={['secondaryMedium']} color={{ color: colors.hatch }}>
                    {apptInfo.Appointment_Date__c}{' '}
                    <L10n tokenID="page.location_selection.at_time" values={{ time: apptTime }} />
                  </Typography>
                ) : (
                  <Typography tag="p" variant={['secondaryMedium']} color={{ color: colors.hatch }}>
                    <FormatDayOfWeek dateStr={apptInfo.Appointment_Date__c} format="long" />{' '}
                    <FormatDate dateStr={apptInfo.Appointment_Date__c} format="full" />{' '}
                    <L10n tokenID="page.location_selection.at_time" values={{ time: apptTime }} />
                  </Typography>
                )}
              </FlexBox>
              <Button size="noPaddingTertiary" styling="plainBlue" handleClick={openDateTimeModal}>
                <Typography tag="p" variant={['primarySmall']} color={{ color: 'highlight' }}>
                  <L10n tokenID="general.change" />
                </Typography>
              </Button>
            </FlexBox>
            <Box gridColumn={['1/-1']}>
              <Typography tag="p" variant={['primaryMedium']}>
                <L10n tokenID="search_result.appointment_hold" /> {formatTime(getTime())}
              </Typography>
            </Box>
          </>
        )}
      </StyledContainer>
    </Card>
  );
};

SearchResultCard.propTypes = {
  pharmacy: PropTypes.shape({
    event: PropTypes.shape({
      attributes: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
      }),
      Id: PropTypes.string,
      Name: PropTypes.string,
      Phone_Number__c: PropTypes.string,
      Location__c: PropTypes.string,
      Event_Address__c: PropTypes.string,
      Location__r: PropTypes.shape({
        attributes: PropTypes.shape({
          type: PropTypes.string,
          url: PropTypes.string,
        }),
        Id: PropTypes.string,
        Geolocation__Latitude__s: PropTypes.number,
        Geolocation__Longitude__s: PropTypes.number,
        Street_One__c: PropTypes.string,
        Pharmacy_Phone__c: PropTypes.string,
        Primary_City__c: PropTypes.string,
        State_Text__c: PropTypes.string,
        Postal_Code_Text__c: PropTypes.string,
      }),
    }),
    distance: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.string),
  }),
  // eslint-disable-next-line
  filteredProducts: PropTypes.object,
};

export default SearchResultCard;
