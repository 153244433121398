export const vaccineLinks = {
  chickenpox: 'https://www.heb.com/pharmacy/vaccinations#chickenpox',
  cholera: 'https://www.heb.com/pharmacy/vaccinations#cholera',
  'COVID-19': 'https://www.heb.com/pharmacy/vaccinations#covid-19',
  flu: 'https://www.heb.com/pharmacy/vaccinations#flu',
  'hepatitis A': 'https://www.heb.com/pharmacy/vaccinations#hepatitis-a',
  'hepatitis B': 'https://www.heb.com/pharmacy/vaccinations#hepatitis-b',
  'hepatitis A/B': 'https://heb.com/pharmacy/vaccinations#hepatitis-a-b',
  HPV: 'https://www.heb.com/pharmacy/vaccinations#hpv',
  meningitis: 'https://www.heb.com/pharmacy/vaccinations#meningitis',
  'measles, mumps, and rubella (MMR)': 'https://www.heb.com/pharmacy/vaccinations#mmr',
  pneumonia: 'https://www.heb.com/pharmacy/vaccinations#pneumonia',
  RSV: 'https://www.heb.com/pharmacy/vaccinations#rsv',
  'senior flu': 'https://www.heb.com/pharmacy/vaccinations#flu',
  shingles: 'https://www.heb.com/pharmacy/vaccinations#shingles',
  'tetanus and diphtheria (Td)': 'https://www.heb.com/pharmacy/vaccinations#tdap',
  'tetanus, diphtheria, and pertussis (Tdap)': 'https://www.heb.com/pharmacy/vaccinations#tdap',
};
