/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';
import { Box } from '../../components/Box';
import { L10n } from '../../components/L10n';
import Button from '../../components/Button';
import { RadioGroup } from '../../components/Radio';
import { useClientStore } from '../../store/store';
import PageNavigation from '../../components/PageNavigation';
import { pregnancyRestrictedVaccines } from '../../utils/vaccineSpecifications/pregnancyRestrictedVaccines';
import { removeSpecificVaccines } from '../../utils/removeSpecificVaccines';

const IsPregnant = () => {
  const intl = useIntl();
  const {
    apptState,
    patientIsPregnant,
    updateApptState,
    updatePatientStore,
    updateUiState,
  } = useClientStore((state) => ({
    apptState: state.apptDetails,
    patientIsPregnant: state.patient.isPregnant,
    updateApptState: state.updateApptDetails,
    updatePatientStore: state.updatePatientInfo,
    updateUiState: state.updateUiState,
  }));
  const selectedVaccines = apptState.products;
  const [inputState, setInputState] = useState({
    isPregnant: { value: patientIsPregnant, hasError: false, error: '' },
  });

  const restrictedVaccinesSelected = () => {
    const hasRestricted = Object.keys(selectedVaccines).some(
      (vax) => vax in pregnancyRestrictedVaccines
    );
    const allRestricted = Object.keys(selectedVaccines).every((vax) => {
      return vax in pregnancyRestrictedVaccines;
    });
    return {
      hasRestricted,
      allRestricted,
    };
  };

  const restrictedSelections = Object.keys(selectedVaccines)
    .filter((vax) => vax in pregnancyRestrictedVaccines)
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: selectedVaccines[key] });
    }, {});

  const vaccineNames = Object.values(restrictedSelections).map((vaccine, index) => {
    return (
      <>
        <L10n key={vaccine.Name} tokenID={`vaccine.${vaccine.Label__c}`} />
        {index === Object.keys(restrictedSelections).length - 2 &&
        Object.keys(restrictedSelections).length > 2
          ? `, ${intl.formatMessage({ id: 'general.and' })} `
          : index === Object.keys(restrictedSelections).length - 2
          ? ` ${intl.formatMessage({ id: 'general.and' })} `
          : index < Object.keys(restrictedSelections).length - 1
          ? ', '
          : ''}
      </>
    );
  });

  const restrictedInfoByte = {
    info: (
      <Typography variant="primaryMedium">
        <L10n
          tokenID="page.eligibility.pregnancy_warning"
          values={{ vaccines: Object.keys(restrictedSelections).length, vaccineNames }}
        />
      </Typography>
    ),
    variant: 'warning',
  };

  const goToDobPage = () => {
    updateUiState('currentPage', 0);
    updateApptState('products', {});
  };

  const allRestrictedInfoByte = {
    info: (
      <Typography tag="p" variant="primaryMedium">
        <L10n
          tokenID="page.eligibility.pregnancy_error"
          values={{
            vaccines: Object.keys(restrictedSelections).length,
            vaccineNames,
            link: (
              <Button handleClick={goToDobPage} size="noPadding" styling="transparent">
                <Typography tag="p" variant="underlineMedium" color={{ color: 'highlight' }}>
                  <L10n tokenID="page.eligibility.link" />
                </Typography>
              </Button>
            ),
          }}
        />
      </Typography>
    ),
    variant: 'error',
  };

  const infoByte = () => {
    const { hasRestricted, allRestricted } = restrictedVaccinesSelected();
    if (hasRestricted) {
      if (allRestricted) {
        return allRestrictedInfoByte;
      }
      return restrictedInfoByte;
    }
    return null;
  };

  const radioOptions = {
    uniqueId: 'radio1',
    choices: [
      {
        label: <L10n tokenID="general.yes" />,
        value: 'yes',
        info: infoByte()?.info,
        infoVariant: infoByte()?.variant,
      },
      {
        label: <L10n tokenID="general.no" />,
        value: 'no',
      },
    ],
  };

  const validateInput = (value, error = <L10n tokenID="page.eligibility.required" />) => {
    return (!value && { hasError: true, error }) || { hasError: false, error: null };
  };

  const handleRadioChange = (event) => {
    const { value } = event.target.value ? event.target : event.target.dataset;
    const { hasError, error } = validateInput(value);
    setInputState({
      ...inputState,
      isPregnant: {
        ...inputState.isPregnant,
        value,
        hasError,
        error,
      },
    });
    updatePatientStore('isPregnant', value);
  };

  const getEligibleVaccines = () => {
    const eligibleVaccines = removeSpecificVaccines(selectedVaccines, null, restrictedSelections);
    return eligibleVaccines;
  };

  const handleNext = () => {
    const { hasError, error } = validateInput(inputState.isPregnant.value);
    setInputState({
      ...inputState,
      isPregnant: {
        ...inputState.isPregnant,
        hasError,
        error,
      },
    });
    if (!hasError) {
      const eligibleVaccines =
        inputState.isPregnant.value === 'yes' ? getEligibleVaccines() : selectedVaccines;
      if (Object.keys(eligibleVaccines).length > 0) updateApptState('products', eligibleVaccines);
      updateUiState('validPage', true);
    }
  };

  return (
    <>
      <RedirectHandler pageNumber={2} />
      <Box
        data-testid="isPregnant"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
      >
        <Box display="grid" gridGap="large">
          <Typography tag="h1" variant={['primaryXLarge', 'primaryWeb3']}>
            <L10n tokenID="page.eligibility.header" />
          </Typography>
          <Typography tag="p" variant={['primaryBase']}>
            <L10n tokenID="page.eligibility.subheader" />
          </Typography>
        </Box>
        <Box display="grid" gridGap="xLarge">
          <RadioGroup
            label={<L10n tokenID="page.eligibility.pregnant" />}
            options={radioOptions}
            onChange={handleRadioChange}
            selectedOption={inputState.isPregnant.value}
            name="isPregnant"
            error={inputState.isPregnant.error}
          />
        </Box>
        <PageNavigation
          handleNext={handleNext}
          disableNext={
            Object.keys(getEligibleVaccines()).length <= 0 && inputState.isPregnant.value === 'yes'
          }
        />
      </Box>
    </>
  );
};

export default IsPregnant;
