import holdCard from './holdCard/translations.json';
import footer from './footer/translations.json';

export default {
  'en-us': {
    ...footer['en-us'],
    ...holdCard['en-us'],
  },
  'es-us': {
    ...footer['es-us'],
    ...holdCard['es-us'],
  },
};
