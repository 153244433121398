import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import { FieldGroup } from '../../components/FieldGroup';
import PageNavigation from '../../components/PageNavigation';
import { Box } from '../../components/Box';
import { useClientStore } from '../../store/store';
import { L10n } from '../../components/L10n';
import { validators } from './validation';
import { CenteredContent, StyledSpan } from './styles';
import Typography from '../../components/Typography';
import { useEventQueryById } from '../../api/Event';
import Link from '../../components/Link';
import { handleEnterKeyPress } from '../../utils/common';

const DateOfBirth = () => {
  const {
    patientDetails,
    reset,
    updatePatientInfo,
    updateUiState,
    updateApptDetails,
  } = useClientStore((state) => ({
    patientDetails: state.patient,
    reset: state.reset,
    updatePatientInfo: state.updatePatientInfo,
    updateUiState: state.updateUiState,
    updateApptDetails: state.updateApptDetails,
  }));
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get('eventId');
  const { data: eventData, isSuccess } = useEventQueryById(eventId);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob.replace(/-/g, '/'));
    let ageNow = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    ageNow = m < 0 || (m === 0 && today.getDate() < birthDate.getDate()) ? ageNow - 1 : ageNow;
    return ageNow;
  };
  const [inputState, setInputState] = useState({
    Birthdate__c: {
      value: patientDetails.Birthdate__c,
      hasError: false,
      error: '',
      node: null,
    },
  });
  const [currentDob, setCurrentDob] = useState(patientDetails.Birthdate__c);

  const intl = useIntl();

  const validateInput = (input, validator, value = null, errorMsg = null) => {
    let hasError = false;
    let error = '';
    if (!validator && input?.value === '' && input?.required) {
      hasError = true;
      error = intl.formatMessage(
        { id: 'general.field_error.valid_data_error' },
        { data: intl.formatMessage({ id: 'general.date_of_birth_lowercase' }) }
      );
    } else if (validator) {
      ({ hasError, error } = validator(value, input?.required, errorMsg));
    }
    const age = calculateAge(input.value);
    if (age < 3) {
      hasError = true;
      error = intl.formatMessage({ id: `page.dob.patientTooYoungError` });
    } else {
      updatePatientInfo('Age', age.toString());
    }
    return {
      hasError,
      error,
    };
  };

  const handleInputValidation = (event) => {
    const field = event.target.id;
    const { hasError, error } = validateInput(
      event.target,
      validators[field],
      inputState[field].value,
      intl.formatMessage(
        { id: 'general.field_error.valid_data_error' },
        { data: intl.formatMessage({ id: 'general.date_of_birth_lowercase' }) }
      )
    );
    setInputState({
      ...inputState,
      [field]: {
        ...inputState[field],
        hasError,
        error,
        node: event.target,
      },
    });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const field = event.target.id;
    setInputState({
      ...inputState,
      [field]: {
        value,
      },
    });
    updatePatientInfo(field, value);
  };

  const handleNext = () => {
    if (currentDob !== inputState.Birthdate__c.value && currentDob !== null && currentDob !== '') {
      setCurrentDob(inputState.Birthdate__c.value);
      reset();
      updatePatientInfo('Birthdate__c', inputState.Birthdate__c.value);
    }
    let currentState = { ...inputState };
    const node = document.querySelector('[name="Birthdate__c"]');
    const { error, hasError } = validateInput(
      node,
      validators.Birthdate__c,
      inputState.Birthdate__c.value,
      intl.formatMessage(
        { id: 'general.field_error.valid_data_error' },
        { data: intl.formatMessage({ id: 'general.date_of_birth_lowercase' }) }
      )
    );
    if (hasError) {
      if (!inputState.Birthdate__c.hasError) {
        currentState = {
          ...currentState,
          Birthdate__c: {
            ...inputState.Birthdate__c,
            hasError,
            error,
            node,
          },
        };
      }
    }
    setInputState({ ...currentState });
    if (!hasError) {
      updateUiState('validPage', true);
    }
  };

  if (eventId && isSuccess) {
    const event = eventData.data.customRecords[0];
    if (event.event.Is_Active__c) {
      updateApptDetails('UrlSpecifiedEvent', event);
      updateUiState('showLocationSearch', false);
    } else {
      return (
        <CenteredContent>
          <Box display="grid" gridGap="16px" data-testid="dateOfBirth">
            <Typography tag="h1" variant="primaryXXLarge">
              <L10n tokenID="page.dob.expired_event" />
            </Typography>
            <Typography variant="primaryLarge">
              <L10n tokenID="page.dob.return_to" />{' '}
              <Link href="https://vaccine.heb.com" tag="highlight">
                vaccine.heb.com
              </Link>
            </Typography>
          </Box>
        </CenteredContent>
      );
    }
  }
  if (!eventId || (isSuccess && eventData.data.customRecords[0].event.Is_Active__c)) {
    return (
      <>
        <Box display="grid" gridGap="48px" data-testid="dateOfBirth">
          <PageTitleSubtitle
            title={<L10n tokenID="page.dob.header" />}
            subtitle={
              <>
                <L10n tokenID="page.dob.subheader" />
                <StyledSpan>
                  <L10n tokenID="page.dob.subheaderTwo" />
                </StyledSpan>
              </>
            }
          />
          <FieldGroup
            label={<L10n tokenID="general.field.patient_DOB" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="Birthdate__c"
            error={inputState.Birthdate__c.error}
            required
            help={
              <L10n
                tokenID="general.field.format_helper"
                values={{ data: <L10n tokenID="general.field.format_date" /> }}
              />
            }
            mask="99-99-9999"
            value={inputState.Birthdate__c.value}
            variant={['base', 'medium']}
            onKeyDown={(e) => handleEnterKeyPress(handleNext, e)}
            type="tel"
          />
        </Box>
        <PageNavigation hideNext={false} hidePrevious handleNext={handleNext} />
      </>
    );
  }
  return null;
};
export default DateOfBirth;
