import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';
import PageNavigation from '../../components/PageNavigation';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import { Box } from '../../components/Box';
import { FieldGroup } from '../../components/FieldGroup';
import { validators } from './validators';
import { useClientStore } from '../../store/store';
import { L10n } from '../../components/L10n';
import Button from '../../components/Button';

const MedicarePartB = () => {
  const {
    patientStore,
    updatePatientStore,
    medicarePartBStore,
    updateInsurance,
    updateUiState,
    showModal,
    updateModal,
  } = useClientStore((state) => ({
    patientStore: state.patient,
    updatePatientStore: state.updatePatientInfo,
    medicarePartBStore: state.insurance.medicarePartB,
    updateInsurance: state.updateInsurance,
    updateUiState: state.updateUiState,
    showModal: state.showModal,
    updateModal: state.updateModal,
  }));
  const [inputState, setInputState] = useState({
    Medicare_Number__c: { value: medicarePartBStore.Medicare_Number__c },
    Part_B_effective_date__c: { value: medicarePartBStore.Part_B_effective_date__c },
    Last_4_Digits_of_SSN__c: {
      value: medicarePartBStore.Last_4_Digits_of_SSN__c,
    },
  });
  const intl = useIntl();
  const errors = {
    Part_B_effective_date__c: intl.formatMessage(
      {
        id: 'general.field_error.valid_data_error',
      },
      { data: intl.formatMessage({ id: 'general.date_lowercase' }) }
    ),
    Last_4_Digits_of_SSN__c: intl.formatMessage(
      {
        id: 'general.field_error.valid_data_error',
      },
      { data: intl.formatMessage({ id: 'general.ssn' }) }
    ),
    Medicare_Number__c: intl.formatMessage(
      {
        id: 'general.field_error.required_error',
      },
      { field: intl.formatMessage({ id: 'page.medicare_part_b.medicare_number' }) }
    ),
  };

  const handleInputValidation = (event) => {
    const field = event.target.id;
    const label = event.target.labels[0].querySelector('span').firstChild.textContent;
    const { hasError, error } = validators.validateInput(
      event.target,
      label,
      validators[field],
      inputState[field].value,
      errors[field]
    );
    setInputState({
      ...inputState,
      [field]: {
        ...inputState[field],
        hasError,
        error,
        node: event.target,
      },
    });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const field = event.target.id;
    setInputState({
      ...inputState,
      [field]: {
        value,
      },
    });
    if (field in patientStore) {
      updatePatientStore(field, value);
    } else if (field in medicarePartBStore) {
      updateInsurance(field, value, 'medicarePartB');
    }
  };

  const handleNext = () => {
    let firstError;
    let currentState = { ...inputState };
    document.querySelectorAll('[name$="__c"]').forEach((node) => {
      const label =
        node.nodeName === 'INPUT'
          ? node.labels[0].querySelector('span').firstChild.textContent
          : null;
      const fieldName = node.getAttribute('name');
      const validator = label ? validators[fieldName] : null;
      const { value } = inputState[fieldName];
      const { error, hasError } = validators.validateInput(
        node,
        label,
        validator,
        value,
        errors[fieldName]
      );
      if (hasError) {
        if (!firstError) firstError = node;
        if (!inputState[fieldName].hasError) {
          currentState = {
            ...currentState,
            [fieldName]: {
              ...inputState[fieldName],
              hasError,
              error,
              node,
            },
          };
        }
      }
    });
    setInputState({ ...currentState });
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      updateUiState('validPage', true);
    }
  };

  const openMedicarePartBModal = (e, modal, props) => {
    updateModal(modal, props);
    showModal();
  };

  return (
    <>
      <RedirectHandler pageNumber={9} />
      <Box
        data-testid="MedicarePartB"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
      >
        <PageTitleSubtitle title={<L10n tokenID="page.medicare_part_b.header" />} />
        <Box display="grid" gridGap="large">
          <Typography tag="h2" variant={['primaryLarge']}>
            <L10n tokenID="page.medicare_part_b.card_info" />
          </Typography>
          <Button
            handleClick={(e) =>
              openMedicarePartBModal(e, 'insuranceCardModal', { insuranceCard: 'medicare' })
            }
            styling="plainBlue"
            size="noPadding"
            flexbox={{ justifyContent: 'left', textAlign: 'left' }}
          >
            <L10n tokenID="page.medicare_part_b.help_text" />
          </Button>
        </Box>
        <FieldGroup
          label={<L10n tokenID="page.medicare_part_b.medicare_number" />}
          name="Medicare_Number__c"
          onChange={handleInputChange}
          onBlur={handleInputValidation}
          error={inputState.Medicare_Number__c.error}
          value={inputState.Medicare_Number__c.value}
        />
        <FieldGroup
          label={<L10n tokenID="page.medicare_part_b.medicare_date" />}
          variant={['base', 'medium']}
          mask="99-99-9999"
          name="Part_B_effective_date__c"
          help={
            <L10n
              tokenID="general.field.format_helper"
              values={{ data: <L10n tokenID="general.field.format_date" /> }}
            />
          }
          onChange={handleInputChange}
          onBlur={handleInputValidation}
          error={inputState.Part_B_effective_date__c.error}
          value={inputState.Part_B_effective_date__c.value}
          type="tel"
        />
        <FieldGroup
          label={<L10n tokenID="insurance.last_4_ssn" />}
          variant={['base', 'medium']}
          mask="9999"
          name="Last_4_Digits_of_SSN__c"
          help={
            <L10n
              tokenID="general.field.format_helper"
              values={{ data: <L10n tokenID="general.field.format_ssn" /> }}
            />
          }
          onChange={handleInputChange}
          onBlur={handleInputValidation}
          error={inputState.Last_4_Digits_of_SSN__c.error}
          value={inputState.Last_4_Digits_of_SSN__c.value}
          type="tel"
        />
        <PageNavigation handleNext={handleNext} />
      </Box>
    </>
  );
};

export default MedicarePartB;
