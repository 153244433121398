import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '../../Button';
import { Content, Footer, Header } from '../../ModalComposition';
import { L10n } from '../../L10n';
import Modal from '../../Modal';
import Typography from '../../Typography';
import { useClientStore } from '../../../store/store';
import { useUpdateSessionHold } from '../../../api/Session-hold';

const ChangeAppointmentModal = ({ handleClose, modal }) => {
  const intl = useIntl();
  const hold = useUpdateSessionHold();

  const { reset, updateUiState, modalTimer } = useClientStore((state) => ({
    reset: state.reset,
    updateUiState: state.updateUiState,
    modalTimer: state.uiState.modalTimer,
  }));
  const modalText = {
    header: {
      location: <L10n tokenID="modal.change_appointment.change_location" />,
      vaccine: <L10n tokenID="modal.change_appointment.change_vaccine" />,
    },
    body: {
      location: <L10n tokenID="modal.change_appointment.location_body" />,
      vaccine: <L10n tokenID="modal.change_appointment.vaccine_body" />,
    },
    button: {
      location: (
        <L10n
          tokenID="modal.change_appointment.change_button"
          values={{ modal: intl.formatMessage({ id: 'general.location_lowercase' }) }}
        />
      ),
      vaccine: (
        <L10n
          tokenID="modal.change_appointment.change_button"
          values={{ modal: intl.formatMessage({ id: 'general.vaccines_lowercase' }) }}
        />
      ),
    },
  };

  const handleChangeAppointment = () => {
    hold.mutate();
    clearTimeout(modalTimer);
    reset();
    updateUiState('currentPage', 0);
  };

  return (
    <Modal
      onClose={handleClose}
      header={
        <Header withClose handleClose={handleClose}>
          {modalText.header[modal]}
        </Header>
      }
      content={
        <Content>
          <Typography variant={['primaryBase']}>{modalText.body[modal]}</Typography>
        </Content>
      }
      footer={
        <Footer variant="stacked">
          <Button handleClick={handleClose} size={['hug', 'secondary']} styling="outlined">
            <L10n tokenID="general.cancel" />
          </Button>
          <Button handleClick={handleChangeAppointment} size={['hug', 'secondary']}>
            {modalText.button[modal]}
          </Button>
        </Footer>
      }
    />
  );
};

ChangeAppointmentModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  modal: PropTypes.oneOf(['location', 'vaccine']).isRequired,
};

export default ChangeAppointmentModal;
