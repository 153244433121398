import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { shape, bool, node, func } from 'prop-types';
import { useQueryClient } from 'react-query';
import { useIntl } from 'react-intl';
import { useClientStore } from '../../store/store';
import { L10n } from '../../components/L10n';

import { useCancelAppointment, useRescheduleAppointment } from '../../api/Appointment';
import { vaccineLinks } from '../../utils/vaccineSpecifications/vaccineLinks';
import { groupFluCovidVaccines } from '../../utils/grouping/groupFluCovidVaccines';

import { Box, FlexBox } from '../../components/Box';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CardSection from '../../components/CardSection';
import Divider from '../../components/Divider';
import FormatDate from '../../components/FormatDate';
import FormatDayOfWeek from '../../components/FormatDayOfWeek';
import Icon from '../../components/Icon';
import InfoByte from '../../components/InfoByte';
import Link from '../../components/Link';
import {
  StyledList,
  StyledListItem,
  StyledItem,
  StyledItemContainer,
} from '../../components/List/styles';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import { showToast } from '../../components/Toast';
import Typography from '../../components/Typography';

import { LargeGridGapFlexBox, YAMSGrid, StyledSpan, StyledToastLink } from './styles';

const YAMS = ({ apptData, isFetching, handleSetFormattedData, setIsPrevAuth }) => {
  const { hideModal, showModal, updateModal, updateApptDetails, langPref } = useClientStore(
    (state) => ({
      hideModal: state.hideModal,
      showModal: state.showModal,
      updateModal: state.updateModal,
      updateApptDetails: state.updateApptDetails,
      langPref: state.uiState.langPref,
    })
  );
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [status, setStatus] = useState('scheduled');
  const [showFullYAMS, setShowFullYAMS] = useState(false);
  const cancel = useCancelAppointment();
  const reschedule = useRescheduleAppointment();

  const futureAppointment =
    new Date(
      `${apptData?.Appointment_Date__c.replace(/-/g, '/')}, ${apptData?.Appointment_Time__c}`
    ) >= new Date();
  const completedAppointment =
    apptData?.Administration_Status__c === 'Signoff Complete' ||
    apptData?.Administration_Status__c === 'Shot Administered - Ready for Signoff';

  useEffect(() => {
    if (apptData?.Status__c !== 'Scheduled') {
      if (apptData?.Cancel_Reason__c === 'Customer Rescheduled') {
        setStatus('rescheduled');
      } else {
        setStatus('cancelled');
      }
      setShowFullYAMS(false);
    } else {
      setStatus('scheduled');
      setShowFullYAMS(futureAppointment && !completedAppointment);
      updateModal('appointmentExpirationModal', {
        startTimer: false,
      });
    }
  }, [
    futureAppointment,
    apptData?.Status__c,
    apptData?.Cancel_Reason__c,
    completedAppointment,
    updateModal,
  ]);

  if (isFetching || apptData === undefined) {
    return null;
  }

  const isMobile = window.screen.width < 768;
  const toastPosition = isMobile ? 'top-center' : 'bottom-left';

  const DetailsContainer = isMobile ? Card : Box;
  const YAMSContainer = isMobile ? Box : Card;

  // APPOINTMENT DETAILS
  const apptDetailsData = [
    {
      title: (
        <>
          <L10n tokenID="general.confirmation_number" />:{' '}
          <Typography tag="span" variant="primaryBase">
            {apptData?.Appointment_Hash__c}
          </Typography>
        </>
      ),
      hasTopLine: false,
      titleTag: 'span',
    },
    {
      title: intl.formatMessage({
        id: 'general.patient',
      }),
      subtitle: (
        <>
          <p>
            {apptData?.First_Name__c} {apptData?.Last_Name__c}
          </p>
          <p>{apptData?.Phone_Number__c}</p>
          <p>{apptData?.Email_Address__c}</p>
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'general.date_and_time',
      }),
      subtitle: (
        <>
          <p>
            <FormatDayOfWeek dateStr={apptData?.Appointment_Date__c} />,{' '}
            <FormatDate dateStr={apptData?.Appointment_Date__c} />
          </p>
          <p>{apptData?.Appointment_Time__c}</p>
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'general.location',
      }),
      subtitle: (
        <>
          <p>{apptData?.Event__r.Name}</p>
          {apptData?.Event__r.Location__r ? (
            <>
              <p>{apptData?.Event__r.Location__r.Street_One__c}</p>
              <p>
                {apptData?.Event__r.Location__r.Primary_City__c},{' '}
                {apptData?.Event__r.Location__r.State_Text__c}{' '}
                {apptData?.Event__r.Location__r.Postal_Code_Text__c}
              </p>
            </>
          ) : (
            <p>{apptData?.Event__r.Event_Address__c}</p>
          )}
        </>
      ),
      children: (
        <a
          href={`tel:${
            apptData?.Event__r.Location__r
              ? apptData?.Event__r.Location__r.Phone_Number__c
              : apptData?.Event__r.Phone_Number__c
          }`}
        >
          <Typography tag="span" color={{ color: 'rxInteractionBlue' }} variant="secondaryBase">
            {apptData?.Event__r.Location__r
              ? apptData?.Event__r.Location__r.Phone_Number__c
              : apptData?.Event__r.Phone_Number__c}
          </Typography>
        </a>
      ),
    },
    {
      hasBottomLine: false,
      subtitle: intl.formatMessage({
        id: 'page.appointmentManagement.vaccines_subtitle',
      }),
      title: intl.formatMessage({
        id: 'general.vaccines',
      }),
      children: (
        <StyledList>
          {Object.keys(groupFluCovidVaccines(apptData?.FS_Appointment_Vaccines__r.records)).map(
            (vax) => {
              return (
                <StyledListItem key={vax}>
                  <StyledItemContainer>
                    <StyledItem height="xxSmall" width="xxSmall" maxWidth="100%" />
                    <Typography tag="p" variant="primaryBase">
                      <Link href={vaccineLinks[vax]} external>
                        <L10n tokenID={`vaccine.${vax}`} />
                      </Link>
                    </Typography>
                  </StyledItemContainer>
                </StyledListItem>
              );
            }
          )}
        </StyledList>
      ),
    },
  ];

  const reminders = [
    {
      icon: 'home',
      info: <L10n tokenID="page.appointmentManagement.reminders_sick" />,
    },
    {
      icon: 'idCard',
      info: <L10n tokenID="page.appointmentManagement.reminders_cards" />,
    },
    {
      icon: 'guardian',
      info: <L10n tokenID="page.appointmentManagement.reminders_minors" />,
    },
    {
      icon: 'shirt',
      info: <L10n tokenID="page.appointmentManagement.reminders_shirts" />,
    },
    {
      icon: 'locationPin',
      info: <L10n tokenID="page.appointmentManagement.reminders_check_in" />,
    },
    {
      icon: 'clock',
      info: <L10n tokenID="page.appointmentManagement.reminders_duration" />,
    },
  ];

  // CANCEL APPOINTMENT
  const handleCancellation = () => {
    hideModal();
    cancel.mutate({ confirmation: apptData?.Appointment_Hash__c, dob: apptData?.Birthdate__c });
  };

  const handleCancelButtonClick = () => {
    updateModal('cancelAppointmentModal', {
      handleClose: hideModal,
      handleCancel: handleCancellation,
      dateStr: apptData?.Appointment_Date__c,
      time: apptData?.Appointment_Time__c,
    });
    showModal();
  };

  const cancelCard = (
    <Box gridArea="cancel">
      <Card variant="muted" margin="none" fullHeight>
        <FlexBox
          alignItems="stretch"
          justifyContent="space-between"
          height="100%"
          gridGap="xLarge"
          flexDirection="column"
        >
          <FlexBox gridGap={['large', 'medium']} flexDirection="column">
            <Typography variant="primaryLarge" tag="h2">
              <L10n tokenID="page.appointmentManagement.cancel_appointment" />
            </Typography>
            <Typography variant="primaryBase">
              <L10n tokenID="page.appointmentManagement.cancel_appointment_subtitle" />
            </Typography>
          </FlexBox>
          <Button size="secondary" handleClick={handleCancelButtonClick}>
            <L10n tokenID="page.appointmentManagement.cancel_appointment" />
          </Button>
        </FlexBox>
      </Card>
    </Box>
  );

  // RESCHEDULE APPOINTMENT
  const handleCloseToast = (newApptId) => {
    setIsPrevAuth(true);
    toast.dismiss();
    queryClient.invalidateQueries([
      'appointment',
      apptData.Birthdate__c,
      apptData.Appointment_Hash__c,
    ]);
    window.scrollTo(0, 0);
    if (newApptId) {
      handleSetFormattedData(newApptId);
      updateApptDetails('confirmationNum', newApptId);
    }
  };

  const showRescheduleToast = (newApptId) => {
    showToast(
      intl.formatMessage({
        id: 'page.appointmentManagement.reschedule_appointment_success_title',
      }),
      'success',
      toastPosition,
      <>
        <L10n
          tokenID="page.appointmentManagement.reschedule_appointment_success_subtitle"
          values={{ email: apptData?.Email_Address__c }}
        />
        <StyledToastLink>
          <Button
            size="noPaddingTertiary"
            styling="link"
            handleClick={() => handleCloseToast(newApptId)}
          >
            {intl.formatMessage({
              id: 'page.appointmentManagement.reschedule_appointment_success_link',
            })}
          </Button>
        </StyledToastLink>
      </>,
      true,
      false,
      () => handleCloseToast()
    );
  };

  const rescheduleSuccess = (data) => {
    showRescheduleToast(data.data.records[0].New_Appointment_Hash__c);
  };

  const showRescheduleToastError = () => {
    showToast(
      intl.formatMessage({
        id: 'page.appointmentManagement.reschedule_appointment_error_title',
      }),
      'error',
      toastPosition,
      <L10n tokenID="page.appointmentManagement.reschedule_appointment_error_subtitle" />,
      true
    );
  };

  const handleReschedule = (appt) => {
    hideModal();
    reschedule.mutate(
      { confirmation: apptData.Appointment_Hash__c, newAppt: appt },
      {
        onSuccess: rescheduleSuccess,
        onError: showRescheduleToastError,
      }
    );
  };

  const handleRescheduleButtonClick = () => {
    updateModal('changeAppointmentDateTimeModal', {
      handleClose: hideModal,
      currApptData: {
        date: apptData?.Appointment_Date__c,
        time: apptData?.Appointment_Time__c,
        locationName: apptData?.Event__r.Name,
        event: apptData?.Event__r,
      },
      handleConfirm: handleReschedule,
      filteredProducts: Object.assign(
        {},
        ...apptData.FS_Appointment_Vaccines__r.records.map((vax) => ({
          [vax.FS_Product__r.Label__c]: { ...vax.FS_Product__r },
        }))
      ),
    });
    showModal();
  };

  const rescheduleCard = (
    <Box gridArea="reschedule">
      <Card variant="muted" margin="none" fullHeight>
        <FlexBox
          alignItems="stretch"
          flexDirection="column"
          gridGap="xLarge"
          height="100%"
          justifyContent="space-between"
        >
          <FlexBox gridGap={['large', 'medium']} flexDirection="column">
            <Typography variant="primaryLarge" tag="h2">
              <L10n tokenID="page.appointmentManagement.reschedule_appointment" />
            </Typography>
            <Typography variant="primaryBase">
              <L10n tokenID="page.appointmentManagement.reschedule_appointment_subtitle" />
            </Typography>
          </FlexBox>
          <Button size="secondary" handleClick={handleRescheduleButtonClick}>
            <L10n tokenID="page.appointmentManagement.reschedule_appointment" />
          </Button>
        </FlexBox>
      </Card>
    </Box>
  );

  const scheduledContents = (
    <>
      <Box gridArea="reminders">
        <DetailsContainer variant="muted" margin="none">
          <LargeGridGapFlexBox>
            <Typography variant="primaryLarge" tag="h2">
              <L10n tokenID="page.appointmentManagement.reminders" />
            </Typography>
            <FlexBox gridGap={['large', null, 'xLarge']} flexDirection="column">
              {reminders.map((reminder) => {
                return (
                  // TODO: create info byte with 8px border radius and update
                  <InfoByte
                    key={reminder.icon}
                    alignment="start"
                    variant="callOut"
                    icon={reminder.icon}
                    info={reminder.info}
                  />
                );
              })}
            </FlexBox>
          </LargeGridGapFlexBox>
        </DetailsContainer>
      </Box>
      {!isMobile && (
        <Box gridArea="divider">
          <Divider mobileOnly={false} />
        </Box>
      )}
      {cancelCard}
      {rescheduleCard}
    </>
  );

  return (
    <YAMSContainer data-testid="YAMS" variant="muted" margin="none" padding={['none', 'xLarge']}>
      <YAMSGrid gridGap="xLarge" flexDirection="column" showFullYAMS={showFullYAMS}>
        <Box
          gridArea="status"
          borderBottom={['none', 'normal']}
          borderColor={['transparent', 'coldBrews.100']}
          pb={['none', 'xLarge']}
        >
          <PageTitleSubtitle
            title={
              <L10n
                tokenID="page.appointmentManagement.appointment_status"
                values={{
                  status,
                  isScheduled: status === 'scheduled',
                }}
              />
            }
            titleInlineElement={
              !isMobile && showFullYAMS ? (
                // TODO: make the printed version prettier
                <Button
                  styling="mutedBlue"
                  size="hug"
                  handleClick={window.print}
                  StartIcon={<Icon icon="printer" />}
                >
                  <L10n tokenID="page.appointmentManagement.print" />
                </Button>
              ) : null
            }
            subtitle={
              <>
                <L10n
                  tokenID={`page.appointmentManagement.${status}_email_sent`}
                  values={{ email: apptData?.Email_Address__c }}
                />
                {!showFullYAMS && status !== 'scheduled' ? (
                  <StyledSpan>
                    <L10n
                      tokenID={`page.appointmentManagement.${
                        status === 'rescheduled' ? 'rescheduled_new_confirmation' : 'cancel_reason'
                      }`}
                    />
                    {
                      // eslint-disable-next-line
                      status === 'cancelled'
                        ? langPref === 'en-us'
                          ? apptData?.Cancel_Reason__c
                          : apptData?.Cancel_Reason_Spanish__c
                        : null
                    }
                  </StyledSpan>
                ) : null}
              </>
            }
          />
        </Box>
        <Box
          gridArea="details"
          borderRight={['none', showFullYAMS ? 'normal' : 'none']}
          borderColor={['none', 'coldBrews.100']}
          pr={['none', showFullYAMS ? 'large' : 'none']}
        >
          <DetailsContainer variant="muted" margin="none">
            <Typography variant="primaryLarge" tag="h2" space={{ pb: 'medium' }}>
              <L10n tokenID="page.appointmentManagement.appointment_details" />
            </Typography>
            {apptDetailsData.map((detail) => {
              return (
                <CardSection
                  key={detail.title}
                  title={detail.title}
                  subtitle={detail.subtitle}
                  titleTag={detail.titleTag || 'h3'}
                  hasBottomLine={detail.hasBottomLine !== undefined ? detail.hasBottomLine : true}
                  hasTopLine={detail.hasTopLine !== undefined ? detail.hasTopLine : true}
                >
                  {detail.children !== undefined ? detail.children : null}
                </CardSection>
              );
            })}
          </DetailsContainer>
        </Box>
        {showFullYAMS ? scheduledContents : null}
      </YAMSGrid>
    </YAMSContainer>
  );
};

YAMS.propTypes = {
  apptData: shape({ node }),
  handleSetFormattedData: func,
  isFetching: bool,
  setIsPrevAuth: func,
};

export default YAMS;
