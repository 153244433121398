import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { HttpLink, split } from '@apollo/client';

export function createHttpLink(options) {
  return split(
    (operation) => operation.getContext().batch === false,
    new HttpLink(options), // if the test is true -- debatch
    new BatchHttpLink(options) // otherwise, batching is fine
  );
}
