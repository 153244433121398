import React, { useState } from 'react';

import { useClientStore } from '../../store/store';

import { FlexBox, Box } from '../../components/Box';
import Button from '../../components/Button';
import Card from '../../components/Card';
import FormatDate from '../../components/FormatDate';
import Icon from '../../components/Icon';
import { L10n } from '../../components/L10n';
import PageNavigation from '../../components/PageNavigation';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';

const ConsentAcknowledgments = () => {
  const L10nPref = (suffix, values) => (
    <L10n tokenID={`page.consent_ack.${suffix}`} values={{ ...values }} />
  );
  const {
    hideModal,
    showModal,
    patientDetails,
    updateModal,
    updatePatientInfo,
    updateUiState,
  } = useClientStore((state) => ({
    hideModal: state.hideModal,
    patientDetails: state.patient,
    showModal: state.showModal,
    updateModal: state.updateModal,
    updatePatientInfo: state.updatePatientInfo,
    updateUiState: state.updateUiState,
  }));

  const [signedAndAcked, setSignedAndAcked] = useState({
    relationshipToPatient: {
      completed:
        patientDetails.Consent_Initial__c !== '' &&
        patientDetails.Consent_Initial_Option__c !== '' &&
        patientDetails.Relationship_to_Patient_Picklist__c,
      error: false,
    },
    vaccineInformation: { completed: patientDetails.viewedVaccineInformation, error: false },
    informedConsent: {
      completed: patientDetails.Signature__c !== '',
      error: false,
    },
  });

  const handleNext = () => {
    const clone = { ...signedAndAcked };
    let firstError = null;
    Object.keys(signedAndAcked).forEach((key) => {
      if (!signedAndAcked[key].completed) {
        clone[key].error = true;
        firstError = firstError === null ? key : firstError;
      }
    });
    if (firstError === null) {
      updateUiState('validPage', true);
    } else {
      setSignedAndAcked({
        ...signedAndAcked,
        ...clone,
      });
      document
        .querySelector(`#${firstError}`)
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleRelationshipToPatientConfirmation = (data) => {
    setSignedAndAcked({
      ...signedAndAcked,
      relationshipToPatient: {
        ...signedAndAcked.relationshipToPatient,
        completed: true,
        error: false,
      },
    });
    Object.keys(data).forEach((field) => updatePatientInfo(field, data[field]));
    hideModal();
  };

  const handleRelationshipToPatientClick = () => {
    updateModal('relationshipToPatientModal', {
      handleClose: hideModal,
      handleConfirm: handleRelationshipToPatientConfirmation,
      relationshipToPatientInitial: patientDetails.Relationship_to_Patient_Picklist__c,
      initialsInitial: patientDetails.Consent_Initial__c,
    });
    showModal();
  };

  const handleVaccineInformationConfirmation = () => {
    setSignedAndAcked({
      ...signedAndAcked,
      vaccineInformation: {
        ...signedAndAcked.vaccineInformation,
        completed: true,
        error: false,
      },
    });
    updatePatientInfo('viewedVaccineInformation', true);
    hideModal();
  };

  const handleVaccineInformationClick = () => {
    updateModal('vaccineInformationModal', {
      handleClose: hideModal,
      handleConfirm: handleVaccineInformationConfirmation,
      vaccinesViewedInitial: patientDetails.viewedVaccineInformation,
    });
    showModal();
  };

  const handleInformedConsentConfirmation = (signature) => {
    setSignedAndAcked({
      ...signedAndAcked,
      informedConsent: {
        ...signedAndAcked.relationshipToPatient,
        completed: true,
        error: false,
      },
    });
    updatePatientInfo('Signature__c', signature);
    hideModal();
  };

  const handleInformedConsentClick = () => {
    updateModal('informedConsentModal', {
      handleClose: hideModal,
      handleConfirm: handleInformedConsentConfirmation,
      signatureInitial: patientDetails.Signature__c,
    });
    showModal();
  };

  const acknowledgments = [
    {
      id: 'relationshipToPatient',
      title: L10nPref('rel_to_patient'),
      buttonText: signedAndAcked.relationshipToPatient.completed
        ? L10nPref('completed')
        : L10nPref('view_sign'),
      onClick: handleRelationshipToPatientClick,
      errorMsg: L10nPref('rel_to_patient_view_sign_error'),
    },
    {
      id: 'vaccineInformation',
      title: L10nPref('vax_info'),
      buttonText: signedAndAcked.vaccineInformation.completed
        ? L10nPref('completed')
        : L10nPref('view_ack'),
      onClick: handleVaccineInformationClick,
      errorMsg: L10nPref('vax_info_view_ack_error'),
    },
    {
      id: 'informedConsent',
      title: L10nPref('informed_consent'),
      buttonText: signedAndAcked.informedConsent.completed
        ? L10nPref('completed')
        : L10nPref('view_ack'),
      onClick: handleInformedConsentClick,
      errorMsg: L10nPref('informed_consent_view_ack_error'),
    },
  ];

  return (
    <>
      <RedirectHandler pageNumber={14} />
      <FlexBox flexDirection="column" gridGap="xLarge">
        <PageTitleSubtitle title={L10nPref('title')} subtitle={L10nPref('subtitle')} />
        <FlexBox flexDirection="column" gridGap="xxLarge">
          {acknowledgments.map((ack) => {
            const hasError = signedAndAcked[ack.id].error;
            const isComplete = signedAndAcked[ack.id].completed;
            return (
              <Box key={ack.id} id={ack.id}>
                <Card variant={hasError ? 'errorBorder' : 'muted'} margin="none">
                  <Box
                    display={['block', 'flex']}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      tag="h2"
                      variant="primaryLarge"
                      space={{ mb: ['medium', 'none'] }}
                      color={{ color: hasError ? 'brisket' : 'inherit' }}
                    >
                      {ack.title}
                    </Typography>
                    <Box textAlign="center" px={['xxLarge', 'none']} minWidth={['auto', '240px']}>
                      <Button
                        EndIcon={
                          isComplete ? <Icon icon="checkCircle" styling="base" /> : undefined
                        }
                        ghostStartIcon
                        size="tertiaryFill"
                        styling={isComplete ? 'success' : 'base'}
                        handleClick={ack.onClick}
                        endIconPosition="end"
                      >
                        {ack.buttonText}
                      </Button>
                    </Box>
                  </Box>
                </Card>
                {hasError ? (
                  <Typography
                    space={{ mt: 'xxSmall' }}
                    color={{ color: 'brisket' }}
                    variant="primarySmall"
                  >
                    {ack.errorMsg}
                  </Typography>
                ) : null}
              </Box>
            );
          })}
        </FlexBox>
        <Box textAlign="right">
          <Typography variant="primarySmall" color={{ color: 'coldBrews.600' }}>
            {L10nPref('legal_effective', {
              date: <FormatDate dateStr="July 16, 2016" format="full" />,
            })}
          </Typography>
        </Box>
      </FlexBox>
      <PageNavigation hideNext={false} handleNext={handleNext} />
    </>
  );
};

export default ConsentAcknowledgments;
