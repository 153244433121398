// Uses haversine formula for calculating approx distance so slight error is expected.
const getDistance = (lat1, lon1, lat2, lon2) => {
  const p = 0.017453292519943295;
  const c = Math.cos;
  const a =
    0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a));
};

export const sortByGeolocation = (lat, lon, events, radius) => {
  const maxDistKm = radius * 1.609344; // Convert miles to km
  const evtsWithDistance = [];
  events.forEach((evt) => {
    const event = { ...evt };
    if (event.event.Location__r && event.event.Location__r.Geolocation__Latitude__s) {
      event.distance = getDistance(
        lat,
        lon,
        event.event.Location__r.Geolocation__Latitude__s,
        event.event.Location__r.Geolocation__Longitude__s
      );
    } else {
      event.distance = null;
    }
    // Return results within the specified distance
    if (event.distance != null && event.distance <= maxDistKm) {
      evtsWithDistance.push(event);
    }
  });
  const sortedEvents = evtsWithDistance.sort((evt1, evt2) => {
    if (evt1.distance == null) {
      return 1;
    }
    if (evt2.distance == null) {
      return -1;
    }
    if (evt1.distance === evt2.distance) {
      return 0;
    }
    return evt1.distance > evt2.distance ? 1 : -1;
  });
  return sortedEvents;
};
