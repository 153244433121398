import axios from 'axios';

// Create axios instance
export const api = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});

// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status;
  if (statusCode) {
    // eslint-disable-next-line no-console
    console.error(`${statusCode} -- ${error}`);
  }
  return Promise.reject(error);
};
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
