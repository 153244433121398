const grid = {
  display: 'grid',
  gap: 'large',
};

const sideBySide = {
  ...grid,
  gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
};

const single = {
  display: 'flex',
  justifyContent: 'center',
};

const stacked = {
  ...grid,
  gridTemplateColumns: ['1fr', 'repeat(2,minmax(0,1fr))'],
};

export const footerVariants = {
  sideBySide,
  single,
  stacked,
};
