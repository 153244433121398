import React from 'react';
import PropTypes from 'prop-types';
import { StyledStepper, StyledBarStep, StyledLeftBar, StyledDot } from './styles';
import Typography from '../Typography';
import Icon from '../Icon';

const ProgressBar = ({ steps, activeStep = 0 }) => {
  return (
    <div>
      <StyledStepper num={steps.length}>
        {steps.map((step, index) => (
          <ProgressBarTitleStep
            key={step}
            title={step}
            active={index === activeStep}
            completed={index < activeStep}
            first={index === 0}
          />
        ))}
      </StyledStepper>
      <StyledStepper num={steps.length}>
        {steps.map((step, index) => (
          <ProgressBarStep
            title={step}
            key={step}
            active={index === activeStep}
            completed={index < activeStep}
            first={index === 0}
          />
        ))}
      </StyledStepper>
    </div>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number,
};

const ProgressBarStep = ({ active = false, completed = false, first = false, title }) => {
  return (
    <StyledBarStep data-testid="progress-bar-step" key={title}>
      {!first && <StyledLeftBar className={(active || completed) && 'activeBar'} />}
      <StyledDot className={(active && 'activeStep') || (completed && 'completedStep')}>
        {completed && <Icon icon="progressBarCheck" styling="base" scale={[0.9, 1.1]} />}
      </StyledDot>
    </StyledBarStep>
  );
};

ProgressBarStep.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  first: PropTypes.bool,
  title: PropTypes.string,
};

const ProgressBarTitleStep = ({ title, active = false }) => {
  return (
    <div key={title}>
      <Typography
        variant={active ? ['secondarySmall', 'secondaryMedium'] : ['primarySmall', 'primaryMedium']}
        color={active ? { color: 'rxColors.rxInteraction800' } : { color: 'rxColors.coldBrew' }}
        space={{ px: 'xxSmall', pb: ['xSmall', 'small'] }}
      >
        {title}
      </Typography>
    </div>
  );
};

ProgressBarTitleStep.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default ProgressBar;
