import React from 'react';
import PropTypes from 'prop-types';
import { StyledList, StyledListItem, StyledItem, StyledItemContainer } from './styles';
import Typography from '../Typography';

const List = ({
  items,
  bulletSize = 'xxSmall',
  maxWidth = '100%',
  textVariant = 'primaryBase',
}) => {
  return (
    <StyledList>
      {items.map((item) => {
        return (
          <StyledListItem key={item}>
            <StyledItemContainer>
              <StyledItem height={bulletSize} width={bulletSize} maxWidth={maxWidth} />
              <Typography tag="p" variant={textVariant}>
                {item}
              </Typography>
            </StyledItemContainer>
          </StyledListItem>
        );
      })}
    </StyledList>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  bulletSize: PropTypes.string,
  maxWidth: PropTypes.string,
  textVariant: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default List;
