import React from 'react';
import Loader from '../../components/Loader';

const MARKETING_PAGE = 'https://www.heb.com/pharmacy/vaccinations';

const Home = () => {
  window.location.replace(MARKETING_PAGE);
  return <Loader />;
};

export default Home;
