import styled from 'styled-components';
import { compose, layout } from 'styled-system';
import themes from '../../themes';

export const StyledDiv = styled.div(
  (props) => ({
    fill: props.$color,
    position: 'relative',
    transition: 'all 0.25s ease',
    '&:hover, button:hover &': {
      fill: props.$hoverColor,
    },
    '&.base': {
      fill: themes.colors.buttercream,
      '&:hover, button:hover &': {
        fill: themes.colors.buttercream,
      },
    },
    '&.error': {
      fill: themes.colors.brisket,
    },
    '&.mutedBlue': {
      fill: themes.colors.rxInteractionBlue,
      '&:hover, button:hover &': {
        fill: themes.colors.buttercream,
      },
    },
    '&.mutedColdbrew': {
      fill: themes.colors.text,
      '&:hover, button:hover &': {
        fill: themes.colors.buttercream,
      },
    },
    '&.outlined': {
      fill: themes.colors.rxInteractionBlue,
      '&:hover, button:hover &': {
        fill: themes.colors.buttercream,
      },
    },
    '&.plainBlue': {
      fill: themes.colors.rxInteractionBlue,
      '&:hover, button:hover &': {
        fill: themes.colors.rxBrandBlue,
      },
    },
    '&.plainColdbrew': {
      fill: themes.colors.text,
      '&:hover, button:hover &': {
        fill: themes.colors.rxInteractionBlue,
      },
    },
    '&.success': {
      fill: themes.colors.buttercream,
      '&:hover, button:hover &': {
        fill: themes.colors.buttercream,
      },
    },
  }),
  compose(layout)
);

export const StyledSvg = styled.svg`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
