export const groupFluCovidVaccines = (products) => {
  const groupedProducts = {};
  products.forEach((product) => {
    const vaxProduct = product.FS_Product__r !== undefined ? product.FS_Product__r : product;
    if (vaxProduct.Vaccine_Type__c === 'Covid-19') {
      if (!groupedProducts['COVID-19']) {
        groupedProducts['COVID-19'] = {
          Name: 'COVID-19',
          manufacturers: { [vaxProduct.Label__c]: vaxProduct },
          Label__c: 'COVID-19',
        };
      } else {
        groupedProducts['COVID-19'] = {
          ...groupedProducts['COVID-19'],
          manufacturers: {
            ...groupedProducts['COVID-19'].manufacturers,
            [vaxProduct.Label__c]: vaxProduct,
          },
        };
      }
    } else if (vaxProduct.Vaccine_Type__c === 'Flu') {
      groupedProducts.flu = {
        ...vaxProduct,
        Label__c: 'flu',
        Name: 'flu',
      };
    } else {
      groupedProducts[vaxProduct.Label__c] = vaxProduct;
    }
  });
  return groupedProducts;
};
