import dateOfBirth from './dateOfBirth/translations.json';
import MedicarePartB from './MedicarePartB/translations.json';
import eligibility from './eligibility/translations.json';
import medicarePartC from './medicarePartC/translations.json';
import medicarePartD from './medicarePartD/translations.json';
import healthInformation from './healthInformation/translations.json';
import insuranceSelection from './insuranceSelection/translations.json';
import medicareStatement from './medicareStatement/translations.json';
import patientInformation from './patientInformation/translations.json';
import pharmacyMedical from './pharmacyMedical/translations.json';
import review from './review/translations.json';
import vaccineSelection from './vaccineSelection/translations.json';
import appointmentManagement from './appointmentManagement/translations.json';
import locationSearch from './locationSearch/translations.json';
import locationSelection from './locationSelection/translations.json';
import consentAcknowledgments from './consentAcknowledgments/translations.json';
import covidManufacturerSelection from './covidManufacturerSelection/translations.json';

export default {
  'en-us': {
    ...dateOfBirth['en-us'],
    ...eligibility['en-us'],
    ...MedicarePartB['en-us'],
    ...medicarePartC['en-us'],
    ...medicarePartD['en-us'],
    ...healthInformation['en-us'],
    ...insuranceSelection['en-us'],
    ...medicareStatement['en-us'],
    ...patientInformation['en-us'],
    ...pharmacyMedical['en-us'],
    ...review['en-us'],
    ...vaccineSelection['en-us'],
    ...appointmentManagement['en-us'],
    ...locationSearch['en-us'],
    ...consentAcknowledgments['en-us'],
    ...locationSelection['en-us'],
    ...covidManufacturerSelection['en-us'],
  },
  'es-us': {
    ...dateOfBirth['es-us'],
    ...eligibility['es-us'],
    ...MedicarePartB['es-us'],
    ...medicarePartC['es-us'],
    ...medicarePartD['es-us'],
    ...healthInformation['es-us'],
    ...insuranceSelection['es-us'],
    ...medicareStatement['es-us'],
    ...patientInformation['es-us'],
    ...pharmacyMedical['es-us'],
    ...review['es-us'],
    ...vaccineSelection['es-us'],
    ...appointmentManagement['es-us'],
    ...locationSearch['es-us'],
    ...consentAcknowledgments['es-us'],
    ...locationSelection['es-us'],
    ...covidManufacturerSelection['es-us'],
  },
};
