import React from 'react';
import PropTypes from 'prop-types';
import FilterRibbon from '../FilterRibbon';
import { L10n } from '../L10n';
import Typography from '../Typography';
import { useClientStore } from '../../store/store';

const LocationFilterRibbon = ({ expanded, setExpanded, button }) => {
  const searchStr = useClientStore((state) => state.uiState.searchStr);
  return (
    <FilterRibbon
      expanded={expanded}
      setExpanded={setExpanded}
      icon="locationPin"
      iconScale={[5 / 6]}
      ribbonContent={
        <Typography tag="p" variant={['primaryMedium']}>
          <L10n tokenID="general.location" />: {!expanded && searchStr}
        </Typography>
      }
      action={button}
    />
  );
};

LocationFilterRibbon.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  button: PropTypes.node,
};

export default LocationFilterRibbon;
