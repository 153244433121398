import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSessionQuery } from '../../api/Session';

import { formatTime } from '../../utils/formatTime';

import { Box } from '../Box';
import DateFilters from './DateFilters';
import { L10n } from '../L10n';
import Shimmer from '../Shimmer';
import TimeFilters from './TimeFilters';
import Typography from '../Typography';

const DateTimeSelection = ({
  selectedAppt,
  handleApptSelection,
  displayTimeslotHold,
  location,
  filteredProducts,
}) => {
  const intl = useIntl();
  const [activeDate, setActiveDate] = useState(new Date().toDateString());

  const { data: sessionData, isLoading } = useSessionQuery(location?.Id);

  const formatEventSessions = useCallback(
    (eventSessions) => {
      const dates = {};
      if (eventSessions) {
        eventSessions.forEach((event) => {
          const eventClone = { ...event };
          const sessionDate = new Date(event.Start_Date_Time__c);
          const startTime = new Date(event.Start_Date_Time__c);

          const localDateStr = sessionDate.toDateString();
          const localStartTimeStr = formatTime(startTime);

          eventClone.label = `${localStartTimeStr}`;
          eventClone.value = event.Id;
          if (dates[localDateStr] === undefined) {
            dates[localDateStr] = {
              Morning: {
                children: intl.formatMessage({ id: `modal.dateTimeSelection.morning` }),
                choices: [],
                id: 'Morning',
              },
              Afternoon: {
                children: intl.formatMessage({ id: `modal.dateTimeSelection.afternoon` }),
                choices: [],
                id: 'Afternoon',
              },
              Evening: {
                children: intl.formatMessage({ id: `modal.dateTimeSelection.evening` }),
                choices: [],
                id: 'Evening',
              },
            };
          }
          if (startTime.getHours() < 12) {
            dates[localDateStr].Morning.choices.push(eventClone);
          } else if (startTime.getHours() < 17) {
            dates[localDateStr].Afternoon.choices.push(eventClone);
          } else {
            dates[localDateStr].Evening.choices.push(eventClone);
          }
        });
      }

      return dates;
    },
    [intl]
  );

  const filterSessions = useCallback(
    (sessions) => {
      if (sessions?.records) {
        const dateTimeMap = new Map();
        const productIds = Object.keys(filteredProducts).map((key) =>
          key === 'COVID-19' && filteredProducts[key].selected.Id
            ? filteredProducts[key].selected.Id
            : filteredProducts[key].Id
        );
        // Filter out full sessions and sessions with duplicate start and end times
        const filteredSessions = sessions.records.filter((session) => {
          let duplicateTime = false;
          const startEndConcat = `${session.Start_Date_Time__c}${session.End_Date_Time__c}`;
          if (!dateTimeMap.has(startEndConcat)) {
            dateTimeMap.set(startEndConcat, session);
          } else {
            duplicateTime = true;
          }
          const sessionOfferings = session.FS_Session_Offerings__r?.records.map(
            (offering) => offering.FS_Product__c
          );
          return (
            productIds.every((productId) => sessionOfferings.includes(productId)) &&
            session.Count_of_Appointments__c < session.Max_Attendees__c &&
            (!session.FS_Session_Holds__r ||
              session.FS_Session_Holds__r.totalSize + session.Count_of_Appointments__c <
                session.Max_Attendees__c) &&
            !duplicateTime
          );
        });

        return filteredSessions;
      }
      return sessions;
    },
    [filteredProducts]
  );
  const timeslots = useMemo(() => {
    const slots = formatEventSessions(filterSessions(sessionData?.data));
    setActiveDate(Object.keys(slots)[0]);
    return slots;
  }, [sessionData, formatEventSessions, filterSessions]);

  return Object.keys(timeslots).length === 0 && !isLoading ? (
    <Typography variant="primaryMedium" space={{ mt: 'small' }}>
      <L10n tokenID="modal.dateTimeSelection.no_avail_appts_event" />
    </Typography>
  ) : (
    <>
      <Box flex="0 1 auto">
        {isLoading ? (
          <Shimmer height="xxLarge" width="120px" space={{ mb: 'small' }} />
        ) : (
          <Typography variant="secondaryBase" space={{ pb: 'small' }}>
            <L10n tokenID="modal.dateTimeSelection.select_a_date" />
          </Typography>
        )}

        <DateFilters
          activeDate={activeDate}
          availableDates={timeslots}
          handleClick={setActiveDate}
          isLoading={isLoading}
        />
      </Box>
      {timeslots[activeDate] ? (
        <TimeFilters
          isLoading={isLoading}
          timeslots={timeslots[activeDate]}
          currDate={activeDate}
          selectedTime={selectedAppt.Event_Session__c}
          handleTimeSelection={(apptDetailsObj) => handleApptSelection(apptDetailsObj)}
          displayTimeslotHold={displayTimeslotHold}
        />
      ) : (
        !isLoading && (
          <Typography variant="primaryMedium" space={{ mt: 'small' }}>
            <L10n tokenID="modal.dateTimeSelection.no_avail_appts_day" />
          </Typography>
        )
      )}
    </>
  );
};

DateTimeSelection.propTypes = {
  displayTimeslotHold: PropTypes.bool,
  selectedAppt: PropTypes.shape({
    Event_Session__c: PropTypes.string,
    Appointment_Date__c: PropTypes.string,
    Appointment_Time__c: PropTypes.string,
  }).isRequired,
  handleApptSelection: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  // eslint-disable-next-line
  filteredProducts: PropTypes.object,
};

export default DateTimeSelection;
