import React from 'react';
import {
  ProgressRingGroup,
  ProgressRingHalf,
  ProgressRingFull,
  ProgressRingDim,
  StyledSvg,
} from './styles';
import { rings } from './rings';
import themes from '../../themes';

const ProgressRings = () => {
  const fullRing = 'full';
  const halfRing = 'half';
  const dimRing = 'dim';
  const logo = 'logo';
  return (
    <StyledSvg
      clipRule="evenodd"
      fillRule="evenodd"
      viewBox="0 0 88 88"
      fill="none"
      data-testid="progress-circle"
      role="status"
    >
      <circle cx="44" cy="44" r="44" fill={themes.colors.hebRed} />
      <ProgressRingGroup>
        <ProgressRingFull
          d={rings[fullRing].d}
          stroke={rings[fullRing].stroke}
          strokeWidth={rings[fullRing].strokeWidth}
          strokeLinecap={rings[fullRing].strokeLinecap}
        />
        <ProgressRingHalf
          d={rings[halfRing].d}
          stroke={rings[halfRing].stroke}
          strokeWidth={rings[halfRing].strokeWidth}
          strokeLinecap={rings[halfRing].strokeLinecap}
        />
        <ProgressRingDim
          d={rings[dimRing].d}
          stroke={rings[dimRing].stroke}
          strokeWidth={rings[dimRing].strokeWidth}
          strokeLinecap={rings[dimRing].strokeLinecap}
        />
      </ProgressRingGroup>
      <path d={rings[logo].d} fill={rings[logo].fill} />
    </StyledSvg>
  );
};

export default ProgressRings;
