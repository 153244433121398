import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import Modal from '../../Modal';
import { Header, Footer, Content } from '../../ModalComposition';
import Typography from '../../Typography';
import { L10n } from '../../L10n';

const DiscardChangesModal = ({ handleClose, handleContinue }) => {
  return (
    <Modal
      onClose={handleClose}
      header={
        <Header withClose handleClose={handleClose}>
          <L10n tokenID="modals.discardChanges.header" />
        </Header>
      }
      content={
        <Content>
          <Typography variant={['primaryBase']}>
            <L10n tokenID="modals.discardChanges.warning" />
          </Typography>
        </Content>
      }
      footer={
        <Footer variant="sideBySide">
          <Button
            handleClick={handleClose}
            size={['hug', 'secondary']}
            styling="outlined"
            data-testid="cancel"
          >
            <L10n tokenID="modals.discardChanges.cancel" />
          </Button>
          <Button handleClick={handleContinue} size={['hug', 'secondary']} data-testid="continue">
            <L10n tokenID="modals.discardChanges.continue" />
          </Button>
        </Footer>
      }
    />
  );
};

DiscardChangesModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
};

export default DiscardChangesModal;
