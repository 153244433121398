/* eslint-disable react/jsx-props-no-spreading */
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  StyledButton,
  StyledEndIcon,
  StyledStartIcon,
  sizeConstrainedButtonStyles,
  stylingConstrainedIconScale,
} from './styles';
import Icon from '../Icon';

const Button = ({
  children,
  disabled = false,
  type = 'button',
  size = 'primary',
  styling = 'base',
  handleClick,
  StartIcon,
  EndIcon,
  flexbox,
  endIconPosition,
  circleSize,
}) => {
  const calculatedStyling = sizeConstrainedButtonStyles[size] || styling;
  const cloneIcon = (icon) => {
    const clone = cloneElement(icon, {
      scale: stylingConstrainedIconScale[size],
      styling: calculatedStyling,
    });
    return clone;
  };

  return (
    <StyledButton
      {...flexbox}
      className={sizeConstrainedButtonStyles[size] || calculatedStyling}
      onClick={(e) => !disabled && handleClick(e)}
      size={size}
      type={type}
      disabled={disabled}
      circleSize={circleSize}
    >
      {StartIcon && <StyledStartIcon>{cloneIcon(StartIcon)}</StyledStartIcon>}
      {children}
      {EndIcon && (
        <StyledEndIcon endIconPosition={endIconPosition}>{cloneIcon(EndIcon)}</StyledEndIcon>
      )}
    </StyledButton>
  );
};

const sizes = [
  'hug',
  'primary',
  'secondary',
  'small',
  'tertiary',
  'tertiaryFill',
  'fill',
  'square',
  'circular',
  'noPadding',
  'noPaddingTertiary',
];

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  size: PropTypes.oneOfType([PropTypes.oneOf(sizes), PropTypes.arrayOf(PropTypes.oneOf(sizes))]),
  styling: PropTypes.oneOf([
    'base',
    'disable',
    'disableActive',
    'link',
    'mutedBlue',
    'mutedColdbrew',
    'outlined',
    'outlinedMinor',
    'plainBlue',
    'plainColdbrew',
    'transparent',
    'success',
  ]),
  type: PropTypes.string,
  EndIcon: PropTypes.shape({
    type: PropTypes.oneOf([Icon]),
  }),
  StartIcon: PropTypes.shape({
    type: PropTypes.oneOf([Icon]),
  }),
  flexbox: PropTypes.objectOf(PropTypes.string),
  endIconPosition: PropTypes.oneOf(['center', 'end']),
  circleSize: PropTypes.string,
};

export default Button;
