import React, { useState } from 'react';
import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';
import PageNavigation from '../../components/PageNavigation';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import { Box } from '../../components/Box';
import { FieldGroup } from '../../components/FieldGroup';
import { useClientStore } from '../../store/store';
import { L10n } from '../../components/L10n';
import Button from '../../components/Button';

const MedicarePartD = () => {
  const {
    patientStore,
    updatePatientStore,
    medicarePartDStore,
    updateInsurance,
    updateUiState,
    showModal,
    updateModal,
  } = useClientStore((state) => ({
    patientStore: state.patient,
    updatePatientStore: state.updatePatientInfo,
    medicarePartDStore: state.insurance.medicarePartD,
    updateInsurance: state.updateInsurance,
    updateUiState: state.updateUiState,
    showModal: state.showModal,
    updateModal: state.updateModal,
  }));
  const [inputState, setInputState] = useState({
    Part_D_Plan_Name__c: { value: '' },
    Part_D_Member_ID__c: { value: '' },
    Part_D_Group_Number__c: { value: '' },
    Part_D_Rx_BIN__c: { value: '' },
    Part_D_Rx_PCN__c: { value: '' },
  });
  const fields = [
    {
      label: <L10n tokenID="insurance.planName" />,
      name: 'Part_D_Plan_Name__c',
      value: medicarePartDStore.Part_D_Plan_Name__c,
    },
    {
      label: <L10n tokenID="insurance.memberId" />,
      name: 'Part_D_Member_ID__c',
      value: medicarePartDStore.Part_D_Member_ID__c,
    },
    {
      label: <L10n tokenID="insurance.groupNumber" />,
      name: 'Part_D_Group_Number__c',
      value: medicarePartDStore.Part_D_Group_Number__c,
    },
    {
      label: 'Rx BIN',
      name: 'Part_D_Rx_BIN__c',
      value: medicarePartDStore.Part_D_Rx_BIN__c,
    },
    {
      label: 'Rx PCN',
      name: 'Part_D_Rx_PCN__c',
      value: medicarePartDStore.Part_D_Rx_PCN__c,
    },
  ];

  const handleInputChange = (event) => {
    const { value } = event.target;
    const field = event.target.id;
    setInputState({
      ...inputState,
      [field]: {
        value,
      },
    });
    if (field in patientStore) {
      updatePatientStore(field, value);
    } else if (field in medicarePartDStore) {
      updateInsurance(field, value, 'medicarePartD');
    }
  };

  const handleNext = () => {
    updateUiState('validPage', true);
  };

  const openMedicarePartDModal = (e, modal, props) => {
    updateModal(modal, props);
    showModal();
  };

  return (
    <>
      <RedirectHandler pageNumber={11} />
      <Box
        data-testid="MedicarePartD"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
      >
        <PageTitleSubtitle title={<L10n tokenID="page.medicare_part_d.header" />} />
        <Box display="grid" gridGap="large">
          <Typography tag="h2" variant={['primaryLarge']}>
            <L10n tokenID="page.medicare_part_d.card_info" />
          </Typography>
          <Button
            handleClick={(e) =>
              openMedicarePartDModal(e, 'insuranceCardModal', { insuranceCard: 'pharmacy' })
            }
            styling="plainBlue"
            size="noPadding"
            flexbox={{ justifyContent: 'left', textAlign: 'left' }}
          >
            <L10n tokenID="insurance.help_text" values={{ insuranceType: 'Medicare Part D' }} />
          </Button>
        </Box>
        {fields.map((field) => (
          <FieldGroup
            key={field.name}
            label={field.label}
            name={field.name}
            onChange={handleInputChange}
            value={field.value}
          />
        ))}

        <PageNavigation handleNext={handleNext} />
      </Box>
    </>
  );
};

export default MedicarePartD;
