import React, { useState } from 'react';
import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';
import PageNavigation from '../../components/PageNavigation';
import Button from '../../components/Button';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import { FieldGroup } from '../../components/FieldGroup';
import { Box } from '../../components/Box';
import { L10n } from '../../components/L10n';
import { useClientStore } from '../../store/store';
import { validators, errors } from './validation';

const PharmacyMedical = () => {
  const {
    updatePharmacyMedicalStore,
    getMedicalPlan,
    updateUiState,
    showModal,
    updateModal,
  } = useClientStore((state) => ({
    updatePharmacyMedicalStore: state.updateInsurance,
    getMedicalPlan: state.insurance.medicalPlan,
    updateUiState: state.updateUiState,
    showModal: state.showModal,
    updateModal: state.updateModal,
  }));
  const [inputState, setInputState] = useState({
    Pharmacy_Card_Carrier_Name__c: { value: '', hasError: false, error: '', node: null },
    Pharmacy_Card_Member_Id__c: { value: '', hasError: false, error: '', node: null },
    Pharmacy_Card_Group__c: { value: '', hasError: false, error: '', node: null },
    Pharmacy_Card_RX_BIN__c: { value: '', hasError: false, error: '', node: null },
    Pharmacy_Card_RX_PCN__c: { value: '', hasError: false, error: '', node: null },
    Medical_Card_Carrier_Name__c: { value: '', hasError: false, error: '', node: null },
    Medical_Card_Member_Id__c: { value: '', hasError: false, error: '', node: null },
    Medical_Card_Group__c: { value: '', hasError: false, error: '', node: null },
    Policy_Holder_Name__c: { value: '', hasError: false, error: '', node: null },
    Policy_Holder_Date_of_Birth__c: { value: '', hasError: false, error: '', node: null },
  });

  const validateInput = (input, validator = null, value = null, errorMsg = null) => {
    let hasError = false;
    let error = '';
    if (validator) {
      ({ hasError, error } = validator(value, input?.required, errorMsg));
    }
    return {
      hasError,
      error,
    };
  };

  const handleInputValidation = (event) => {
    const field = event.target.id;
    const { hasError, error } = validateInput(
      event.target,
      validators[field],
      inputState[field].value,
      errors[field]
    );
    setInputState({
      ...inputState,
      [field]: {
        ...inputState[field],
        hasError,
        error,
        node: event.target,
      },
    });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const field = event.target.id;
    setInputState({
      ...inputState,
      [field]: {
        value,
      },
    });
    updatePharmacyMedicalStore(field, value, 'medicalPlan');
  };

  const handleNext = () => {
    let firstError;
    document.querySelectorAll('[name$="__c"]').forEach((node) => {
      const fieldName = node.getAttribute('name');
      if (inputState[fieldName].hasError) {
        if (!firstError) firstError = node;
      }
    });
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      updateUiState('validPage', true);
    }
  };
  const handleButtonClick = (e, modal, props) => {
    updateModal(modal, props);
    showModal();
  };

  return (
    <>
      <RedirectHandler pageNumber={12} />
      <Box
        data-testid="pharmacyMedical"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
      >
        <PageTitleSubtitle
          title={<L10n tokenID="page.pharmacy_medical.pageHeader" />}
          subtitle={<L10n tokenID="page.pharmacy_medical.pageInformationOne" />}
        />
        <Box display="grid" gridGap="large">
          <Typography tag="h2" variant={['primaryLarge']}>
            <L10n tokenID="page.pharmacy_medical.subHeaderTwo" />
          </Typography>
          <Button
            handleClick={(e) =>
              handleButtonClick(e, 'insuranceCardModal', { insuranceCard: 'pharmacy' })
            }
            styling="plainBlue"
            size="noPadding"
            flexbox={{ justifyContent: 'left', textAlign: 'left' }}
          >
            <L10n tokenID="page.pharmacy_medical.informationLinkOne" />
          </Button>
        </Box>
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputOne" />}
          name="Pharmacy_Card_Carrier_Name__c"
          onChange={handleInputChange}
          value={getMedicalPlan.Pharmacy_Card_Carrier_Name__c}
        />
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputTwo" />}
          name="Pharmacy_Card_Member_Id__c"
          onChange={handleInputChange}
          value={getMedicalPlan.Pharmacy_Card_Member_Id__c}
        />
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputThree" />}
          name="Pharmacy_Card_Group__c"
          onChange={handleInputChange}
          value={getMedicalPlan.Pharmacy_Card_Group__c}
        />
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputFour" />}
          name="Pharmacy_Card_RX_BIN__c"
          onChange={handleInputChange}
          value={getMedicalPlan.Pharmacy_Card_RX_BIN__c}
        />
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputFive" />}
          name="Pharmacy_Card_RX_PCN__c"
          onChange={handleInputChange}
          value={getMedicalPlan.Pharmacy_Card_RX_PCN__c}
        />
        <Box display="grid" gridGap="large">
          <Typography tag="h2" variant={['primaryLarge']}>
            <L10n tokenID="page.pharmacy_medical.subHeaderThree" />
          </Typography>
          <Button
            handleClick={(e) =>
              handleButtonClick(e, 'insuranceCardModal', { insuranceCard: 'medical' })
            }
            styling="plainBlue"
            size="noPadding"
            flexbox={{ justifyContent: 'left', textAlign: 'left' }}
          >
            <L10n tokenID="page.pharmacy_medical.informationLinkTwo" />
          </Button>
        </Box>
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputSix" />}
          name="Medical_Card_Carrier_Name__c"
          onChange={handleInputChange}
          value={getMedicalPlan.Medical_Card_Carrier_Name__c}
        />
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputSeven" />}
          name="Medical_Card_Member_Id__c"
          onChange={handleInputChange}
          value={getMedicalPlan.Medical_Card_Member_Id__c}
        />
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputEight" />}
          name="Medical_Card_Group__c"
          onChange={handleInputChange}
          value={getMedicalPlan.Medical_Card_Group__c}
        />
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputNine" />}
          name="Policy_Holder_Name__c"
          onChange={handleInputChange}
          onBlur={handleInputValidation}
          error={inputState.Policy_Holder_Name__c.error}
          value={getMedicalPlan.Policy_Holder_Name__c}
        />
        <FieldGroup
          label={<L10n tokenID="page.pharmacy_medical.inputTen" />}
          name="Policy_Holder_Date_of_Birth__c"
          onChange={handleInputChange}
          onBlur={handleInputValidation}
          help={
            <L10n
              tokenID="general.field.format_helper"
              values={{ data: <L10n tokenID="general.field.format_date" /> }}
            />
          }
          mask="99-99-9999"
          error={inputState.Policy_Holder_Date_of_Birth__c.error}
          value={getMedicalPlan.Policy_Holder_Date_of_Birth__c}
          type="tel"
        />
      </Box>
      <PageNavigation hideNext={false} handleNext={handleNext} />
    </>
  );
};

export default PharmacyMedical;
