import apptHoldExpiration from './apptHoldExpiration/translations.json';
import cancelAppointment from './cancelAppointment/translations.json';
import changeAppointment from './changeAppointment/translations.json';
import dateTimeSelection from './dateTimeSelection/translations.json';
import discardChanges from './discardChanges/translations.json';
import changeAppointmentDateTime from './changeAppointmentDateTime/translations.json';
import upsell from './upsell/translations.json';
import insuranceCards from './insuranceCards/translations.json';
import locationFilter from './locationFilter/translations.json';

export default {
  'en-us': {
    ...apptHoldExpiration['en-us'],
    ...cancelAppointment['en-us'],
    ...changeAppointment['en-us'],
    ...dateTimeSelection['en-us'],
    ...discardChanges['en-us'],
    ...changeAppointmentDateTime['en-us'],
    ...upsell['en-us'],
    ...insuranceCards['en-us'],
    ...locationFilter['en-us'],
  },
  'es-us': {
    ...apptHoldExpiration['es-us'],
    ...cancelAppointment['es-us'],
    ...changeAppointment['es-us'],
    ...dateTimeSelection['es-us'],
    ...discardChanges['es-us'],
    ...changeAppointmentDateTime['es-us'],
    ...upsell['es-us'],
    ...insuranceCards['es-us'],
    ...locationFilter['es-us'],
  },
};
