import styled from 'styled-components';
import css from '@styled-system/css';
import { typographyVariants } from '../Typography/variants';

export const Label = styled.span`
  display: block;

  ${css({
    ...typographyVariants.secondaryBase,
    mb: 'small',
    '&.disabled': {
      color: 'coldBrews.600',
    },
  })}
`;

export const StyledFieldGroup = styled.label`
  display: block;
  flex: 1 0 0;

  ${({ error }) =>
    css({
      color: error ? 'brisket' : 'coldBrews.800',
    })};

  &:focus-within {
    ${({ error }) =>
      css({
        color: error ? 'brisket' : 'coldBrews.800',
      })};
  }

  input:focus {
    ${({ error }) =>
      css({
        borderColor: error ? 'brisket' : 'rxInteractionBlue',
      })};
  }
`;
