import React from 'react';
import PropTypes from 'prop-types';

import TitleBanner from '../TitleBanner';

const Carousel = ({ currPage, children, handleBackClick, handleNextClick, numPages, title }) => {
  return (
    <>
      <TitleBanner
        handleBackClick={handleBackClick}
        handleNextClick={handleNextClick}
        page={currPage}
        title={title}
        totalPages={numPages}
      />
      {children}
    </>
  );
};

Carousel.propTypes = {
  currPage: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  numPages: PropTypes.number.isRequired,
  title: PropTypes.node.isRequired,
};

export default Carousel;
