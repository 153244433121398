import { useIntl } from 'react-intl';
import { useClientStore } from '../store/store';
import {
  vaccinesNotGivenTogether,
  infoByteSpecs,
  MAX_PRODUCTS_IN_APPT,
} from './vaccineSpecifications';

export const VaccineCheckboxList = (vaccinesAvail) => {
  const intl = useIntl();
  const checkbox = {};
  const age = useClientStore.getState().patient.Age;
  vaccinesAvail.forEach((vaccine) => {
    const vaccineName = intl.formatMessage({ id: `vaccine.${vaccine.Label__c}` });
    checkbox[vaccineName] = {
      ...vaccine,
      ...infoByteSpecs(age)[vaccine.Label__c],
      label: vaccineName,
      Label__c: vaccine.Label__c,
      checked: false,
      disabled: false,
      disabledCount: 0, // accounts for when multiple vaccines cannot be given with another vaccine, e.g. hep A and hep B both cannot be given with hep A/B
    };
  });
  const c19 = checkbox['COVID-19'];
  if (checkbox['COVID-19'] && Object.keys(c19?.manufacturers).length === 1) {
    const manufacturerKey = Object.keys(c19.manufacturers)[0];
    const manufacturerName = c19.manufacturers[manufacturerKey].Covid_Manufacturer__c;
    c19.vanityLabel = `${c19.Name} - ${manufacturerName}`;
  }
  return { ...checkbox };
};

export const SelectedVaccinesCheckboxList = (vaccinesSelected, vaccinesFiltered) => {
  const intl = useIntl();
  const checkbox = {};
  Object.keys(vaccinesSelected).forEach((vaccine) => {
    const vaccineName = intl.formatMessage({ id: `vaccine.${vaccine}` });
    checkbox[vaccineName] = {
      ...vaccinesSelected[vaccine],
      info: null,
      label: vaccineName,
      Label__c: vaccinesSelected[vaccine].Label__c,
      checked: Object.keys(vaccinesFiltered).includes(vaccine),
      disabled: false,
      disabledCount: 0,
    };
  });
  return { ...checkbox };
};

export const updateVaccineCheckboxList = (
  value,
  label,
  checkboxGroupData,
  maxSelectionsAvail = MAX_PRODUCTS_IN_APPT
) => {
  const currSelection = checkboxGroupData[label].Label__c;
  const numSelected = Object.values(checkboxGroupData).filter((obj) => obj.checked).length;
  const disablingVaccines = {};
  const sign = value ? 1 : -1;

  if (numSelected + sign === maxSelectionsAvail) {
    Object.values(checkboxGroupData)
      .filter((obj) => !obj.checked && !obj.disabled)
      .forEach((vaccine) => {
        disablingVaccines[vaccine.label] = {
          ...checkboxGroupData[vaccine.label],
          disabled: true,
          disabledFromMaxLimit: true,
        };
      });
  } else if (!value && numSelected === maxSelectionsAvail) {
    Object.values(checkboxGroupData)
      .filter((obj) => obj.disabled && obj.disabledFromMaxLimit)
      .forEach((vaccine) => {
        disablingVaccines[vaccine.label] = {
          ...checkboxGroupData[vaccine.label],
          disabled: vaccine.disabledCount > 0,
          disabledFromMaxLimit: false,
        };
      });
  }

  if (vaccinesNotGivenTogether[currSelection] !== undefined) {
    vaccinesNotGivenTogether[currSelection].forEach((vaccine) => {
      Object.keys(checkboxGroupData).forEach((vax) => {
        if (vaccine === checkboxGroupData[vax].Label__c) {
          const newCount = checkboxGroupData[vax].disabledCount + 1 * sign;
          disablingVaccines[vax] = {
            ...checkboxGroupData[vax],
            disabledCount: newCount,
            disabled: newCount > 0,
          };
        }
      });
    });
  }

  return {
    ...checkboxGroupData,
    ...disablingVaccines,
    [label]: {
      ...checkboxGroupData[label],
      checked: value,
    },
  };
};
