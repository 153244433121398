import styled from 'styled-components';
import css from '@styled-system/css';
import { colors } from '../../themes/keys';
import { FlexBox } from '../Box';

export const RadioBorder = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;

  ${css({
    py: 'medium',
    '&.default': {
      border: 'none',
      gap: 'large',
      py: 'none',
    },
    '&.card': {
      display: 'block',
      border: 'normal',
      borderColor: 'coldBrews.200',
      borderRadius: 'small',
      px: 'large',
      py: '14px',
    },
    '.simple &': {
      border: 'none',
      color: 'coldBrews.800',
      mx: ['none', 'xLarge'],
    },
    '&.error': {
      borderColor: 'briskets.500',
    },
  })}
`;

// Outer container for radio group
export const RadioGroupContainer = styled(FlexBox)`
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;

  ${css({
    gap: 'large',
    '&.default': {
      gap: 'small',
    },
    '&.card': {
      gap: 'small',
    },
    '&.simple': {
      gap: 0,
    },
  })}
`;

// Outer container for individual radio option
export const RadioCardContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;

  ${css({
    py: 'medium',
    '.default &': {
      borderRadius: 'medium',
      borderStyle: 'solid',
      color: 'coldBrews.800',
      px: 'xLarge',
    },
    '&.card': {
      py: '10px',
    },
    '.simple &': {
      border: 'none',
      borderBottom: 'normalMuted',
      color: 'coldBrews.800',
      mx: ['none', 'xLarge'],
    },
    '&.error': {
      borderColor: 'briskets.500',
    },
  })}

  ${({ checked }) =>
    css({
      borderColor: checked ? 'rxInteractionBlue' : 'coldBrews.200',
      borderWidth: checked ? '2px' : '1px',
    })};
`;

// Container for individual radio input and label
export const RadioContainer = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
`;

// Label for radio input
export const RadioLabel = styled.label`
  cursor: pointer;
  line-height: 24px;
  height: 100%;
  width: 100%;
  ${css({
    borderRadius: 'medium',
    color: 'coldBrews.800',
    fontFamily: 'default',
    fontWeight: 'medium',
  })}
`;

export const InfoContainer = styled.div`
  margin: 10px 0px 0px;
  ${css({
    '&.infoByte-enter': {
      opacity: '0.01',
      transform: 'translateY(-100%)',
      '&&-active': { opacity: '1', transform: 'translateY(0%)' },
    },
    transition: 'transform .5s ease-in',
  })}
`;

export const AnimationContainer = styled.div`
  overflow: hidden;
`;

export const StyledRadio = styled.span`
  display: flex;
  vertical-align: middle;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  ${css({
    '&.error': {
      borderColor: 'briskets.500',
    },
  })};}

  ${({ checked }) =>
    css({
      borderColor: checked ? 'rxInteractionBlue' : 'coldBrews.800',
      mr: 'small',
    })};
`;

export const StyledRadioField = styled(FlexBox)`
  align-items: center;
`;

export const StyledInputContainer = styled.label`
  display: flex;
  position: relative;
  width: 100%;
  ${css({
    '.simple &': {
      flexDirection: ['row-reverse', 'row'],
      justifyContent: ['space-between', 'start'],
      width: '100%',
    },
  })}
  & > input[type='radio'] {
    opacity: 0;
    position: absolute;
    height: 0px;
    left: 0;
    width: 0px;
    z-index: 2;
    ${css({
      '&:focus ~ div > span': {
        borderRadius: 'circular',
        boxShadow: `0 0 0 1px #fff, 0 0 0 2px #fff, 0 0 0 3px ${colors.rxInteractionBlue}, 0 0 0 4px ${colors.rxColors.rxInteraction800}`,
      },
    })}

    &:checked + div > {
      ${StyledRadio} {
        border-width: 2px;
        box-shadow: inset 0px 0px 0px 3px #fff;
        ${css({
          bg: 'rxInteractionBlue',
        })}
      }

      ${StyledRadioField} {
        ${({ checked }) =>
          css({
            borderColor: checked ? 'rxInteractionBlue' : 'coldBrews.200',
            borderWidth: checked ? '2px' : '1px',
          })};
      }
    }

    ${css({
      '&:focus:checked ~ div > span': {
        boxShadow: `inset 0px 0px 0px 3px #fff, 0 0 0 1px #fff, 0 0 0 2px #fff, 0 0 0 3px ${colors.rxInteractionBlue}, 0 0 0 4px ${colors.rxColors.rxInteraction800}`,
      },
    })}
  }
`;

export const StyledErrorMessage = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
