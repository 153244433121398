import components from './components';
import modals from './modals';
import pages from './pages';
import translations from './translations.json';

export default {
  'en-us': {
    ...components['en-us'],
    ...modals['en-us'],
    ...pages['en-us'],
    ...translations['en-us'],
  },
  'es-us': {
    ...components['es-us'],
    ...modals['es-us'],
    ...pages['es-us'],
    ...translations['es-us'],
  },
};
