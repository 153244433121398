import styled from 'styled-components';
import css from '@styled-system/css';

import Grid from '../../components/Grid';
import { FlexBox } from '../../components/Box';

const desktopMinimumContent = `'status status status status status status status status status status status status' 'details details details details details details details details details details details details'`;
const desktopFullContent = `'status status status status status status status status status status status status' 'details details details details details details reminders reminders reminders reminders reminders reminders' 'divider divider divider divider divider divider divider divider divider divider divider divider' 'reschedule reschedule reschedule reschedule reschedule reschedule cancel cancel cancel cancel cancel cancel'`;
const mobileMinimumContent = `'status status status status' 'details details details details'`;
const mobileFullContent = `'status status status status' 'details details details details' 'reschedule reschedule reschedule reschedule' 'cancel cancel cancel cancel' 'reminders reminders reminders reminders'`;

export const YAMSGrid = styled(Grid)`
  grid-template-rows: auto;
  ${css({
    rowGap: 'xLarge',
  })}
  ${({ showFullYAMS }) =>
    css({
      gridTemplateAreas: showFullYAMS
        ? [mobileFullContent, desktopFullContent]
        : [mobileMinimumContent, desktopMinimumContent],
    })}
`;

export const LargeGridGapFlexBox = styled(FlexBox)`
  ${css({
    flexDirection: 'column',
    gridGap: 'large',
  })}
`;

export const StyledSpan = styled.span`
  display: block;
  ${css({
    pt: 'medium',
  })}
`;

export const StyledToastLink = styled('span')`
  display: block;
  margin-top: 10px;
`;
