import { useQuery, useMutation, useQueryClient } from 'react-query';
import { format } from 'date-fns';
import { api } from './config/axiosConfig';
import { useClientStore } from '../store/store';
import { showToast } from '../components/Toast';

// AXIOS CALLS
const getAppt = async (dob, confirmation) => {
  const queryStr = `?confirmation=${confirmation}&dob=${dob}`;
  const response = await api.request({
    url: `/appointment${queryStr}`,
    method: 'GET',
  });
  return response;
};

const createAppt = async (payload) => {
  const response = await api.request({
    url: `/appointment`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: payload,
  });
  return response;
};

const updateAppt = async (payload) => {
  const response = await api.request({
    url: `/appointment`,
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
    },
    data: payload,
  });
  return response;
};
// QUERIES
export const useAppointmentQuery = (dob, confirmation) => {
  return useQuery(['appointment', dob, confirmation], () => getAppt(dob, confirmation), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!dob && !!confirmation,
  });
};

// MUTATIONS
export const useCreateAppointment = () => {
  const {
    patient,
    primaryCareProvider,
    insurance,
    apptDetails,
    uiState,
    updateApptDetails,
  } = useClientStore((state) => ({
    patient: state.patient,
    primaryCareProvider: state.primaryCareProvider,
    insurance: state.insurance,
    apptDetails: state.apptDetails,
    uiState: state.uiState,
    updateApptDetails: state.updateApptDetails,
  }));
  const products = Object.keys(apptDetails.products).map((key) => {
    const productId =
      key === 'COVID-19' && apptDetails.products[key].selected
        ? apptDetails.products[key].selected.Id
        : apptDetails.products[key].Id;
    return { Id: productId };
  });

  // Set submitted timestamp on initial submission.
  // Prevents subsequent calls from generating duplicate appointments
  const now = Date.now();
  if (!apptDetails.Submitted_Timestamp__c) {
    updateApptDetails('Submitted_Timestamp__c', now);
  }

  const formatDateStr = (dateStr) => {
    if (dateStr) {
      return format(new Date(dateStr.replace(/-/g, '/')), 'yyyy-MM-dd');
    }
    return null;
  };
  const langPref = uiState.Language_Preference__c === 'Español' ? 'es' : 'en';
  const options = Object.values(apptDetails.FS_Appointment_Options__c).map((option) => ({
    FS_Registration_Option__c: option.Id,
    Value__c: option.Value__c,
  }));
  const payload = {
    appt: {
      Appointment_Date__c: formatDateStr(apptDetails.Appointment_Date__c),
      Appointment_Time__c: apptDetails.Appointment_Time__c,
      Birthdate__c: formatDateStr(patient.Birthdate__c),
      City__c: patient.City__c,
      Consent_to_SMS__c: patient.Consent_to_SMS__c,
      Country__c: patient.Country__c,
      County__c: patient.County__c,
      Email_Address__c: patient.Email_Address__c,
      Ethnicity__c: patient.Ethnicity__c,
      Event__c: apptDetails.FSEvent__c.Id,
      Event_Session__c: apptDetails.Event_Session__c,
      First_Name__c: patient.First_Name__c,
      Gender__c: patient.Gender__c,
      Race__c: patient.Race__c.join(';'),
      State__c: patient.State__c,
      Last_Name__c: patient.Last_Name__c,
      Zip_Code__c: patient.Zip_Code__c,
      Street__c: patient.Street__c,
      Phone_Number__c: patient.Phone_Number__c,
      Consent_Initial__c: patient.Consent_Initial__c,
      Consent_Initial_Option__c: patient.Consent_Initial_Option__c,
      Relationship_to_Patient_Picklist__c: patient.Relationship_to_Patient_Picklist__c,
      Signature__c: patient.Signature__c,
      Name_of_Beneficiary__c: patient.Name_of_Beneficiary__c,
      Medicare_Signature__c: patient.Medicare_Signature__c,
      Language_Preference__c: langPref,
      Submitted_Timestamp__c: apptDetails.Submitted_Timestamp__c,
      ...primaryCareProvider,
      Medicare_Number__c: insurance.medicarePartB.Medicare_Number__c,
      Last_4_Digits_of_SSN__c: insurance.medicarePartB.Last_4_Digits_of_SSN__c,
      ...insurance.medicarePartC,
      ...insurance.medicarePartD,
      Pharmacy_Card_Carrier_Name__c: insurance.medicalPlan.Pharmacy_Card_Carrier_Name__c,
      Pharmacy_Card_Member_Id__c: insurance.medicalPlan.Pharmacy_Card_Member_Id__c,
      Pharmacy_Card_Group__c: insurance.medicalPlan.Pharmacy_Card_Group__c,
      Pharmacy_Card_RX_BIN__c: insurance.medicalPlan.Pharmacy_Card_RX_BIN__c,
      Pharmacy_Card_RX_PCN__c: insurance.medicalPlan.Pharmacy_Card_RX_PCN__c,
      Medical_Card_Carrier_Name__c: insurance.medicalPlan.Medical_Card_Carrier_Name__c,
      Medical_Card_Member_Id__c: insurance.medicalPlan.Medical_Card_Member_Id__c,
      Medical_Card_Group__c: insurance.medicalPlan.Medical_Card_Group__c,
      Policy_Holder_Name__c: insurance.medicalPlan.Policy_Holder_Name__c,
    },
    hold: apptDetails.FS_Session_Hold__c,
    options,
    products,
    images: insurance.images.images,
  };
  // Conditionally setting values for non-required Date fields
  // Apex can't serialize empty strings into date objects.
  if (insurance.medicarePartB.Part_B_effective_date__c) {
    payload.appt.Part_B_effective_date__c = formatDateStr(
      insurance.medicarePartB.Part_B_effective_date__c
    );
  }
  if (insurance.medicalPlan.Policy_Holder_Date_of_Birth__c) {
    payload.appt.Policy_Holder_Date_of_Birth__c = formatDateStr(
      insurance.medicalPlan.Policy_Holder_Date_of_Birth__c
    );
  }
  return useMutation({
    mutationFn: () => createAppt(JSON.stringify(payload)),
    onError: (error) => {
      showToast('Error', 'error', 'top-left', `Create appointment failed: ${error.message}`);
    },
  });
};

export const useRescheduleAppointment = () => {
  return useMutation({
    mutationFn: (data) => {
      const { confirmation, newAppt } = data;
      const formattedNewAppt = { ...newAppt };
      formattedNewAppt.Appointment_Date__c = format(
        new Date(formattedNewAppt.Appointment_Date__c),
        'yyyy-MM-dd'
      );
      const payload = {
        appt: {
          Appointment_Hash__c: confirmation,
          Status__c: 'Cancelled',
          Cancel_Reason__c: 'Customer Rescheduled',
        },
        newAppt: { ...formattedNewAppt },
      };
      return updateAppt(JSON.stringify(payload));
    },
  });
};

export const useCancelAppointment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      const { confirmation } = data;
      const payload = {
        appt: {
          Appointment_Hash__c: confirmation,
          Status__c: 'Cancelled',
        },
      };
      return updateAppt(JSON.stringify(payload));
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['appointment', variables.dob, variables.confirmation]);
      queryClient.setQueryData([
        'appointment',
        variables.dob,
        data.data.records[0].Appointment_Hash__c,
      ]);
    },
  });
};
