import React from 'react';
import { useClientStore } from '../../../store/store';
import {
  AppointmentExpirationModal,
  InsuranceCardModal,
  CancelAppointmentModal,
  ChangeAppointmentModal,
  DateTimeSelectionModal,
  DiscardChangesModal,
  ChangeAppointmentDateTimeModal,
  InformedConsentModal,
  RelationshipToPatientModal,
  UpsellModal,
  LocationFilterModal,
  VaccineInformationModal,
} from '..';

const GlobalModal = () => {
  const { modalProps, modalType, modalOpen } = useClientStore((state) => state.modalState);

  if (modalOpen) {
    document.body.classList.add('modal-open');
  } else if (document.body.classList.contains('modal-open')) {
    document.body.classList.remove('modal-open');
  }

  const modalComponents = {
    appointmentExpirationModal: AppointmentExpirationModal,
    cancelAppointmentModal: CancelAppointmentModal,
    changeAppointmentModal: ChangeAppointmentModal,
    dateTimeSelectionModal: DateTimeSelectionModal,
    discardChangesModal: DiscardChangesModal,
    changeAppointmentDateTimeModal: ChangeAppointmentDateTimeModal,
    informedConsentModal: InformedConsentModal,
    insuranceCardModal: InsuranceCardModal,
    locationFilterModal: LocationFilterModal,
    relationshipToPatientModal: RelationshipToPatientModal,
    upsellModal: UpsellModal,
    vaccineInformationModal: VaccineInformationModal,
  };
  const Modal = modalComponents[modalType];
  // eslint-disable-next-line react/jsx-props-no-spreading
  return modalType !== '' ? <Modal {...modalProps} /> : null;
};

GlobalModal.propTypes = {};

export default GlobalModal;
