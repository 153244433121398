import { create } from 'zustand';
import { produce } from 'immer';

const patientDetails = {
  Age: null, // Used to filter results. Not stored in SF.
  First_Name__c: '',
  Last_Name__c: '',
  Email_Address__c: '',
  Phone_Number__c: '',
  Zip_Code__c: '',
  Street__c: '',
  City__c: '',
  State__c: '',
  Country__c: '',
  County__c: '',
  isPregnant: '', // Used to filter results. Not stored in SF.
  Name_of_Beneficiary__c: '',
  Medicare_Signature__c: '',
  Birthdate__c: '',
  Consent_Initial__c: '',
  Consent_Initial_Option__c: '',
  Relationship_to_Patient_Picklist__c: '',
  Signature__c: '',
  viewedVaccineInformation: false, // used to determine if section of consent and ack is complete, not stored in SF
  Ethnicity__c: '',
  Gender__c: '',
  Race__c: [],
  Consent_to_SMS__c: false,
};

const primaryCareProvider = {
  sharePcpInput__c: '', // used to dynamically display other PCP fields; not stored in SF
  Primary_Care_Provider_Name__c: '',
  Primary_Care_Provider_Phone__c: '',
  Primary_Care_Provider_Fax__c: '',
};

const medicarePartB = {
  Medicare_Number__c: '',
  Last_4_Digits_of_SSN__c: '',
  Part_B_effective_date__c: '',
};

const medicarePartC = {
  Part_C_Plan_Name__c: '',
  Part_C_Member_ID__c: '',
  Part_C_Group_Number__c: '',
  Part_C_Last_4_Digits_of_SSN__c: '',
};

const medicarePartD = {
  Part_D_Plan_Name__c: '',
  Part_D_Member_ID__c: '',
  Part_D_Group_Number__c: '',
  Part_D_Rx_BIN__c: '',
  Part_D_Rx_PCN__c: '',
};

const medicalPlan = {
  Pharmacy_Card_Carrier_Name__c: '',
  Pharmacy_Card_Member_Id__c: '',
  Pharmacy_Card_Group__c: '',
  Pharmacy_Card_RX_BIN__c: '',
  Pharmacy_Card_RX_PCN__c: '',
  Medical_Card_Carrier_Name__c: '',
  Medical_Card_Member_Id__c: '',
  Medical_Card_Group__c: '',
  Policy_Holder_Name__c: '',
  Policy_Holder_Date_of_Birth__c: '',
};

const insuranceImages = {
  images: [],
};

const apptDetails = {
  UrlSpecifiedEvent: '', // Set if url param "eventId" is given with a valid FSEVent__c Id.
  FSEvent__c: {},
  Event_Session__c: '',
  Appointment_Date__c: '',
  Appointment_Time__c: '',
  FS_Session_Hold__c: {},
  products: {},
  FS_Appointment_Options__c: [],
  confirmationNum: '', // set when user creates an appointment or appointment is found in AuthenticateUser
  Submitted_Timestamp__c: '',
};

const uiState = {
  currentPage: 0,
  isPrevAuth: false,
  isLoading: false,
  Language_Preference__c: 'English',
  langPref: 'en-us',
  showIsPregnant: false,
  showLocationSearch: true, // Default to true. Hidden only for direct links
  showCovidManufacturer: false,
  hasInsurance: '',
  showMedicarePartB: false,
  showMedicarePartC: false,
  showMedicarePartD: false,
  showPharmacyMedical: false,
  showMedicareStatement: false,
  validPage: false,
  searchStr: '',
  modalTimer: '',
};

const modalState = {
  modalOpen: false,
  modalType: '',
  modalProps: {},
};

const initialState = {
  patient: { ...patientDetails },
  primaryCareProvider: { ...primaryCareProvider },
  insurance: {
    medicarePartB: { ...medicarePartB },
    medicarePartC: { ...medicarePartC },
    medicarePartD: { ...medicarePartD },
    medicalPlan: { ...medicalPlan },
    images: { ...insuranceImages },
  },
  apptDetails: { ...apptDetails },
  uiState: { ...uiState },
  modalState: { ...modalState },
};

export const useClientStore = create((set) => ({
  ...initialState,
  // mutators below
  updatePatientInfo: (attribute, value) =>
    set(
      produce((state) => {
        state.patient[attribute] = value; // eslint-disable-line no-param-reassign
      })
    ),
  updatePrimaryCareProvider: (attribute, value) =>
    set(
      produce((state) => {
        state.primaryCareProvider[attribute] = value; // eslint-disable-line no-param-reassign
      })
    ),
  updateInsurance: (attribute, value, insuranceType) =>
    set(
      produce((state) => {
        state.insurance[insuranceType][attribute] = value; // eslint-disable-line no-param-reassign
      })
    ),
  updateImages: (value) =>
    set(
      produce((state) => {
        state.insurance.images.images = value; // eslint-disable-line no-param-reassign
      })
    ),
  updateApptDetails: (attribute, value) =>
    set(
      produce((state) => {
        state.apptDetails[attribute] = value; // eslint-disable-line no-param-reassign
      })
    ),
  updateCovidManufacturer: (value) =>
    set(
      produce((state) => {
        state.apptDetails.products['COVID-19'].selected = value; // eslint-disable-line no-param-reassign
      })
    ),
  updateUiState: (attribute, value) =>
    set(
      produce((state) => {
        state.uiState[attribute] = value; // eslint-disable-line no-param-reassign
      })
    ),
  updateModal: (modalType, modalProps = {}) =>
    set(
      produce((state) => {
        state.modalState.modalType = modalType; // eslint-disable-line no-param-reassign
        state.modalState.modalProps = modalProps; // eslint-disable-line no-param-reassign
      })
    ),
  showModal: () =>
    set(
      produce((state) => {
        if (state.modalState.modalType !== '') {
          state.modalState.modalOpen = true; // eslint-disable-line no-param-reassign
        }
      })
    ),
  hideModal: () =>
    set(
      produce((state) => {
        state.modalState.modalType = ''; // eslint-disable-line no-param-reassign
        state.modalState.modalProps = {}; // eslint-disable-line no-param-reassign
        state.modalState.modalOpen = false; // eslint-disable-line no-param-reassign
      })
    ),
  reset: () => {
    set(initialState);
  },
}));
