import React from 'react';
import PropTypes from 'prop-types';
import { StyledColumn, StyledHeader, StyledIcon, StyledTypography } from './styles';
import { Box } from '../Box';
import LanguageSelection from '../LanguageSelection';
import Icon from '../Icon';
import PharmacyLogo from './PharmacyLogo.svg';

const Header = ({ background = 'black', back }) => {
  return (
    <StyledHeader className={background} data-testid="header">
      <StyledColumn className="start">
        {back && (
          <StyledIcon
            href="https://www.heb.com/pharmacy/common/landing"
            aria-label="Back to H-E-B Pharmacy"
          >
            <Box
              width="xxLarge"
              height="xxLarge"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon icon="chevronLeft" styling="base" />
            </Box>
            <StyledTypography>Pharmacy home</StyledTypography>
          </StyledIcon>
        )}
      </StyledColumn>
      <StyledColumn className="center">
        <img src={PharmacyLogo} alt="H-E-B Pharmacy Logo" />
      </StyledColumn>
      <StyledColumn className="end">
        <LanguageSelection />
      </StyledColumn>
    </StyledHeader>
  );
};

Header.propTypes = {
  back: PropTypes.bool,
  background: PropTypes.oneOf(['blue', 'black']),
};

export default Header;
