import styled from 'styled-components';
import css from '@styled-system/css';
import { compose, layout, space, variant } from 'styled-system';
import { ribbonVariants, layoutVariants } from './variants';
import { Box } from '../Box';

export const StyledRibbon = styled(Box)`
  ${css({
    alignItems: 'center',
    backgroundColor: 'rxColors.rxInteraction50',
    gridGap: 'large',
    overflow: 'hidden',
  })}
  ${compose(layout, space)}
  ${variant({
    variants: ribbonVariants,
  })}
`;

export const StyledRibbonContent = styled.div(
  css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  compose(layout),
  variant({
    variants: layoutVariants,
  })
);

export const StyledIcon = styled.div`
  flex: 0 0 auto;
  ${css({
    fill: 'coldBrews.800',
    p: 'none',
  })}
`;
