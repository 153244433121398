import { useQuery } from 'react-query';
import { api } from './config/axiosConfig';
import { useClientStore } from '../store/store';

const getEvents = async (closestMatch, productIds) => {
  const response = await api.request({
    url: `/events?closestMatch=${closestMatch}&products=${productIds}`,
    method: 'GET',
  });
  return response;
};

const getEventById = async (eventId) => {
  const response = await api.request({
    url: `/events?id=${eventId}`,
    method: 'GET',
  });
  return response;
};

export const useEventQuery = (closestMatch, products) => {
  const UrlSpecifiedEvent = useClientStore((state) => state.apptDetails.UrlSpecifiedEvent);
  return useQuery(['events', closestMatch, products], () => getEvents(closestMatch, products), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !UrlSpecifiedEvent && !!closestMatch, // Disable event query if we have direct link event
  });
};

export const useEventQueryById = (eventId) => {
  return useQuery('event', () => getEventById(eventId), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!eventId,
  });
};
