const fieldVariants = {
  base: {
    maxWidth: '100%',
  },
  medium: {
    maxWidth: '50%',
  },
};

export const fieldSizeVariants = {
  ...fieldVariants,
};
