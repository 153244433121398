import React from 'react';
import PropTypes from 'prop-types';
import { StyledOverlay } from './styles';

const Overlay = ({ children, onClick }) => {
  return (
    <StyledOverlay onClick={onClick} role="presentation">
      {children}
    </StyledOverlay>
  );
};

Overlay.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Overlay;
