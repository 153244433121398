import { Link } from 'react-router-dom';
import { variant } from 'styled-system';
import styled from 'styled-components';
import css from '@styled-system/css';
import { linkVariants } from './variants';

export const StyledLink = styled(Link)(
  css({
    fontFamily: 'default',
    lineHeight: 'heading',
    m: 'none',
    textDecoration: 'underline',
  }),
  variant({
    variants: linkVariants,
  })
);
