import styled from 'styled-components';
import css from '@styled-system/css';
import { compose, grid, space } from 'styled-system';
import Grid from '../Grid';

export const StyledBodyContainer = styled(Grid)(
  ({ fullHeight }) =>
    css({
      minHeight: fullHeight && 'calc(100vh - 129px)',
    }),
  css({
    px: ['large', 'xxLarge'],
    m: '0 auto',
  }),
  compose(grid, space)
);

export const StyledInnerGrid = styled(Grid)(
  compose(grid, space),
  'margin: 0 auto;',
  'width: 100%;'
);
