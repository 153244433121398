/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error';

/**
 * Apollo Error Link Callback
 * https://www.apollographql.com/docs/link/links/error/
 * @param {*} object
 * @param {array} object.graphQLErrors
 * @param {object} object.networkError
 */
export const errorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations: [location], path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
};

export default onError(errorHandler);
