import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import { Content, Footer, Header } from '../../ModalComposition';
import { L10n } from '../../L10n';
import Modal from '../../Modal';
import Typography from '../../Typography';
import FormatDate from '../../FormatDate';

const CancelAppointmentModal = ({ dateStr, handleCancel, handleClose, time }) => {
  return (
    <Modal
      onClose={handleClose}
      header={
        <Header withClose handleClose={handleClose}>
          <L10n tokenID="modal.cancelAppointment.title" />
        </Header>
      }
      content={
        <Content>
          <Typography variant={['primaryBase']}>
            <L10n
              tokenID="modal.cancelAppointment.subtitle"
              values={{ date: <FormatDate dateStr={dateStr} />, time }}
            />
          </Typography>
        </Content>
      }
      footer={
        <Footer variant="stacked">
          <Button handleClick={handleClose} size={['hug', 'secondary']} styling="outlined">
            <L10n tokenID="modal.cancelAppointment.keep_appointment" />
          </Button>
          <Button handleClick={handleCancel} size={['hug', 'secondary']}>
            <L10n tokenID="modal.cancelAppointment.cancel_appointment" />
          </Button>
        </Footer>
      }
    />
  );
};

CancelAppointmentModal.propTypes = {
  dateStr: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  time: PropTypes.node.isRequired,
};

export default CancelAppointmentModal;
