/* eslint-disable */
import React from 'react';
import { StyledLanguageSelection } from './styles';
import { useClientStore } from '../../store/store';

const LanguageSelection = () => {
  const { updateUiState, selectedLanguage, langPref } = useClientStore((state) => ({
    updateUiState: state.updateUiState,
    selectedLanguage: state.uiState.Language_Preference__c,
    langPref: state.uiState.langPref,
  }));
  const onButtonClick = () => {
    updateUiState('Language_Preference__c', selectedLanguage == 'English' ? 'Español' : 'English');
    updateUiState('langPref', langPref == 'en-us' ? 'es-us' : 'en-us');
  };

  return (
    <StyledLanguageSelection onClick={onButtonClick}>
      {selectedLanguage === 'English' ? 'Español' : 'English'}
    </StyledLanguageSelection>
  );
};
LanguageSelection.propTypes = {};

export default LanguageSelection;
