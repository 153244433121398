import { variant, compose, flexbox, textAlign } from 'styled-system';
import styled from 'styled-components';
import css from '@styled-system/css';
import { sizeVariants, stylingVariants } from './variants';

export const StyledButton = styled.button(
  css({
    ...stylingVariants.base,
    transition: 'all 0.25s ease',
    height: 'min-content',
    position: 'relative',
    '&.disable': {
      ...stylingVariants.disable,
    },
    '&.disableActive': {
      ...stylingVariants.disableActive,
    },
    '&.link': {
      ...stylingVariants.link,
    },
    '&.mutedBlue': {
      ...stylingVariants.mutedBlue,
    },
    '&.mutedColdbrew': {
      ...stylingVariants.mutedColdbrew,
    },
    '&.outlined': {
      ...stylingVariants.outlined,
    },
    '&.outlinedMinor': {
      ...stylingVariants.outlinedMinor,
    },
    '&.plainBlue': {
      ...stylingVariants.plainBlue,
    },
    '&.plainColdbrew': {
      ...stylingVariants.plainColdbrew,
    },
    '&.transparent': {
      ...stylingVariants.transparent,
    },
    '&.success': {
      ...stylingVariants.success,
    },
  }),
  ({ disabled, circleSize, size }) =>
    css({
      bg: disabled && 'coldBrews.200',
      color: disabled && 'buttercream',
      '&:hover': {
        bg: disabled && 'coldBrews.200',
        color: disabled && 'buttercream',
        cursor: disabled && 'auto',
      },
      height: size === 'circular' && circleSize,
      width: size === 'circular' && circleSize,
    }),
  variant({
    prop: 'size',
    variants: sizeVariants,
  }),
  compose(flexbox, textAlign)
);

export const StyledStartIcon = styled.div(
  css({
    mr: 5,
  })
);

export const StyledEndIcon = styled.div(
  css({
    ml: 5,
  }),
  ({ endIconPosition }) =>
    css({
      position: endIconPosition === 'end' ? 'absolute' : 'static',
      right: endIconPosition === 'end' ? 'large' : undefined,
    })
);

export const stylingConstrainedIconScale = {
  primary: [1],
  secondary: [1],
  tertiary: [0.875],
  tertiaryFill: [0.875],
  small: [0.875],
  hug: [1],
  fill: [1],
};

export const sizeConstrainedButtonStyles = {
  small: 'plainBlue',
};
