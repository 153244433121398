import React from 'react';
import PropTypes from 'prop-types';
import { StyledDivider } from './styles';

const Divider = ({ mobileOnly = true }) => {
  return <StyledDivider data-testid="divider" display={['block', mobileOnly ? 'none' : 'block']} />;
};

Divider.propTypes = {
  mobileOnly: PropTypes.bool,
};

export default Divider;
