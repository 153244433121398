import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledInfoByte = styled.div`
  align-items: ${(props) => props.alignment};
  border-radius: 8px;
  display: block;
  display: flex;
  gap: 16px;
`;

export const StyledIcon = styled.div`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  ${css({
    p: 'none',
  })}
`;

export const StyledInfo = styled.div`
  display: flex;
  line-height: 20px;
  ${css({
    color: 'coldBrews.800',
    fontFamily: 'default',
    fontWeight: 'medium',
    fontSize: 'small',
  })}
`;
