const validateBirthDate = (value, required = false, error) => {
  if (value && value.match(/(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(19|20)\d{2}/)) {
    return { hasError: false, error: null };
  }
  if (!value && !required) {
    return { hasError: false, error: null };
  }
  return { hasError: true, error };
};

export const validators = {
  Birthdate__c: validateBirthDate,
};
