import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import Typography from '../../components/Typography';
import PageNavigation from '../../components/PageNavigation';
import { Box } from '../../components/Box';
import { FieldGroup } from '../../components/FieldGroup';
import { validators } from './validators';
import { useClientStore } from '../../store/store';
import { L10n } from '../../components/L10n';
import Button from '../../components/Button';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import RedirectHandler from '../../components/RedirectHandler';

const MedicarePartC = () => {
  const {
    medicarePartCStore,
    updateInsurance,
    updateUiState,
    showModal,
    updateModal,
  } = useClientStore((state) => ({
    medicarePartCStore: state.insurance.medicarePartC,
    updateInsurance: state.updateInsurance,
    updateUiState: state.updateUiState,
    showModal: state.showModal,
    updateModal: state.updateModal,
  }));

  // Global Store

  const ssnRef = useRef(null);
  const intl = useIntl();

  // Local Store
  const [inputState, setInputState] = useState({
    Part_C_Plan_Name__c: { value: '', hasError: false, error: '', node: null },
    Part_C_Member_ID__c: { value: '', hasError: false, error: '', node: null },
    Part_C_Group_Number__c: { value: '', hasError: false, error: '', node: null },
    Part_C_Last_4_Digits_of_SSN__c: { value: '', hasError: false, error: '', node: null },
  });

  // Validate SSN on blur
  const handleSSNValidation = () => {
    const ssnInput = ssnRef.current;
    const { hasError, error } = validators[ssnInput.props.name](
      ssnInput.props.value,
      ssnInput.props.required,
      intl.formatMessage(
        { id: 'general.field_error.valid_data_error' },
        { data: <L10n tokenID="general.ssn" /> }
      )
    );
    setInputState({
      ...inputState,
      [ssnInput.props.name]: {
        ...inputState[ssnInput.props.name],
        hasError,
        error,
        node: ssnInput,
      },
    });
  };

  // Setting input values in global state
  const handleInputChange = (event) => {
    const { value } = event.target;
    const field = event.target.id;
    setInputState({
      ...inputState,
      [field]: {
        value,
      },
    });
    if (field in medicarePartCStore) {
      updateInsurance(field, value, 'medicarePartC');
    }
  };

  // Validate SSN on next
  const handleNext = () => {
    let currentState = { ...inputState };
    const ssnInput = ssnRef.current;
    const { hasError, error } = validators[ssnInput.props.name](
      ssnInput.props.value,
      ssnInput.props.required,
      <L10n
        tokenID="general.field_error.valid_data_error"
        values={{
          data: <L10n tokenID="general.ssn" />,
        }}
      />
    );
    if (hasError) {
      currentState = {
        ...currentState,
        [ssnInput.props.name]: {
          ...inputState[ssnInput.props.name],
          hasError,
          error,
          ssnInput,
        },
      };
      setInputState({ ...currentState });
      ssnInput.getInputDOMNode().scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      updateUiState('validPage', true);
    }
  };

  const openMedicarePartCModal = (e, modal, props) => {
    updateModal(modal, props);
    showModal();
  };

  return (
    <>
      <RedirectHandler pageNumber={10} />

      <Box
        data-testid="MedicarePartC"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
      >
        <PageTitleSubtitle title={<L10n tokenID="page.medicare_part_c.title" />} />
        <Box display="grid" gridGap="large">
          <Typography tag="h2" variant={['primaryLarge']}>
            <L10n tokenID="page.medicare_part_c.card_info" />
          </Typography>
          <Button
            handleClick={(e) =>
              openMedicarePartCModal(e, 'insuranceCardModal', { insuranceCard: 'medical' })
            }
            styling="plainBlue"
            size="noPadding"
            flexbox={{ justifyContent: 'left', textAlign: 'left' }}
          >
            <L10n
              tokenID="insurance.help_text"
              values={{
                insuranceType: 'Medicare Part C',
              }}
            />
          </Button>
        </Box>
        <FieldGroup
          label={<L10n tokenID="insurance.planName" />}
          name="Part_C_Plan_Name__c"
          onChange={handleInputChange}
          error={inputState.Part_C_Plan_Name__c.error}
          value={medicarePartCStore.Part_C_Plan_Name__c}
        />
        <FieldGroup
          label={<L10n tokenID="insurance.memberId" />}
          name="Part_C_Member_ID__c"
          onChange={handleInputChange}
          error={inputState.Part_C_Member_ID__c.error}
          value={medicarePartCStore.Part_C_Member_ID__c}
        />
        <FieldGroup
          label={<L10n tokenID="insurance.groupNumber" />}
          name="Part_C_Group_Number__c"
          onChange={handleInputChange}
          error={inputState.Part_C_Group_Number__c.error}
          value={medicarePartCStore.Part_C_Group_Number__c}
        />
        <FieldGroup
          label={<L10n tokenID="insurance.last_4_ssn" />}
          variant={['base', 'medium']}
          mask="9999"
          name="Part_C_Last_4_Digits_of_SSN__c"
          help={
            <L10n
              tokenID="general.field.format_helper"
              values={{ data: <L10n tokenID="general.field.format_ssn" /> }}
            />
          }
          onChange={handleInputChange}
          onBlur={handleSSNValidation}
          ref={ssnRef}
          error={inputState.Part_C_Last_4_Digits_of_SSN__c.error}
          value={medicarePartCStore.Part_C_Last_4_Digits_of_SSN__c}
          type="tel"
        />
        <PageNavigation handleNext={handleNext} />
      </Box>
    </>
  );
};

export default MedicarePartC;
