import React from 'react';
import PropTypes from 'prop-types';
import { StyledFlexBox } from './styles';
import { Box } from '../Box';
import Button from '../Button';
import Icon from '../Icon';

const ProgressionButton = ({
  direction,
  handleClick,
  isAvailable = true,
  buttonStyling = 'plainBlue',
  iconStyling = 'plainBlue',
  disabled = false,
  size,
}) => {
  return isAvailable ? (
    <Button
      size="circular"
      disabled={disabled}
      styling={buttonStyling}
      handleClick={handleClick}
      circleSize={size}
    >
      <StyledFlexBox textAlign="center" justifyContent="center">
        <Icon
          title={direction}
          icon={direction === 'next' ? 'rightProgression' : 'leftProgression'}
          styling={iconStyling}
        />
      </StyledFlexBox>
    </Button>
  ) : (
    <Box height="38px" width="38px" />
  );
};

ProgressionButton.propTypes = {
  direction: PropTypes.oneOf(['next', 'back']).isRequired,
  handleClick: PropTypes.func.isRequired,
  isAvailable: PropTypes.bool,
  buttonStyling: PropTypes.oneOf([
    'base',
    'disable',
    'disableActive',
    'mutedBlue',
    'mutedColdbrew',
    'outlined',
    'outlinedMinor',
    'plainBlue',
    'plainColdbrew',
    'success',
  ]),
  iconStyling: PropTypes.oneOf([
    'base',
    'error',
    'outlined',
    'mutedBlue',
    'mutedColdbrew',
    'plainBlue',
    'plainColdbrew',
    'success',
  ]),
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

export default ProgressionButton;
