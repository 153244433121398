import { gql } from '@apollo/client';

export const STORE_SEARCH_QUERY = gql`
  query StoreSearch(
    $address: String!
    $radius: Int!
    $fulfillmentChannels: [FulfillmentChannelName]
  ) {
    searchStoresByAddress(
      address: $address
      radiusMiles: $radius
      fulfillmentChannels: $fulfillmentChannels
    ) {
      stores {
        distanceMiles
        store {
          storeNumber
        }
      }
    }
  }
`;
