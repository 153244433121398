import React from 'react';
import PropTypes from 'prop-types';
import { StyledNav } from './styles';

const NavBar = ({ position, children, symmetricalNav = false }) => {
  return (
    <StyledNav
      role="navigation"
      aria-label="navigation bar"
      position={position}
      symmetricalNav={symmetricalNav}
    >
      {children}
    </StyledNav>
  );
};

const positioning = ['center', 'spaceBetween', 'left', 'right'];

NavBar.propTypes = {
  position: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(positioning)),
    PropTypes.oneOf(positioning),
  ]).isRequired,
  children: PropTypes.node,
  symmetricalNav: PropTypes.bool,
};

export default NavBar;
