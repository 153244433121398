import React from 'react';

import { L10n } from '../../components/L10n';
import Typography from '../../components/Typography';

const shinglesInfoByte = {
  info: (
    <Typography variant={['primaryMedium']}>
      <L10n tokenID="infoByte.shingles.under_fifty" />
    </Typography>
  ),
  infoVariant: 'callOut',
};
const RSVInfoByte = {
  info: (
    <Typography variant={['primaryMedium']}>
      <L10n tokenID="infoByte.RSV.under_seventy_five" />
    </Typography>
  ),
  infoVariant: 'callOut',
};

export const infoByteSpecs = (age) => ({
  shingles: age < 50 ? shinglesInfoByte : {},
  RSV: age < 75 ? RSVInfoByte : {},
});
