import React from 'react';
import PropTypes from 'prop-types';
import { StyledDiv } from './styles';
import themes from '../../themes';
import ProgressRings from '../ProgressRings';
import Overlay from '../Overlay';

const Loader = ({ scale = [5] }) => {
  const scaledDimension = (dimension) => {
    const arr = [];
    for (let i = 0; i < themes.breakpoints.length; i += 1) {
      arr[i] = dimension * (scale[i] ? scale[i] : scale[scale.length - 1]);
    }
    return arr;
  };
  return (
    <Overlay data-testid="loader-overlay">
      <StyledDiv
        data-testid="loader-container"
        height={scaledDimension('16')}
        width={scaledDimension('16')}
      >
        <ProgressRings />
      </StyledDiv>
    </Overlay>
  );
};

Loader.propTypes = {
  scale: PropTypes.arrayOf(PropTypes.number),
};

export default Loader;
