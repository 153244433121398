import React, { Children, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledFooter } from './styles';

const Footer = forwardRef(({ children, variant, px = 'xLarge' }, ref) => {
  const calculatedVariant = () => {
    if (Children.count(children) === 1) return 'single';
    return variant;
  };
  return (
    <StyledFooter px={px} variant={calculatedVariant()} ref={ref}>
      {children}
    </StyledFooter>
  );
});

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  px: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  variant: PropTypes.oneOf(['single', 'stacked', 'sideBySide']),
};

export default Footer;
