import React from 'react';
import PropTypes from 'prop-types';
import { StyledTypography } from './styles';

const tagMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
};

const Typography = ({ children, tag = 'p', variant, color, space, id }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledTypography as={tagMap[tag]} variant={variant} {...color} {...space} id={id}>
      {children}
    </StyledTypography>
  );
};

const variants = [
  'errorPrimaryBase',
  'errorPrimarySmall',
  'errorSecondaryBase',
  'primaryWeb1',
  'primaryWeb2',
  'primaryWeb3',
  'primaryWeb4',
  'primaryXXLarge',
  'primaryXLarge',
  'primaryLarge',
  'primaryBase',
  'primaryMedium',
  'primarySmall',
  'primaryXSmall',
  'secondaryWeb1',
  'secondaryWeb2',
  'secondaryWeb3',
  'secondaryWeb4',
  'secondaryXXLarge',
  'secondaryXLarge',
  'secondaryLarge',
  'secondaryBase',
  'secondaryMedium',
  'secondarySmall',
  'secondaryXSmall',
  'italicsBase',
  'italicsMedium',
  'italicsSmall',
  'underlineSmall',
  'underlineMedium',
  'strikethroughPrimaryMedium',
  'strikethroughSecondaryMedium',
  'strikethroughPrimarySmall',
  'strikethroughSecondarySmall',
  'errorPrimaryBase',
  'errorPrimarySmall',
  'errorSecondaryBase',
  'hyperLinkPrimaryBase',
  'hyperLinkSecondaryBase',
  'errorPrimaryMedium',
  'boldBase',
  'boldSmall',
];

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.objectOf(PropTypes.string),
  space: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']),
  variant: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(variants)),
    PropTypes.oneOf(variants),
  ]).isRequired,
  id: PropTypes.string,
};

export default Typography;
