import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useClientStore } from '../../../store/store';

import { Box, FlexBox } from '../../Box';
import Button from '../../Button';
import Card from '../../Card';
import { Content, Footer, Header } from '../../ModalComposition';
import DateTimeSelection from '../../DateTimeSelection';
import { L10n } from '../../L10n';
import Modal from '../../Modal';
import Typography from '../../Typography';
import InfoByte from '../../InfoByte';

const DateTimeSelectionModal = ({ locationData, handleClose, filteredProducts }) => {
  const modalPadding = ['large', 'xLarge'];
  const { apptDetails, updateApptDetails } = useClientStore((state) => ({
    updateApptDetails: state.updateApptDetails,
    apptDetails: state.apptDetails,
  }));
  const headerRef = useRef();
  const footerRef = useRef();
  const contentRef = useRef();

  const [modalAndFooterHeight, setModalAndFooterHeight] = useState(0);
  const [selectedAppt, setSelectedAppt] = useState(
    apptDetails.FSEvent__c.Id === locationData.Id
      ? {
          Event_Session__c: apptDetails.Event_Session__c,
          Appointment_Time__c: apptDetails.Appointment_Time__c,
          Appointment_Date__c: apptDetails.Appointment_Date__c.toString(),
        }
      : { Event_Session__c: '', Appointment_Time__c: '', Appointment_Date__c: '' }
  );
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!headerRef?.current?.clientHeight || !footerRef?.current?.clientHeight) {
      return;
    }
    setModalAndFooterHeight(headerRef?.current?.clientHeight + footerRef?.current?.clientHeight);
  }, [headerRef?.current?.clientHeight, footerRef?.current?.clientHeight]);

  const handleSaveClick = () => {
    if (selectedAppt.Event_Session__c === '' || selectedAppt.Event_Session__c === undefined) {
      setShowError(true);
      contentRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      Object.keys(selectedAppt).forEach((key) => {
        updateApptDetails(key, selectedAppt[key]);
      });
      updateApptDetails('FS_Session_Hold__c.Id', selectedAppt.Event_Session__c); // TODO: create session hold

      updateApptDetails('FSEvent__c', {
        ...apptDetails.FSEvent__c,
        Id: locationData.Id,
        Location__c: locationData.Location__c,
        Location__r: locationData.Location__r,
        Event_Address__c: locationData.Event_Address__c,
        Name: locationData.Name,
      });
      handleClose();
    }
  };

  const handleApptSelection = (apptDetailsObj) => {
    setSelectedAppt((currAppt) => ({
      ...currAppt,
      ...apptDetailsObj,
    }));
  };

  return (
    <Modal
      onClose={handleClose}
      isMobileFullScreen
      isDesktopFullScreen
      canCloseOnClickOutside
      header={
        <Header withClose handleClose={handleClose} px={modalPadding} ref={headerRef}>
          <L10n tokenID="modal.dateTimeSelection.select_date_and_time" />
        </Header>
      }
      content={
        <Content heightOffset={`${modalAndFooterHeight}px`} px={modalPadding} ref={contentRef}>
          <FlexBox flexDirection="column" gridGap={['xLarge']} height="100%">
            {showError ? (
              <InfoByte
                variant="error"
                info={<L10n tokenID="modal.dateTimeSelection.error_no_time_selection" />}
              />
            ) : null}
            <Box flex="0 1 auto">
              <Typography variant="secondaryBase" space={{ pb: 'small' }}>
                <L10n tokenID="modal.dateTimeSelection.location" />
              </Typography>
              <Card variant="muted" margin="none" borderRadius="small">
                <Typography variant="secondaryMedium">{locationData.Name}</Typography>
              </Card>
            </Box>
            <DateTimeSelection
              displayTimeslotHold
              selectedAppt={selectedAppt}
              handleApptSelection={(apptDetailsObj) => handleApptSelection(apptDetailsObj)}
              location={locationData}
              filteredProducts={filteredProducts}
            />
          </FlexBox>
        </Content>
      }
      footer={
        <Footer variant="single" px={modalPadding} ref={footerRef}>
          <Button handleClick={handleSaveClick}>
            <L10n tokenID="modal.dateTimeSelection.save" />
          </Button>
        </Footer>
      }
    />
  );
};

DateTimeSelectionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  locationData: PropTypes.shape({
    Event_Address__c: PropTypes.string,
    Id: PropTypes.string,
    Location__c: PropTypes.string,
    Name: PropTypes.string,
    attributes: PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
    }),
    Location__r: PropTypes.shape({
      Geolocation__Latitude__s: PropTypes.number,
      Geolocation__Longitude__s: PropTypes.number,
      Id: PropTypes.string,
      Postal_Code_Text__c: PropTypes.string,
      Primary_City__c: PropTypes.string,
      State_Text__c: PropTypes.string,
      Street_One__c: PropTypes.string,
      attributes: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  }),
  // eslint-disable-next-line
  filteredProducts: PropTypes.object,
};

export default DateTimeSelectionModal;
