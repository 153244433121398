import { useQuery } from 'react-query';
import { api } from './config/axiosConfig';

const getProducts = async () => {
  const response = await api.request({
    url: `/products`,
    method: 'GET',
  });
  return response;
};

export const useProductQuery = () => {
  return useQuery('products', () => getProducts(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
