import * as React from 'react';
import PropTypes from 'prop-types';
import { StyledLink } from './styles';

const Link = ({ children, href, variant = ['highlight'], external }) => {
  return (
    <StyledLink variant={variant} to={href} target={external && '_blank'}>
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  href: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['highlight', 'image', 'white', 'boldHighlight', 'muted']),
  external: PropTypes.bool,
};

export default Link;
