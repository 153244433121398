import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledStepper = styled.div`
  ${({ num }) =>
    css({
      alignItems: 'end',
      display: 'grid',
      gridTemplateColumns: [`repeat(${num}, minmax(0, 1fr))`],
      justifyContent: 'center',
      justifyItems: 'center',
      m: 'auto',
      maxWidth: '100%',
      textAlign: 'center',
    })};
  })
`;

export const StyledBarStep = styled.div(
  css({
    position: 'relative',
    width: '100%',
  })
);

export const StyledDot = styled.div(
  css({
    bg: 'buttercream',
    border: '2px solid',
    borderColor: 'rxColors.rxMediumGray',
    borderRadius: 'circular',
    height: ['16px', '20px'],
    m: 'auto',
    width: ['16px', '20px'],
    '&.activeStep': {
      borderColor: 'rxColors.rxInteraction800',
    },
    '&.completedStep': {
      borderColor: 'rxBrandBlue',
      bg: 'rxBrandBlue',
    },
  })
);

export const StyledLeftBar = styled.div(
  css({
    bg: 'coldBrews.200',
    flexShrink: '0',
    height: 'xSmall',
    position: 'absolute',
    right: '50%',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
    zIndex: '-1',
    '&.activeBar': {
      bg: 'rxBrandBlue',
    },
  })
);

export const StyledTitleStep = styled.div(
  css({
    position: 'relative',
    width: '100%',
  })
);
