import styled, { keyframes } from 'styled-components';
import { compose, space } from 'styled-system';
import { Box } from '../Box';

const shimmerAnimation = keyframes`
 0% { background-position: 100% 100%; }
 100% { background-position: 0% 0%; }
`;

export const StyledBox = styled(Box)`
  animation: 2s ease-in-out 0s infinite normal none running ${shimmerAnimation};
  background-image: linear-gradient(
    135deg,
    rgb(238, 236, 236) 30%,
    rgb(255, 255, 255) 50%,
    rgb(238, 236, 236) 70%
  );
  background-size: 300% 300%;
  ${compose(space)}
`;
