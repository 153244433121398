export * from './covidManufacturers';
export * from './infoByteSpecs';
export * from './pregnancyRestrictedVaccines';
export * from './vaccineAgeRestrictions';
export * from './vaccinesNotGivenTogether';
export const MAX_PRODUCTS_IN_APPT = 5;
export const MIN_PRODUCTS_FOR_UPSELL = 3;
export const MIN_AGE_FOR_VACCINES = 3; // Minimum age for flu and covid vaccines
export const OTHER_VACCINE_MIN_AGE = 14; // Mininum age for vaccines other than flu and covid
export const AGE_FOR_ADULT_VACCINES = 18;
