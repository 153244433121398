import React from 'react';
import { L10n } from '../../components/L10n';

const validateName = (value, required = false, error) => {
  if (value && value.toLowerCase().match(/^([^0-9]*)$/)) {
    return { hasError: false, error: null };
  }
  if (!value && !required) {
    return { hasError: false, error: null };
  }
  return { hasError: true, error };
};

const validateDate = (value, required = false, error) => {
  if (value && value.match(/(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(19|20)\d{2}/)) {
    return { hasError: false, error: null };
  }
  if (!value && !required) {
    return { hasError: false, error: null };
  }
  return { hasError: true, error };
};
export const validators = {
  Policy_Holder_Date_of_Birth__c: validateDate,
  Policy_Holder_Name__c: validateName,
};

export const errors = {
  Policy_Holder_Date_of_Birth__c: (
    <L10n
      tokenID="general.field_error.valid_data_error"
      values={{ data: <L10n tokenID="general.date_lowercase" /> }}
    />
  ),
  Policy_Holder_Name__c: (
    <L10n
      tokenID="general.field_error.valid_data_error"
      values={{ data: <L10n tokenID="general.name" /> }}
    />
  ),
};
