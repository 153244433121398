import React from 'react';
import PropTypes from 'prop-types';
import { StyledBox } from './styles';

const Shimmer = ({ height, borderRadius = 'circular', width = '100%', space }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledBox height={height} borderRadius={borderRadius} width={width} {...space} />;
};

Shimmer.propTypes = {
  borderRadius: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  space: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
  width: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

export default Shimmer;
