const center = {
  justifyContent: 'center',
};
const left = {
  justifyContent: 'start',
};
const right = {
  justifyContent: 'end',
};
const spaceBetween = {
  justifyContent: 'space-between',
};

export const positionVariants = { center, left, right, spaceBetween };
