import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import css from '@styled-system/css';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    border-radius: 12px;
    padding: 0px;

    ${css({
      minWidth: ['300px', '450px'],
    })}
  }
  }
  .Toastify__toast {
    border-radius: inherit;
    box-shadow: none;
    cursor: auto;
    padding: 0px;
    ${css({
      m: 'large',
    })}
  }
  .Toastify__toast-body {
    padding: 0px;
  }
  .Toastify__progress-bar {
    visibility:hidden;
  }

`;

export const ToastBody = styled.div`
  flex: 1 0 0;
`;

export const ToastIcon = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 10px;
`;

export const ToastClose = styled.button`
  cursor: pointer;
  height: 24px;
  width: 24px;
  ${css({
    bg: 'transparent',
    border: 'none',
    outline: 'none',
    p: 'none',
  })}
`;

export const ToastTitle = styled.div`
  flex: 1 0 0;
`;

export const TitleContainer = styled.span`
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 4px;
`;

export const ToastFlex = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 16px;
`;

export const ToastContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 4px;
`;
