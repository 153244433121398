import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '../Box';
import Divider from '../Divider';
import Typography from '../Typography';

const CardSection = ({
  children,
  gridGap = 'xLarge',
  hasBottomLine = true,
  hasTopLine = true,
  subtitle,
  title,
  titleTag = 'h3',
}) => {
  return (
    <Box display="flex" gridGap={gridGap} flexDirection="column">
      {hasTopLine && <Divider mobileOnly={false} />}
      <Box display="flex" gridGap="small" flexDirection="column">
        <Typography tag={titleTag} variant="secondaryBase">
          {title}
        </Typography>
        {subtitle ? (
          <Typography variant="primaryBase" tag="span">
            {subtitle}
          </Typography>
        ) : null}
        {children || null}
      </Box>
      {hasBottomLine && <Divider mobileOnly={false} />}
    </Box>
  );
};

CardSection.propTypes = {
  children: PropTypes.node,
  gridGap: PropTypes.oneOf([
    'none',
    'xxxSmall',
    'xxSmall',
    'xSmall',
    'small',
    'medium',
    'large',
    'xLarge',
    'xxLarge',
    'xxxLarge',
    'xxxxLarge',
    'xxxxxLarge',
  ]),
  hasBottomLine: PropTypes.bool,
  hasTopLine: PropTypes.bool,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  titleTag: PropTypes.string,
};

export default CardSection;
