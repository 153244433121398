import * as React from 'react';
import PropTypes from 'prop-types';
import { StyledCard } from './styles';

const Card = ({
  children,
  variant,
  margin = 'large',
  fullHeight = false,
  borderRadius = 'large',
  padding = 'large',
}) => {
  return (
    <StyledCard
      variant={variant}
      margin={margin}
      fullHeight={fullHeight}
      borderRadius={borderRadius}
      padding={padding}
    >
      {children}
    </StyledCard>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  borderRadius: PropTypes.string,
  fullHeight: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Card;
