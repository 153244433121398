import styled from 'styled-components';
import css from '@styled-system/css';
import { colors } from '../../themes/keys';

export const UncheckedBox = styled.div`
  border-style: solid;
  cursor: pointer;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  width: 18px;
  user-select: none;
  ${({ checked }) =>
    css({
      borderColor: checked ? 'rxInteractionBlue' : 'coldBrews.800',
      borderRadius: '2px',
      borderWidth: checked ? '0px' : '1px',
    })};

  ${({ disabled }) =>
    disabled &&
    css({
      bg: 'coldBrews.200',
      borderColor: '#A6A6A6',
      cursor: 'auto',
    })};
  ${css({
    '.error &': {
      border: 'normalError',
    },
  })}
`;

export const StyledCheckbox = styled.input`
  height: 18px;
  margin: 0;
  position: absolute;
  opacity: 0;
  top: 0;
  width: 18px;
  z-index: 2;
  ${css({
    '&:focus': {
      '& ~ div': {
        borderRadius: '2px',
        boxShadow: `0 0 0 1px #fff, 0 0 0 2px #fff, 0 0 0 3px ${colors.rxInteractionBlue}, 0 0 0 4px ${colors.rxColors.rxInteraction800}`,
        '-moz-box-shadow': `0 0 0 1px #fff, 0 0 0 2px #fff, 0 0 0 3px ${colors.rxInteractionBlue}, 0 0 0 4px ${colors.rxColors.rxInteraction800}`,
        '-webkit-shadow': `0 0 0 1px #fff, 0 0 0 2px #fff, 0 0 0 3px ${colors.rxInteractionBlue}, 0 0 0 4px ${colors.rxColors.rxInteraction800}`,
      },
    },
  })}
`;
