import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledGrid = styled.div(
  css({
    columnGap: 'large',
    display: 'grid',
    gridTemplateColumns: ['repeat(4, minmax(0, 1fr))', 'repeat(12, minmax(0, 1fr))'],
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '1440px',
  })
);
