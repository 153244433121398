import React from 'react';
import PropTypes from 'prop-types';

import { Box, FlexBox } from '../../Box';
import ProgressionButton from '../../ProgressionButton';

const TitleBanner = ({ title, handleBackClick, handleNextClick, page, totalPages }) => {
  return (
    <FlexBox
      alignItems="center"
      bg="rxColors.rxInteraction50"
      flexDirection="row"
      justifyContent="space-between"
      mb="large"
      p="large"
    >
      <ProgressionButton
        direction="back"
        handleClick={handleBackClick}
        isAvailable={page > 0}
        size="38px"
      />
      <Box textAlign="center">{title}</Box>
      <ProgressionButton
        direction="next"
        handleClick={handleNextClick}
        isAvailable={page < totalPages - 1}
        size="38px"
      />
    </FlexBox>
  );
};

TitleBanner.propTypes = {
  handleBackClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  title: PropTypes.node.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default TitleBanner;
