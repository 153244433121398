import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line react/jsx-props-no-spreading
export const L10n = ({ tokenID, ...rest }) => <FormattedMessage id={tokenID} {...rest} />;

L10n.propTypes = {
  tokenID: PropTypes.string.isRequired,
};
