import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { handleFocus } from '../../utils/common';
import Icon from '../Icon';
import { StyledInput, StyledInputContainer, StyledIcon } from './styles';

const Input = forwardRef(
  (
    {
      name,
      type,
      error,
      required,
      placeholder,
      maxlength,
      onChange,
      mask,
      disabled,
      onBlur,
      variant,
      value,
      ariaLabel,
      onKeyDown,
      enterKeyHint,
      autocomplete,
    },
    ref
  ) => {
    const styling = disabled ? 'disabled' : 'base';

    return (
      <StyledInputContainer variant={variant}>
        <StyledInput
          className={styling}
          disabled={disabled}
          as={mask ? InputMask : undefined}
          maskChar={null}
          id={name}
          name={name}
          required={required}
          error={error}
          placeholder={placeholder}
          type={type}
          maxLength={maxlength}
          onBlur={onBlur}
          onChange={onChange}
          mask={mask}
          ref={ref}
          value={value}
          aria-label={ariaLabel}
          onKeyDown={onKeyDown}
          onFocus={(e) => handleFocus(e)}
          enterKeyHint={enterKeyHint}
          autoComplete={autocomplete}
          maskPlaceholder={null}
        />
        {error && (
          <StyledIcon>
            <Icon icon="alert" styling="error" />
          </StyledIcon>
        )}
      </StyledInputContainer>
    );
  }
);

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'search', 'tel']).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  maxlength: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  mask: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  variant: PropTypes.arrayOf(PropTypes.oneOf(['base', 'medium'])),
  value: PropTypes.string,
  ariaLabel: PropTypes.string,
  onKeyDown: PropTypes.func,
  enterKeyHint: PropTypes.string,
  autocomplete: PropTypes.string,
};

export { Input };
