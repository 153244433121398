import styled from 'styled-components';
import css from '@styled-system/css';
import { Box } from '../Box';

export const ModalPositioning = styled(Box)`
  ${({ isMobileFullScreen }) =>
    css({
      inset: [isMobileFullScreen ? '0' : 'auto', 'auto'], // inset needs to be before left and top
      left: [!isMobileFullScreen && '50%', '50%'],
      position: 'fixed',
      top: [!isMobileFullScreen && '50%', '50%'],
      transform: [!isMobileFullScreen && 'translate(-50%, -50%)', 'translate(-50%, -50%)'],
      width: '100vw',
      zIndex: 'dialog',
    })}
`;

export const ModalContainer = styled(Box)`
  ${({ open }) =>
    css({
      opacity: open ? 1 : 0,
      visibility: open ? 'visible' : 'hidden',
      display: open ? 'block' : 'none',
    })}
  transition: opacity .5s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const StyledModal = styled(Box)`
  ${css({
    bg: 'buttercream',
    gridColumn: ['1/5', '1/13'],
    maxHeight: ['100%', 'calc(100vh - 64px)'],
    // eslint-disable-next-line no-dupe-keys
    maxHeight: ['-webkit-fill-available', 'calc(100vh - 64px)'],
    overflowY: 'auto',
    width: '100%',
  })}
`;
