import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useClientStore } from '../../store/store';
import { ModalPositioning, ModalContainer, StyledModal } from './styles';
import Overlay from '../Overlay';
import BodyContainer from '../BodyContainer';
import { Box } from '../Box';

const Modal = ({
  canCloseOnClickOutside = true,
  content,
  footer,
  header,
  isDesktopFullScreen = false,
  isMobileFullScreen = false,
  onClose,
  portalDomNode,
  gridColumns = ['1 / 5', '1/13', '3/11', '4/10'],
}) => {
  const ParentContainer = !isMobileFullScreen || window.screen.width >= 768 ? BodyContainer : Box;
  const { modalOpen } = useClientStore((state) => state.modalState);

  return modalOpen
    ? createPortal(
        <ModalContainer open={modalOpen}>
          <Overlay onClick={canCloseOnClickOutside && onClose ? onClose : undefined} />
          <ModalPositioning isMobileFullScreen={isMobileFullScreen} display="inline">
            <ParentContainer
              gridColumn={gridColumns}
              paddingBottom="none"
              paddingTop="none"
              paddingLeft={[isMobileFullScreen ? 'none' : 'large', 'xxLarge']}
              paddingRight={[isMobileFullScreen ? 'none' : 'large', 'xxLarge']}
              fullHeight={isMobileFullScreen && window.screen.width < 768}
              display="inline"
            >
              <StyledModal
                role="dialog"
                borderRadius={isMobileFullScreen ? ['0px', 'large'] : 'large'}
                height={[
                  isMobileFullScreen ? '100%' : 'auto',
                  isDesktopFullScreen ? 'calc(100% - 64px)' : 'auto',
                ]}
              >
                {header}
                {content}
                {footer}
              </StyledModal>
            </ParentContainer>
          </ModalPositioning>
        </ModalContainer>,
        portalDomNode || document.querySelector('#modal-root')
      )
    : null;
};

Modal.propTypes = {
  canCloseOnClickOutside: PropTypes.bool,
  content: PropTypes.node,
  footer: PropTypes.node,
  gridColumns: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.node,
  isDesktopFullScreen: PropTypes.bool,
  isMobileFullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  portalDomNode: PropTypes.object, // eslint-disable-line
};

export default Modal;
