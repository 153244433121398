import styled from 'styled-components';
import css from '@styled-system/css';
import { variant } from 'styled-system';
import { fieldSizeVariants } from './variants';

export const StyledIcon = styled.div`
  position: absolute;
  right: 16px;
  width: 20px;
`;

export const StyledInput = styled.input`
  appearance: none;
  box-sizing: border-box;
  box-shadow: none;
  height: 44px;
  line-height: 16px;
  margin: 0;
  transition: border-color 0.15s linear, background 0.15s linear;
  width: 100%;

  ${css({
    bg: 'buttercream',
    border: 'normal',
    borderColor: 'border',
    borderRadius: 'medium',
    color: 'text',
    fontFamily: 'default',
    fontSize: 'medium',
    fontWeight: 'medium',
    py: 'small',
    px: 'medium',
    '&.disabled': {
      backgroundColor: 'coldBrews.50',
    },
  })}

  &&:focus {
    outline: none;
  }

  ${({ error }) =>
    css({
      borderColor: error ? 'brisket' : 'coldBrew200',
      color: error ? 'brisket' : 'coldBrew800',
      pr: error ? 'xxxlarge' : 'medium',
    })};
`;

export const StyledInputContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  min-width: 0;
  position: relative;
  width: 100%;
  ${variant({
    variants: fieldSizeVariants,
  })}
`;
