const rxInteractionBlue = '#3463F5';
const brisket = '#C12033';
const tamale = '#FCD131';
const cheesePuff = '#EF8D29';
const hatch = '#008800';
const rxBrandBlue = '#003396';
const rxInteraction = '#F8BF95';
const zoodle = '#F8BF95';
const dragonfruit = '#EE4D9B';
const hebRed = '#DC291E';
const curbsideBlue = '#F8BF95';
const peppercorn = '#000';
const buttercream = '#FFF';
const coldbrew = '#414142';
const carrotCake = '#F8BF95';
const texasBrownie = '#F8BF95';
const cherryPistachio = '#F8BF95';
const vaccine = '#634BAD';
const alertWarningBg = '#FFFDF5';
const ink = '#3E4041';

const colors = {
  briskets: {
    500: brisket,
    50: '#FFF2F2',
  },
  coldBrews: {
    900: '#333',
    800: coldbrew,
    600: '#717172',
    300: '#E0E0E0',
    200: '#D7D7D8',
    100: '#EEECEC',
    50: '#F5F5F5',
  },
  rxColors: {
    rxInteraction800: '#001B70',
    rxInteraction50: '#F2F5FF',
    rxInteractionBlue,
    rxLightBlue: '#F1F4FE',
    rxLightGray: '#F5F5F5',
    rxMediumGray: '#A6A6A6',
    rxPaleGreen: '#E3F5E3',
    rxPink: '#FBEDEC',
  },
  rxInteractionBlue,
  brisket,
  tamale,
  cheesePuff,
  hatch,
  rxBrandBlue,
  rxInteraction,
  zoodle,
  dragonfruit,
  hebRed,
  curbsideBlue,
  peppercorn,
  buttercream,
  coldbrew,
  carrotCake,
  texasBrownie,
  cherryPistachio,
  vaccine,
  alertWarningBg,
  ink,
};

// aliases
colors.text = coldbrew;
colors.highlight = rxInteractionBlue;
colors.card = colors.rxColors.rxLightBlue;
colors.border = colors.coldBrews['200'];
colors.white = buttercream;
colors.black = peppercorn;

export default colors;
