import { ApolloClient, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import fetch from 'unfetch';
import { createHttpLink } from './links/httpLink';

export function createClient({
  initialState,
  uri,
  name,
  ssrMode,
  version,
  headers,
  cacheConfig,
  links = [],
}) {
  const cache = new InMemoryCache(cacheConfig);

  const httpLink = createHttpLink({
    credentials: 'include',
    fetch,
    headers,
    uri,
  });

  return new ApolloClient({
    cache: initialState ? cache.restore(initialState) : cache,
    link: from([...links, httpLink]),
    name,
    ssrMode,
    version,
  });
}
