import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { FieldGroup } from '../FieldGroup';
import Button from '../Button';
import { StyledSearch, StyledSearchContainer } from './styles';
import { Box } from '../Box';
import { useClientStore } from '../../store/store';
import { L10n } from '../L10n';
import { handleEnterKeyPress } from '../../utils/common';

const FilterSearch = ({ expanded = false, setExpanded }) => {
  const intl = useIntl();
  const { searchStr, updateUiState } = useClientStore((state) => ({
    searchStr: state.uiState.searchStr,
    updateUiState: state.updateUiState,
  }));
  const [search, setSearch] = useState({
    value: searchStr,
    error: '',
  });
  const className = expanded ? 'expanded' : '';

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearch({ value, error: '' });
  };

  const updateSearchStr = () => {
    if (!search.value || search.value === '') {
      setSearch({
        value: '',
        error: intl.formatMessage(
          {
            id: 'general.field_error.data_error',
          },
          { data: intl.formatMessage({ id: 'general.location_lowercase' }) }
        ),
      });
    } else {
      updateUiState('searchStr', search.value);
      setExpanded(false);
    }
  };

  useEffect(() => {
    if (!expanded) {
      setSearch({ value: searchStr, error: '' });
    }
  }, [expanded, search.value, searchStr]);

  return (
    <StyledSearchContainer data-testid="filter-search" checked={expanded} className={className}>
      <TransitionGroup>
        <CSSTransition in={expanded} timeout={{ enter: 500, exit: 1 }} classNames="search">
          <StyledSearch className={className} variant={['mobile', 'desktop']}>
            <Box width="100%">
              <FieldGroup
                name="searchStr"
                value={search.value}
                onChange={handleInputChange}
                ariaLabel="Search"
                type="search"
                required
                error={search.error}
                onKeyDown={(e) => handleEnterKeyPress(updateSearchStr, e)}
              />
            </Box>
            <Box>
              <Button handleClick={updateSearchStr} size={['hug', 'tertiary']}>
                <L10n tokenID="general.update" />
              </Button>
            </Box>
          </StyledSearch>
        </CSSTransition>
      </TransitionGroup>
    </StyledSearchContainer>
  );
};

FilterSearch.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
};

export default FilterSearch;
