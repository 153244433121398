const topLine = {
  borderTop: '1px solid rgba(0, 27, 112, 0.20)',
};

const bottomLine = {
  borderBottom: '1px solid rgba(0, 27, 112, 0.20)',
};

const border = {
  ...topLine,
  ...bottomLine,
};

const noBorder = {
  border: 'none',
};

const desktopLayout = {
  px: 'medium',
  gap: 'large',
  justifyContent: 'left',
};

export const ribbonVariants = {
  border,
  noBorder,
};

export const layoutVariants = {
  desktopLayout,
};
