import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import PageNavigation from '../../components/PageNavigation';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import { Box } from '../../components/Box';
import Card from '../../components/Card';
import { RadioGroup } from '../../components/Radio';
import { L10n } from '../../components/L10n';
import { validators } from './validation';
import { useClientStore } from '../../store/store';
import Typography from '../../components/Typography';
import RedirectHandler from '../../components/RedirectHandler';

const InsuranceSelection = () => {
  const {
    uiState,
    updateUiState,
    products,
    insurance,
    updateInsurance,
    updatePatientInfo,
  } = useClientStore((state) => ({
    uiState: state.uiState,
    updateUiState: state.updateUiState,
    products: state.apptDetails.products,
    insurance: state.insurance,
    updateInsurance: state.updateInsurance,
    updatePatientInfo: state.updatePatientInfo,
  }));

  const showMedicarePartB = Object.keys(products).some((x) =>
    ['flu', 'COVID-19', 'pneumonia', 'senior flu'].includes(x)
  );

  const intl = useIntl();
  const [insuranceQuestion, setInsuranceQuestion] = useState(uiState.hasInsurance);
  const insuranceQuestionOptions = {
    uniqueId: 'radio1',
    choices: [
      {
        label: <L10n tokenID="page.insuranceSelection.yes" />,
        value: 'yes',
      },
      {
        label: <L10n tokenID="page.insuranceSelection.no" />,
        value: 'no',
      },
    ],
  };
  const checkboxOptions = [
    {
      label: intl.formatMessage({ id: `page.insuranceSelection.pharmacyMedical` }),
      value: 'showPharmacyMedical',
      storeLabel: 'medicalPlan',
      checked: uiState.showPharmacyMedical,
    },
    {
      label: intl.formatMessage({ id: `page.insuranceSelection.medicarePartB` }),
      value: 'showMedicarePartB',
      storeLabel: 'medicarePartB',
      checked: uiState.showMedicarePartB,
      showCheckbox: showMedicarePartB,
    },
    {
      label: intl.formatMessage({ id: `page.insuranceSelection.medicarePartC` }),
      value: 'showMedicarePartC',
      storeLabel: 'medicarePartC',
      checked: uiState.showMedicarePartC,
    },
    {
      label: intl.formatMessage({ id: `page.insuranceSelection.medicarePartD` }),
      value: 'showMedicarePartD',
      storeLabel: 'medicarePartD',
      checked: uiState.showMedicarePartD,
    },
  ];
  const [checkboxGroupData, setCheckboxGroupData] = useState(checkboxOptions);

  const resetCheckBoxGroupData = () => {
    const updatedData = [...checkboxGroupData];
    updatedData.forEach((e) => {
      e.checked = false;
    });
    setCheckboxGroupData(updatedData);
    updateUiState('showMedicareStatement', false);
  };

  const [inputState, setInputState] = useState({
    pharmacyInsuranceQuestion: {
      value: uiState.hasInsurance,
      hasError: false,
      error: '',
      node: null,
    },
    insuranceTypesQuestion: {
      value: checkboxGroupData,
      hasError: false,
      error: '',
      node: null,
    },
  });

  const validateInput = (input, validator = null, value = null) => {
    let hasError = false;
    let error = '';
    if (!validator && input?.value === '' && input?.required) {
      hasError = true;
      error = intl.formatMessage({ id: `general.field_error.response_required_error` });
    } else if (validator) {
      ({ hasError, error } = validator(
        value,
        intl.formatMessage({ id: `general.field_error.response_required_error` })
      ));
    }
    return {
      hasError,
      error,
    };
  };

  const handleInsuranceQuestionChange = (event) => {
    let error = '';
    let hasError = false;
    const { value } = event.target.value ? event.target : event.target.dataset;
    if (inputState.pharmacyInsuranceQuestion.hasError) {
      ({ error, hasError } = validateInput(null, validators.pharmacyInsuranceQuestion, value));
    }
    setInsuranceQuestion(value);
    setInputState({
      ...inputState,
      pharmacyInsuranceQuestion: {
        ...inputState.pharmacyInsuranceQuestion,
        value,
        hasError,
        error,
      },
    });
    if (value === 'no') {
      resetCheckBoxGroupData();
    }
  };

  const handleInsuranceTypesQuestionChange = (index, value) => {
    const updatedData = [...checkboxGroupData];
    updatedData[index].checked = value;

    setCheckboxGroupData(updatedData);

    let error = '';
    let hasError = false;
    if (inputState.insuranceTypesQuestion.hasError) {
      ({ error, hasError } = validateInput(
        null,
        validators.insuranceTypesQuestion,
        inputState.insuranceTypesQuestion.value
      ));
    }
    setInputState({
      ...inputState,
      insuranceTypesQuestion: {
        ...inputState.insuranceTypesQuestion,
        hasError,
        error,
      },
    });
  };

  const handleNext = () => {
    // reset uistates for MedicareStatement and medicare part B (doesn't always reset in the other place because sometimes it isnt shown)
    updateUiState('showMedicareStatement', false);
    updateUiState('showMedicarePartB', false);
    let firstError;
    let currentState = { ...inputState };
    let resetMedicareStatement = true;
    document.querySelectorAll('[name$="Question"]').forEach((node) => {
      const fieldName = node.getAttribute('name');
      const validator = validators[fieldName];
      const { value } = inputState[fieldName];
      const { error, hasError } = validateInput(node, validator, value);
      if (hasError) {
        if (!firstError) firstError = node;
        if (!inputState[fieldName].hasError) {
          currentState = {
            ...currentState,
            [fieldName]: {
              ...inputState[fieldName],
              hasError,
              error,
              node,
            },
          };
        }
      }
    });
    setInputState({ ...currentState });
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      checkboxGroupData.forEach((e) => {
        if (!e.checked) {
          const store = e.storeLabel;
          // resets store for insurances that arent checked
          Object.keys(insurance[store]).forEach((field) => {
            updateInsurance(field, '', store);
          });
        }
        // resets state for MedicarePartC, MedicarePartD, and Pharmacy/Medical
        updateUiState(e.value, e.checked);
        if ((e.value === 'showMedicarePartB' || e.value === 'showMedicarePartC') && e.checked) {
          updateUiState('showMedicareStatement', true);
          resetMedicareStatement = false;
        }
      });
      updateUiState('validPage', true);
      updateUiState('hasInsurance', insuranceQuestion);
      if (resetMedicareStatement) {
        updatePatientInfo('Name_of_Beneficiary__c', '');
        updatePatientInfo('Medicare_Signature__c', '');
      }
    }
  };

  return (
    <>
      <RedirectHandler pageNumber={8} />
      <Box
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
        data-testid="insuranceSelection"
      >
        <PageTitleSubtitle
          title={<L10n tokenID="page.insuranceSelection.header" />}
          subtitle={<L10n tokenID="page.insuranceSelection.subheader" />}
        />
        <RadioGroup
          label={<L10n tokenID="page.insuranceSelection.questionOne" />}
          options={insuranceQuestionOptions}
          onChange={handleInsuranceQuestionChange}
          selectedOption={insuranceQuestion}
          name="pharmacyInsuranceQuestion"
          error={inputState.pharmacyInsuranceQuestion.error}
          required
        />
        {insuranceQuestion === 'yes' && (
          <>
            <CheckboxGroup
              label={<L10n tokenID="page.insuranceSelection.questionTwo" />}
              name="insuranceTypesQuestion"
              onChange={handleInsuranceTypesQuestionChange}
              options={Object.values(checkboxGroupData)}
              error={inputState.insuranceTypesQuestion.error}
              required
            />
            <Card variant="callOut" margin="none">
              <Typography variant="primaryMedium">
                <L10n tokenID="page.insuranceSelection.callOutCard" />
              </Typography>
            </Card>
          </>
        )}
        <PageNavigation hideNext={false} handleNext={handleNext} />
      </Box>
    </>
  );
};

export default InsuranceSelection;
