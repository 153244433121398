import styled, { css } from 'styled-components';
import { compose, layout, width, flexbox } from 'styled-system';
import { Box } from '../Box';

export const StyledList = styled.ul`
  display: contents;
  align-items: center;
  width: 100%;
  ${css({
    '& li:last-child button p': {
      pl: 'small',
    },
  })}
  ${compose(layout, flexbox, width)}
`;

export const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  ${compose(layout, flexbox, width)}
`;

export const StyledNav = styled.nav`
  display: flex;
  ${compose(flexbox)}
`;

export const StyledPaginationContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, min-content));
`;
