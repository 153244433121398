import React, { useState } from 'react';
import { format } from 'date-fns';
import { useAppointmentQuery } from '../../api/Appointment';
import { useClientStore } from '../../store/store';

import AuthenticateUser from './AuthenticateUser';
import YAMS from './YAMS';

const AppointmentManagement = () => {
  const { birthdate, storeApptId, updateApptDetails, isAuthFromBooking } = useClientStore(
    (state) => ({
      birthdate: state.patient.Birthdate__c,
      storeApptId: state.apptDetails.confirmationNum,
      updateApptDetails: state.updateApptDetails,
      isAuthFromBooking: state.uiState.isPrevAuth,
    })
  );
  const [isPrevAuth, setIsPrevAuth] = useState(isAuthFromBooking);
  const [formattedData, setFormattedData] = useState({
    formattedDate: birthdate,
    confirmationNum: storeApptId,
  });

  const handleSetFormattedData = (id, dob) => {
    if (dob) {
      const formattedBDay = format(new Date(dob.replace(/-/g, '/')), 'yyyy-MM-dd');
      setFormattedData({ ...formattedData, formattedDate: formattedBDay, confirmationNum: id });
    } else {
      setFormattedData({ ...formattedData, confirmationNum: id });
    }
    updateApptDetails('confirmationNum', id);
  };

  const { data, isError, isSuccess, isFetching } = useAppointmentQuery(
    formattedData.formattedDate,
    formattedData.confirmationNum
  );

  return isSuccess || isPrevAuth ? (
    <YAMS
      apptData={data?.data.records[0]}
      isFetching={isFetching}
      handleSetFormattedData={handleSetFormattedData}
      setIsPrevAuth={setIsPrevAuth}
    />
  ) : (
    <AuthenticateUser isError={isError} handleSetFormattedData={handleSetFormattedData} />
  );
};

export default AppointmentManagement;
