import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { StyledDD, HangingIndent, StyledDT } from './styles';

import { Box, FlexBox } from '../../../Box';
import Button from '../../../Button';
import { Content, Footer, Header } from '../../../ModalComposition';
import { FieldGroup } from '../../../FieldGroup';
import { L10n } from '../../../L10n';
import Modal from '../../../Modal';
import { RadioGroup } from '../../../Radio';
import Typography from '../../../Typography';

import validationFunctions from '../../../../utils/validation';

const RelationshipToPatientModal = ({
  handleConfirm,
  handleClose,
  relationshipToPatientInitial,
  initialsInitial,
}) => {
  const intl = useIntl();
  const validators = {
    validateInput: validationFunctions.validateInput,
    Relationship_to_Patient_Picklist__c: validationFunctions.validateRadioInput,
    Consent_Initial__c: validationFunctions.validateName,
  };

  const errors = {
    Relationship_to_Patient_Picklist__c: intl.formatMessage({
      id: 'general.field_error.response_required_error',
    }),
    Consent_Initial__c: intl.formatMessage({
      id: 'page.consent_ack.initials_required',
    }),
  };

  const L10nPref = (suffix, values) => (
    <L10n tokenID={`page.consent_ack.${suffix}`} key={suffix} values={{ ...values }} />
  );

  const [inputState, setInputState] = useState({
    Relationship_to_Patient_Picklist__c: {
      value: relationshipToPatientInitial,
      hasError: false,
      error: '',
      node: null,
    },
    Consent_Initial__c: {
      value: initialsInitial,
      hasError: false,
      error: '',
      node: null,
    },
  });

  const validateInput = (field, label, target) => {
    return validators.validateInput(
      target,
      label,
      validators[field],
      inputState[field]?.value,
      errors[field]
    );
  };

  const mapRelationshipToPatientToConsentInitialOption = (relationshipToPatient) => {
    switch (relationshipToPatient) {
      case 'Self, parent, or legal guardian':
        return 'Option 1';
      case 'Authorized Representative':
        return 'Option 2';
      case 'Authorized Individual':
        return 'Option 3';
      default:
        return '';
    }
  };

  const handleConfirmClick = () => {
    let firstError = null;
    const currState = { ...inputState };
    document.querySelectorAll('[name$="__c"]').forEach((node) => {
      if (node.type === 'text' || !node.type) {
        const field = node.id ? node.id : node.getAttribute('name');
        const label = node.labels
          ? node.labels[0].firstChild.firstChild?.textContent
          : node.firstChild.firstChild.firstChild.textContent;
        const { hasError, error } = validateInput(field, label, node);
        if (hasError) {
          firstError = !firstError ? node : firstError;
          currState[field] = {
            ...inputState[field],
            hasError,
            error,
            node,
          };
        }
      }
    });
    setInputState({
      ...inputState,
      ...currState,
    });
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      const returnValues = {
        Relationship_to_Patient_Picklist__c: inputState.Relationship_to_Patient_Picklist__c.value,
        Consent_Initial__c: inputState.Consent_Initial__c.value,
        Consent_Initial_Option__c: mapRelationshipToPatientToConsentInitialOption(
          inputState.Relationship_to_Patient_Picklist__c.value
        ),
      };
      handleConfirm(returnValues);
    }
  };

  const handleInputChange = (event) => {
    setInputState({
      ...inputState,
      [event.target.id]: {
        value: event.target.value,
      },
    });
  };

  const handleBlur = (event) => {
    const { target } = event;
    const field = target.id;
    const label = target.labels[0].firstChild.firstChild.textContent;
    const { hasError, error } = validateInput(field, label, event.target);
    setInputState({
      ...inputState,
      [field]: {
        ...inputState[field],
        hasError,
        error,
        node: target,
      },
    });
  };

  const inputs = [
    {
      label: L10nPref('your_initials'),
      name: 'Consent_Initial__c',
      error: inputState.Consent_Initial__c.error,
      variant: ['base', 'medium'],
      required: true,
      mask: 'aaa',
    },
  ];

  const relationshipToPatientOptions = {
    uniqueId: 'Relationship_to_Patient_Picklist__c',
    choices: [
      {
        label: L10nPref('self_parent_guardian'),
        value: 'Self, parent, or legal guardian',
      },
      {
        label: L10nPref('auth_rep'),
        value: 'Authorized Representative',
      },
      {
        label: L10nPref('auth_ind'),
        value: 'Authorized Individual',
      },
    ],
  };

  const languageTokenIDs = [
    'rel_to_patient_text_self',
    'rel_to_patient_text_parent_guardian',
    'rel_to_patient_text_auth_rep',
    'rel_to_patient_text_auth_ind',
  ];

  return (
    <Modal
      onClose={handleClose}
      isMobileFullScreen
      header={
        <Header withClose handleClose={handleClose}>
          {L10nPref('rel_to_patient')}
        </Header>
      }
      content={
        <Content>
          <FlexBox flexDirection="column" gridGap="xLarge">
            <Box
              border="normal"
              borderColor="coldBrews.200"
              borderRadius="large"
              height={['30vh', '300px']}
              maxHeight="300px"
              overflowY="auto"
              px="large"
              pb="large"
            >
              <Typography variant="primaryBase" tag="span">
                <dl>
                  {languageTokenIDs.map((tokenID) =>
                    L10nPref(tokenID, {
                      Typography: (str) => (
                        <Typography tag="span" variant="secondaryBase">
                          {str}
                        </Typography>
                      ),
                      HangingIndent: (str) => <HangingIndent>{str}</HangingIndent>,
                      HangingIndentPY: (str) => <HangingIndent py="large">{str}</HangingIndent>,
                      HangingIndentPB: (str) => <HangingIndent pb="large">{str}</HangingIndent>,
                      StyledDD: (str) => <StyledDD>{str}</StyledDD>,
                      StyledDT: (str) => <StyledDT>{str}</StyledDT>,
                    })
                  )}
                </dl>
              </Typography>
            </Box>
            <RadioGroup
              label={L10nPref('rel_to_patient')}
              options={relationshipToPatientOptions}
              onChange={(e) =>
                setInputState({
                  ...inputState,
                  Relationship_to_Patient_Picklist__c: {
                    value: e.target.value,
                  },
                })
              }
              selectedOption={inputState.Relationship_to_Patient_Picklist__c.value}
              name="Relationship_to_Patient_Picklist__c"
              required
              error={inputState.Relationship_to_Patient_Picklist__c.error}
            />
            {inputs.map((input) => {
              const { label, name, required, error, variant, mask } = input;
              return (
                <FieldGroup
                  onBlur={handleBlur}
                  onChange={handleInputChange}
                  key={name}
                  label={label}
                  required={required}
                  name={name}
                  error={error}
                  variant={variant}
                  value={inputState[name].value}
                  mask={mask}
                />
              );
            })}
          </FlexBox>
        </Content>
      }
      footer={
        <Footer variant="sideBySide">
          <Button handleClick={handleClose} size={['hug', 'secondary']} styling="outlined">
            {L10nPref('close')}
          </Button>
          <Button handleClick={handleConfirmClick} size={['hug', 'secondary']}>
            {L10nPref('confirm')}
          </Button>
        </Footer>
      }
    />
  );
};

RelationshipToPatientModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  relationshipToPatientInitial: PropTypes.string.isRequired,
  initialsInitial: PropTypes.string.isRequired,
};

export default RelationshipToPatientModal;
