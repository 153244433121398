export const icons = {
  calendar: {
    d: [
      'M-10 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z',
    ],
    viewBox: '0 0 24 24',
    height: '24',
    width: '24',
  },
  check: {
    d: [
      'M5.79506 10.875L2.32506 7.40501C2.13823 7.21776 1.88458 7.11252 1.62006 7.11252C1.35554 7.11252 1.10189 7.21776 0.915059 7.40501C0.525059 7.79501 0.525059 8.42501 0.915059 8.81501L5.09506 12.995C5.48506 13.385 6.11506 13.385 6.50506 12.995L17.0851 2.41501C17.4751 2.02501 17.4751 1.39501 17.0851 1.00501C16.8982 0.817757 16.6446 0.712524 16.3801 0.712524C16.1155 0.712524 15.8619 0.817757 15.6751 1.00501L5.79506 10.875Z',
    ],
    viewBox: '0 0 14 14',
    height: '14',
    width: '14',
  },
  chevron: {
    d: [
      'M14.3492 11.7593C14.7686 12.1187 15.3999 12.0701 15.7593 11.6508C16.1187 11.2315 16.0701 10.6002 15.6508 10.2407L8.65081 4.24074C8.27632 3.91975 7.72372 3.91975 7.34923 4.24074L0.349227 10.2407C-0.0700991 10.6002 -0.118661 11.2315 0.240762 11.6508C0.600184 12.0701 1.23148 12.1187 1.65081 11.7593L8.00002 6.31708L14.3492 11.7593Z',
    ],
    viewBox: '0 0 16 16',
    width: '16',
    height: '16',
  },
  expandLess: {
    d: [
      'M11.29 8.71 6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  expandMore: {
    d: [
      'M15.88 9.29 12 13.17 8.12 9.29a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  chevronLeft: {
    d: [
      'M9.74741 18.3356C10.1143 18.7484 10.0771 19.3805 9.66436 19.7474C9.25158 20.1143 8.61951 20.0772 8.25259 19.6644L0.252591 10.6644C-0.0841969 10.2855 -0.0841969 9.71453 0.252591 9.33565L8.25259 0.335647C8.61951 -0.0771359 9.25158 -0.114317 9.66436 0.252601C10.0771 0.619519 10.1143 1.25159 9.74741 1.66437L2.33795 10L9.74741 18.3356Z',
    ],
    viewBox: '0 0 10 20',
    width: '10',
    height: '20',
  },
  close: {
    d: [
      'M6.58579 8.25L0.292893 1.95711C-0.097631 1.56658 -0.097631 0.933417 0.292893 0.542893C0.683417 0.152369 1.31658 0.152369 1.70711 0.542893L8 6.83579L14.2929 0.542893C14.6834 0.152369 15.3166 0.152369 15.7071 0.542893C16.0976 0.933417 16.0976 1.56658 15.7071 1.95711L9.41421 8.25L15.7071 14.5429C16.0976 14.9334 16.0976 15.5666 15.7071 15.9571C15.3166 16.3476 14.6834 16.3476 14.2929 15.9571L8 9.66421L1.70711 15.9571C1.31658 16.3476 0.683418 16.3476 0.292893 15.9571C-0.0976309 15.5666 -0.0976309 14.9334 0.292893 14.5429L6.58579 8.25Z',
    ],
    viewBox: '0 0 16 16',
    width: '16',
    height: '16',
  },
  clock: {
    d: [
      'M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24ZM14 21.5C9.85786 21.5 6.5 18.1421 6.5 14C6.5 9.85786 9.85786 6.5 14 6.5C18.1421 6.5 21.5 9.85786 21.5 14C21.5 18.1421 18.1421 21.5 14 21.5ZM14.0078 16.2499C14.1983 16.2499 14.3832 16.2262 14.5598 16.1817C15.2959 16.0287 16.4451 15.6164 18.136 14.9256C18.9651 14.5869 18.9651 13.4129 18.136 13.0742C17.2623 12.7172 16.5332 12.4346 15.931 12.2237C15.7181 11.3619 15.3992 10.2072 14.9682 8.72131C14.6892 7.7594 13.3264 7.7594 13.0474 8.72131C12.324 11.2152 11.9163 12.7762 11.7959 13.5853C11.7709 13.7197 11.7578 13.8582 11.7578 13.9999C11.7578 15.2425 12.7652 16.2499 14.0078 16.2499Z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  filter: {
    d: [
      'M13.8341 2.0137C13.8881 2.00469 13.9435 2 14 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H14C13.9435 4 13.8881 3.99531 13.8341 3.9863C13.4262 5.15864 12.3113 6 11 6C9.69378 6 8.58254 5.16519 8.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H8.17071C8.58254 0.834808 9.69378 0 11 0C12.3113 0 13.4262 0.841358 13.8341 2.0137ZM10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3ZM3 11C4.31133 11 5.42615 10.1586 5.8341 8.9863C5.88806 8.99531 5.94348 9 6 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H6C5.94348 7 5.88806 7.00469 5.8341 7.0137C5.42615 5.84136 4.31133 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H7C7.05652 14 7.11194 13.9953 7.1659 13.9863C7.57385 15.1586 8.68867 16 10 16C11.3113 16 12.4262 15.1586 12.8341 13.9863C12.8881 13.9953 12.9435 14 13 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13C12.9435 12 12.8881 12.0047 12.8341 12.0137C12.4262 10.8414 11.3113 10 10 10C8.68867 10 7.57385 10.8414 7.1659 12.0137C7.11194 12.0047 7.05652 12 7 12H1ZM2 8C2 7.44772 2.44772 7 3 7C3.55228 7 4 7.44772 4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12Z',
    ],
    viewBox: '0 0 16 16',
    width: '16',
    height: '16',
  },
  guardian: {
    d: [
      'M6.5 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 7.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5S17.83 8 17 8s-1.5.67-1.5 1.5zm3 2.5h-2.84c-.58.01-1.14.32-1.45.86l-.92 1.32L9.72 8c-.37-.63-1.03-.99-1.71-1H5c-1.1 0-2 .9-2 2v6h1.5v7h5V11.61L12.03 16h2.2l.77-1.1V22h4v-5h1v-3.5c0-.82-.67-1.5-1.5-1.5z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  home: {
    d: [
      'M11.4141 3.18962C11.7638 2.93679 12.2362 2.93679 12.5859 3.18962L21.5859 9.69662C21.846 9.88465 22 10.1861 22 10.507V10.9934C22 11.5447 21.5539 11.992 21.0026 11.9934L20 11.996L19.0988 20.1089C19.0426 20.6153 18.6145 20.9984 18.1049 20.9984H15.0792C15.0355 20.9984 15 20.963 15 20.9192V15.996C15 14.3392 13.6569 12.996 12 12.996V12.996C10.3431 12.996 9 14.3392 9 15.996V20.9169C9 20.9606 8.96457 20.996 8.92084 20.9961L5.89562 20.9979C5.38582 20.9982 4.9574 20.615 4.90112 20.1083L4 11.996L2.99742 11.9934C2.44614 11.992 2 11.5447 2 10.9934L2 10.507C2 10.1861 2.15402 9.88465 2.4141 9.69662L11.4141 3.18962Z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  search: {
    d: [
      'M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM11.1922 12.6064C10.0236 13.4816 8.57234 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.57234 13.4816 10.0236 12.6064 11.1922L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L11.1922 12.6064Z',
    ],
    viewBox: '0 0 16 16',
    width: '16',
    height: '16',
  },
  info: {
    d: [
      'M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 13.2C11.34 13.2 10.8 12.66 10.8 12V7.2C10.8 6.54 11.34 6 12 6C12.66 6 13.2 6.54 13.2 7.2V12C13.2 12.66 12.66 13.2 12 13.2ZM13.2 18H10.8V15.6H13.2V18Z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  idCard: {
    d: [
      'M2.4 3H21.6C22.932 3 24 3.95675 24 5.15V18.05C24 19.2433 22.932 20.2 21.6 20.2H2.4C1.068 20.2 0 19.2433 0 18.05L0.012 5.15C0.012 3.95675 1.068 3 2.4 3ZM3.597 18.05H20.397C21.057 18.05 21.597 17.5663 21.597 16.975C21.597 16.975 21.594 6.7625 21.597 6.225C21.6 5.6875 21 5.15 20.397 5.15H3.6C3 5.15 2.4 5.6875 2.4 6.225L2.397 16.975C2.397 17.5663 2.937 18.05 3.597 18.05Z',
      'M7.5 11.5C8.46687 11.5 9.25 10.7169 9.25 9.75C9.25 8.78313 8.46687 8 7.5 8C6.53313 8 5.75 8.78313 5.75 9.75C5.75 10.7169 6.53313 11.5 7.5 11.5ZM7.5 8.875C7.98125 8.875 8.375 9.26875 8.375 9.75C8.375 10.2313 7.98125 10.625 7.5 10.625C7.01875 10.625 6.625 10.2313 6.625 9.75C6.625 9.26875 7.01875 8.875 7.5 8.875ZM7.5 11.9375C6.33187 11.9375 4 12.5237 4 13.6875V14.5625C4 14.8031 4.19687 15 4.4375 15H10.5625C10.8031 15 11 14.8031 11 14.5625V13.6875C11 12.5237 8.66813 11.9375 7.5 11.9375ZM10.125 14.125H4.875V13.6919C4.9625 13.3769 6.31875 12.8125 7.5 12.8125C8.68125 12.8125 10.0375 13.3769 10.125 13.6875V14.125Z',
      'M10.125 14.125H4.875V13.6919C4.9625 13.3769 6.31875 12.8125 7.5 12.8125C8.68125 12.8125 10.0375 13.3769 10.125 13.6875V14.125Z',
      'M7.5 8.875C7.98125 8.875 8.375 9.26875 8.375 9.75C8.375 10.2313 7.98125 10.625 7.5 10.625C7.01875 10.625 6.625 10.2313 6.625 9.75C6.625 9.26875 7.01875 8.875 7.5 8.875Z',
      'M19.5556 10.0833H12.4444C12.2 10.0833 12 10.2708 12 10.5C12 10.7292 12.2 10.9167 12.4444 10.9167H19.5556C19.8 10.9167 20 10.7292 20 10.5C20 10.2708 19.8 10.0833 19.5556 10.0833ZM12.4444 13H16.8889C17.1333 13 17.3333 12.8125 17.3333 12.5833C17.3333 12.3542 17.1333 12.1667 16.8889 12.1667H12.4444C12.2 12.1667 12 12.3542 12 12.5833C12 12.8125 12.2 13 12.4444 13ZM19.5556 8H12.4444C12.2 8 12 8.1875 12 8.41667V8.42083C12 8.65 12.2 8.8375 12.4444 8.8375H19.5556C19.8 8.8375 20 8.65 20 8.42083V8.41667C20 8.1875 19.8 8 19.5556 8Z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  locationPin: {
    d: [
      'M20 9.77778C20 14.3831 14.5027 19.7519 12.6221 21.4538C12.2642 21.7777 11.7358 21.7777 11.3779 21.4538C9.49735 19.7519 4 14.3831 4 9.77778C4 5.33333 7.58172 2 12 2C16.4183 2 20 5.33333 20 9.77778ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  printer: {
    d: [
      'M12 12V10C12 9.44771 11.5523 9 11 9H5C4.44772 9 4 9.44771 4 10V12V13C4 13.5523 4.44772 14 5 14H11C11.5523 14 12 13.5523 12 13V12ZM2 12V13C2 14.6569 3.34315 16 5 16H11C12.6569 16 14 14.6569 14 13V12C15.1046 12 16 11.1046 16 10V6C16 4.89543 15.1046 4 14 4V3C14 1.34315 12.6569 0 11 0H5C3.34315 0 2 1.34315 2 3V4C0.895431 4 0 4.89543 0 6V10C0 11.1046 0.895431 12 2 12ZM12 4V3C12 2.44772 11.5523 2 11 2H5C4.44772 2 4 2.44771 4 3V4H12Z',
    ],
    viewBox: '0 0 16 16',
    width: '16',
    height: '16',
  },
  alert: {
    d: [
      'M11.1057 3.49751C11.4742 2.83416 12.5258 2.83416 12.8943 3.49751L21.8932 19.6975C22.2256 20.2959 21.7421 21 20.9988 21H3.00116C2.25787 21 1.77444 20.2959 2.10685 19.6975L11.1057 3.49751ZM11 9.85714C11 9.38376 11.4477 9 12 9C12.5523 9 13 9.38376 13 9.85714V14.1429C13 14.6162 12.5523 15 12 15C11.4477 15 11 14.6162 11 14.1429V9.85714ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  checkboxActive: {
    d: [
      'M2 0.371582H16C17.1 0.371582 18 1.27158 18 2.37158V16.3716C18 17.4716 17.1 18.3716 16 18.3716H2C0.9 18.3716 0 17.4716 0 16.3716V2.37158C0 1.27158 0.9 0.371582 2 0.371582ZM6.2467 14.6641C6.67329 15.0541 7.36239 15.0541 7.78898 14.6641L16.0801 7.07408C16.5066 6.68408 16.5066 6.05408 16.0801 5.66408C15.6535 5.27408 14.9644 5.27408 14.5378 5.66408L7.01237 12.5441L3.8622 9.66408C3.43561 9.27408 2.74651 9.27408 2.31992 9.66408C2.1151 9.85091 2 10.1046 2 10.3691C2 10.6336 2.1151 10.8872 2.31992 11.0741L6.2467 14.6641Z',
    ],
    viewBox: '0 0 18 18',
    width: '18',
    height: '18',
  },
  shirt: {
    d: [
      'M9.97299 1.33499C12.4192 2.27816 13.7723 2.32756 16.183 1.33499C16.222 1.22723 16.2568 1.11556 16.287 1C13.8764 1.99257 12.3133 1.94317 9.86709 1C9.89783 1.11556 9.93324 1.22723 9.97299 1.33499Z',
      'M6.51714 10.8696V23.7852C6.51714 23.8022 6.53095 23.816 6.54798 23.816H19.5479C19.5649 23.816 19.5787 23.8022 19.5787 23.7852V10.8696L21.1833 12.4895C21.1964 12.5028 21.2182 12.5014 21.2296 12.4866L24.9936 7.60111C25.003 7.58888 25.0019 7.57156 24.9911 7.56059L19.6823 2.20124C19.6142 2.13246 19.5308 2.08069 19.439 2.05012L16.2982 1.00457L16.287 1C16.2568 1.11556 16.222 1.22723 16.183 1.33499C13.9684 3.37996 12.2677 3.38325 9.97299 1.33499C9.93324 1.22723 9.89783 1.11556 9.86709 1L9.85569 1.00457L6.65599 2.05121C6.56469 2.08107 6.48163 2.13185 6.41343 2.19951L1.00912 7.56043C0.998013 7.57144 0.996917 7.58903 1.00657 7.60134L4.83751 12.4873C4.8489 12.5018 4.8704 12.5032 4.88349 12.4902L6.51714 10.8696ZM7.58512 8.49786C7.58544 8.498 7.58564 8.49831 7.58564 8.49865L7.62238 22.6298C7.62253 22.6849 7.66726 22.7295 7.72238 22.7295H18.4288C18.484 22.7295 18.5288 22.6848 18.5288 22.6295V8.49867C18.5288 8.49833 18.529 8.49801 18.5293 8.49788V8.49788C18.5296 8.49774 18.53 8.4978 18.5302 8.49804L21.0775 10.8696L23.6464 7.62294C23.6659 7.59834 23.6637 7.56305 23.6415 7.54093L19.1346 3.0622C19.0641 2.99221 18.9788 2.9405 18.884 2.91082C18.3696 2.74971 16.6879 2.10215 16.3556 1.9738C16.32 1.96005 16.2799 1.96861 16.2517 1.99433C14.0715 3.98306 12.1714 3.98558 9.91304 1.99241C9.8851 1.96775 9.84579 1.95967 9.81092 1.97282C9.47614 2.09909 7.74079 2.75183 7.22042 2.91196C7.12627 2.94093 7.04148 2.99157 6.971 3.06038L2.38276 7.5406C2.36002 7.5628 2.35788 7.59865 2.37781 7.6234L4.99184 10.8696L7.58419 8.49801C7.58444 8.49778 7.58481 8.49772 7.58512 8.49786V8.49786Z',
      'M16.183 1.33499C13.7723 2.32756 12.4192 2.27816 9.97299 1.33499M16.183 1.33499C16.222 1.22723 16.2568 1.11556 16.287 1M16.183 1.33499C13.9684 3.37996 12.2677 3.38325 9.97299 1.33499M9.97299 1.33499C9.93324 1.22723 9.89783 1.11556 9.86709 1M9.86709 1L9.85569 1.00457L6.65599 2.05121C6.56469 2.08107 6.48163 2.13185 6.41343 2.19951L1.00912 7.56043C0.998013 7.57144 0.996917 7.58903 1.00657 7.60134L4.83751 12.4873C4.8489 12.5018 4.8704 12.5032 4.88349 12.4902L6.51714 10.8696V23.7852C6.51714 23.8022 6.53095 23.816 6.54798 23.816H19.5479C19.5649 23.816 19.5787 23.8022 19.5787 23.7852V10.8696L21.1833 12.4895C21.1964 12.5028 21.2182 12.5014 21.2296 12.4866L24.9936 7.60111C25.003 7.58888 25.0019 7.57156 24.9911 7.56059L19.6823 2.20124C19.6142 2.13246 19.5308 2.08069 19.439 2.05012L16.2982 1.00457L16.287 1M9.86709 1C12.3133 1.94317 13.8764 1.99257 16.287 1M7.22042 2.91196C7.74079 2.75183 9.47614 2.09909 9.81092 1.97282C9.84579 1.95967 9.8851 1.96775 9.91304 1.99241C12.1714 3.98558 14.0715 3.98306 16.2517 1.99433C16.2799 1.96861 16.32 1.96005 16.3556 1.9738C16.6879 2.10215 18.3696 2.74971 18.884 2.91082C18.9788 2.9405 19.0641 2.99221 19.1346 3.0622L23.6415 7.54093C23.6637 7.56305 23.6659 7.59834 23.6464 7.62294L21.0775 10.8696L18.5302 8.49804C18.53 8.4978 18.5296 8.49774 18.5293 8.49788V8.49788C18.529 8.49801 18.5288 8.49833 18.5288 8.49867V22.6295C18.5288 22.6848 18.484 22.7295 18.4288 22.7295H7.72238C7.66726 22.7295 7.62253 22.6849 7.62238 22.6298L7.58564 8.49865C7.58564 8.49831 7.58544 8.498 7.58512 8.49786V8.49786C7.58481 8.49772 7.58444 8.49778 7.58419 8.49801L4.99184 10.8696L2.37781 7.6234C2.35788 7.59865 2.36002 7.5628 2.38276 7.5406L6.971 3.06038C7.04148 2.99157 7.12627 2.94093 7.22042 2.91196Z',
    ],
    viewBox: '0 0 26 25',
    width: '26',
    height: '25',
  },
  timer: {
    d: [
      'M10.4375 20C15.9603 20 20.4375 15.5228 20.4375 10C20.4375 4.47715 15.9603 0 10.4375 0C4.91465 0 0.4375 4.47715 0.4375 10C0.4375 15.5228 4.91465 20 10.4375 20ZM10.4375 17.5C6.29536 17.5 2.9375 14.1421 2.9375 10C2.9375 5.85786 6.29536 2.5 10.4375 2.5C14.5796 2.5 17.9375 5.85786 17.9375 10C17.9375 14.1421 14.5796 17.5 10.4375 17.5ZM10.4385 12.25C10.6289 12.25 10.8138 12.2263 10.9904 12.1818C11.7266 12.0288 12.8758 11.6165 14.5667 10.9257C15.3957 10.587 15.3957 9.41297 14.5667 9.07426C13.6929 8.7173 12.9639 8.43472 12.3617 8.22384C12.1488 7.36202 11.8299 6.20731 11.3989 4.72142C11.1199 3.75951 9.75706 3.75951 9.47806 4.72142C8.7547 7.21535 8.347 8.77633 8.22659 9.5854C8.20156 9.71978 8.18848 9.85835 8.18848 9.99998C8.18848 11.165 9.07385 12.1231 10.2084 12.2384C10.2841 12.246 10.3608 12.25 10.4385 12.25Z',
    ],
    viewBox: '0 0 21 20',
    width: '21',
    height: '20',
  },
  camera: {
    d: [
      'M16 19C14.8954 19 14 19.8954 14 21V30C14 31.1046 14.8954 32 16 32H32C33.1046 32 34 31.1046 34 30V21C34 19.8954 33.1046 19 32 19H30.8284C30.298 19 29.7893 18.7893 29.4142 18.4142L27.5858 16.5858C27.2107 16.2107 26.702 16 26.1716 16H21.8284C21.298 16 20.7893 16.2107 20.4142 16.5858L18.5858 18.4142C18.2107 18.7893 17.702 19 17.1716 19H16ZM24 30C21.2386 30 19 27.7614 19 25C19 22.2386 21.2386 20 24 20C26.7614 20 29 22.2386 29 25C29 27.7614 26.7614 30 24 30ZM24 28C25.6569 28 27 26.6569 27 25C27 23.3431 25.6569 22 24 22C22.3431 22 21 23.3431 21 25C21 26.6569 22.3431 28 24 28Z',
    ],
    viewBox: '0 0 48 48',
    width: '48',
    height: '48',
  },
  checkCircle: {
    d: [
      'M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.2929 7.29289L9 14.5858L7.70711 13.2929C7.31658 12.9024 6.68342 12.9024 6.29289 13.2929C5.90237 13.6834 5.90237 14.3166 6.29289 14.7071L8.29289 16.7071C8.68342 17.0976 9.31658 17.0976 9.70711 16.7071L17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289C17.3166 6.90237 16.6834 6.90237 16.2929 7.29289Z',
    ],
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
  },
  trashCan: {
    d: [
      'M1 2.5C1 2.77614 1.22386 3 1.5 3H14.5C14.7761 3 15 2.77614 15 2.5V2C15 1.44772 14.5523 1 14 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2C1.44772 1 1 1.44772 1 2V2.5ZM2.59028 4C2.28131 4 2.04628 4.27743 2.09708 4.5822L3.86078 15.1644C3.94114 15.6466 4.35833 16 4.84717 16H11.1529C11.6418 16 12.0589 15.6466 12.1393 15.1644L13.903 4.5822C13.9538 4.27743 13.7188 4 13.4098 4H2.59028ZM6.00005 6C5.44776 6 5.00005 6.44772 5.00005 7V13C5.00005 13.5523 5.44776 14 6.00005 14C6.55233 14 7.00005 13.5523 7.00005 13V7C7.00005 6.44772 6.55233 6 6.00005 6ZM9.00005 7C9.00005 6.44772 9.44776 6 10 6C10.5523 6 11 6.44772 11 7V13C11 13.5523 10.5523 14 10 14C9.44776 14 9.00005 13.5523 9.00005 13V7Z',
    ],
    viewBox: '0 0 16 16',
    width: '16',
    height: '16',
  },
  progressBarCheck: {
    d: [
      'M5.28194 9.15275L3.35416 7.22497C3.25036 7.12095 3.10945 7.06248 2.96249 7.06248C2.81554 7.06248 2.67462 7.12095 2.57083 7.22497C2.35416 7.44164 2.35416 7.79164 2.57083 8.00831L4.89305 10.3305C5.10971 10.5472 5.45971 10.5472 5.67638 10.3305L11.5542 4.45275C11.7708 4.23609 11.7708 3.88609 11.5542 3.66942C11.4504 3.56539 11.3094 3.50693 11.1625 3.50693C11.0155 3.50693 10.8746 3.56539 10.7708 3.66942L5.28194 9.15275Z',
    ],
    viewBox: '0 0 14 14',
    width: '14',
    height: '14',
  },
  rightProgression: {
    d: [
      'M8.99953 6.71466C8.60953 7.10466 8.60953 7.73466 8.99953 8.12466L12.8795 12.0047L8.99953 15.8847C8.60953 16.2747 8.60953 16.9047 8.99953 17.2947C9.38953 17.6847 10.0195 17.6847 10.4095 17.2947L14.9995 12.7047C15.3895 12.3147 15.3895 11.6847 14.9995 11.2947L10.4095 6.70466C10.0295 6.32466 9.38953 6.32466 8.99953 6.71466Z',
    ],
    viewBox: '0 0 24 25',
    width: '24',
    height: '25',
  },
  leftProgression: {
    d: [
      'M14.9995 6.70899C14.8127 6.52174 14.559 6.4165 14.2945 6.4165C14.03 6.4165 13.7764 6.52174 13.5895 6.70899L8.99953 11.299C8.60953 11.689 8.60953 12.319 8.99953 12.709L13.5895 17.299C13.9795 17.689 14.6095 17.689 14.9995 17.299C15.3895 16.909 15.3895 16.279 14.9995 15.889L11.1195 11.999L14.9995 8.11899C15.3895 7.72899 15.3795 7.08899 14.9995 6.70899Z',
    ],
    viewBox: '0 0 24 25',
    width: '24',
    height: '25',
  },
};
