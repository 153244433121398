import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset'; // the meyer reset
import css from '@styled-system/css';

export default createGlobalStyle`
  /* Reset */
  ${reset}

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    scroll-margin-top: 80px;
  }

  body {
    font-size: 16px; /* theme-independent */
    margin: 0;
    overflow-x: hidden;       
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${css({
      color: 'text',
      fontFamily: 'default',
      '&.modal-open': {
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        right: 0,
        top: 0,
      },
    })}
  }

  a {
    text-decoration: none;
  }

  strong,
  b {
    ${css({
      fontWeight: 'bold',
    })}
  }

  em,
  i {
    font-style: italic;
  }

  address {
    white-space: pre-line;
  }
`;
