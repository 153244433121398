import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FieldGroup } from '../../components/FieldGroup';
import { useClientStore } from '../../store/store';
import PageNavigation from '../../components/PageNavigation';
import { FlexBox } from '../../components/Box';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import RedirectHandler from '../../components/RedirectHandler';

import Button from '../../components/Button';
import Typography from '../../components/Typography';
import { L10n } from '../../components/L10n';

import { handleEnterKeyPress } from '../../utils/common';

const LocationSearch = () => {
  const searchStore = useClientStore((state) => state.uiState.searchStr);
  const updateUiState = useClientStore((state) => state.updateUiState);
  const [searchStr, setSearchStr] = useState(searchStore);
  const [searchError, setSearchError] = useState('');

  const isMobile = window.screen.width < 768;
  const intl = useIntl();
  const searchInstructions = isMobile
    ? `${intl.formatMessage({ id: 'page.location_search.search' })} ${intl.formatMessage({
        id: 'page.location_search.search_criteria',
      })}`
    : `${intl.formatMessage({ id: 'page.location_search.instructions' })} ${intl.formatMessage({
        id: 'page.location_search.search_criteria',
      })}`;
  const searchLabel = intl.formatMessage({ id: 'page.location_search.search' });
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchStr(value);
  };

  const handleSearchBlur = (event) => {
    const { value } = event.target;
    if (!value || value === '') {
      setSearchError(intl.formatMessage({ id: 'page.location_search.required' }));
    } else {
      setSearchError('');
      updateUiState('searchStr', value);
    }
  };

  const handleNext = () => {
    let valid = false;
    if (!searchStr || searchStr === '') {
      setSearchError(
        intl.formatMessage(
          {
            id: 'general.field_error.data_error',
          },
          { data: intl.formatMessage({ id: 'general.location_lowercase' }) }
        )
      );
    } else {
      setSearchError('');
      updateUiState('searchStr', searchStr);
      valid = true;
    }
    updateUiState('validPage', valid);
    return valid;
  };

  return (
    <>
      <RedirectHandler pageNumber={4} />
      <FlexBox gridGap={['medium', 'xLarge']} flexDirection="column" data-testid="locationSearch">
        <FlexBox gridGap={['medium', 'large']} flexDirection="column">
          <PageTitleSubtitle title={<L10n tokenID="page.location_search.title" />} />
          <Typography variant="primaryBase">{searchInstructions}</Typography>
        </FlexBox>
        <FlexBox gridGap={['medium', 'large']} flexDirection={['column', 'row']}>
          <FieldGroup
            name="search"
            onChange={handleSearchChange}
            error={searchError}
            value={searchStr}
            type="search"
            onBlur={handleSearchBlur}
            ariaLabel={searchLabel}
            data-testid="searchInput"
            onKeyDown={(e) => handleEnterKeyPress(handleNext, e)}
          />
          <Button size="secondary" handleClick={handleNext} style={{ flexGrow: '2' }}>
            <L10n tokenID="page.location_search.search" />
          </Button>
        </FlexBox>
      </FlexBox>
      <PageNavigation hideNext={false} handleNext={handleNext} />
    </>
  );
};

export default LocationSearch;
