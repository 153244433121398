import React, { useState, useEffect } from 'react';
import Typography from '../../components/Typography';
import { RadioGroup } from '../../components/Radio';
import PageNavigation from '../../components/PageNavigation';
import { Box } from '../../components/Box';
import { useClientStore } from '../../store/store';
import { L10n } from '../../components/L10n';
import RedirectHandler from '../../components/RedirectHandler';
import { useOptionsQuery } from '../../api/Options';

const ClinicalInfo = () => {
  const { data, isLoading } = useOptionsQuery();
  const { aptOptions, langPref, udpateApptState, updateUiState } = useClientStore((state) => ({
    aptOptions: state.apptDetails.FS_Appointment_Options__c,
    langPref: state.uiState.langPref,
    udpateApptState: state.updateApptDetails,
    updateUiState: state.updateUiState,
  }));
  const [questionState, setQuestionState] = useState();
  const radioOptions = {
    uniqueId: 'radio-yn',
    choices: [
      {
        label: <L10n tokenID="general.yes" />,
        value: 'yes',
      },
      {
        label: <L10n tokenID="general.no" />,
        value: 'no',
      },
    ],
  };

  const handleChange = (event) => {
    const fieldName = event.target.getAttribute('name');
    const { value } = event.target.value ? event.target : event.target.dataset;
    setQuestionState({
      ...questionState,
      [fieldName]: {
        ...questionState[fieldName],
        value,
        error: '',
        hasError: false,
      },
    });
  };

  const validateRadioInput = (
    value,
    error = <L10n tokenID="general.field_error.response_required_error" />
  ) => {
    return (!value && { hasError: true, error }) || { hasError: false, error: null };
  };

  const handleNext = () => {
    let firstError;
    let currentState = { ...questionState };
    const apptOptions = [];
    document.querySelectorAll('div[name^="Question"]').forEach((node) => {
      const fieldName = node.getAttribute('name');
      const { value } = questionState[fieldName];
      const { error, hasError } = validateRadioInput(value);
      if (hasError) {
        if (!firstError) firstError = node;
        if (!questionState[fieldName].hasError) {
          currentState = {
            ...currentState,
            [fieldName]: {
              ...questionState[fieldName],
              hasError,
              error,
            },
          };
        }
      } else {
        apptOptions[questionState[fieldName].FS_Registration_Option__c] = {
          Id: questionState[fieldName].FS_Registration_Option__c,
          Value__c: value,
        };
      }
    });
    setQuestionState({ ...currentState });
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      updateUiState('validPage', true);

      // Add hidden prompts to store to create on backend. Later populated in CVM.
      data.data.records.forEach((question) => {
        if (question.Prompt_at_Check_In__c) {
          apptOptions[question.Id] = {
            Id: question.Id,
            Value__c: '',
          };
        }
      });
    }
    udpateApptState('FS_Appointment_Options__c', apptOptions);
  };

  useEffect(() => {
    if (!isLoading && data && !questionState) {
      const initialState = Object.assign(
        {},
        ...data.data.records.map((question) => ({
          [`Question_${question.Order__c}`]: {
            value: aptOptions[question.Id]?.Value__c,
            label: question.Label__c,
            label_spanish: question.Label_Spanish__c,
            order: question.Order__c,
            FS_Registration_Option__c: question.Id,
            subtext: question.Subtext__c,
            prompt_at_checkin: question.Prompt_at_Check_In__c,
            subtext_spanish: question.Subtext_Spanish__c,
          },
        }))
      );
      setQuestionState(initialState);
    }
  }, [aptOptions, data, isLoading, questionState]);

  return (
    <>
      <RedirectHandler pageNumber={7} />
      <Box
        data-testid="clinicalInfo"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
      >
        <Box display="grid" gridGap="large">
          <Typography tag="h1" variant={['primaryXLarge', 'primaryWeb3']}>
            <L10n tokenID="page.health_information.header" />
          </Typography>
          <Typography tag="p" variant={['primaryBase']}>
            <L10n tokenID="page.health_information.subheader" />
          </Typography>
        </Box>
        <Box display="grid" gridGap="xLarge">
          {questionState &&
            Object.values(questionState).map((question) => {
              const key = `Question_${question.order}`;
              return (
                !question.prompt_at_checkin && (
                  <RadioGroup
                    label={langPref === 'en-us' ? question.label : question.label_spanish}
                    subtext={langPref === 'en-us' ? question.subtext : question.subtext_spanish}
                    options={radioOptions}
                    onChange={handleChange}
                    selectedOption={question.value}
                    name={key}
                    key={`${key}_Group`}
                    error={question.error}
                    required
                  />
                )
              );
            })}
        </Box>
        <PageNavigation hideNext={false} handleNext={handleNext} />
      </Box>
    </>
  );
};

export default ClinicalInfo;
