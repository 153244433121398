import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { showToast } from './components/Toast';

import App from './app';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.queryKey[0] !== 'appointment') {
        showToast('Error', 'error', 'top-left', `Something went wrong: ${error.message}`);
      }
    },
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
