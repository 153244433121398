import React, { Suspense, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useIsFetching, useIsMutating, useQueryClient } from 'react-query';
import { ToastContainer } from './components/Toast';

import theme from './themes';
import GlobalStyle from './themes/global';
import { useClientStore } from './store/store';
import { AppRoutes } from './routes/routeMap';
import GlobalModal from './components/Modals/GlobalModal';

import { L10nProvider } from './components/L10nProvider';
import languagePacks from './utils/L10nPacks';

import { Box } from './components/Box';
import BodyContainer from './components/BodyContainer';
import Footer from './components/Footer';
import Header from './components/Header';
import Loader from './components/Loader';
import Home from './pages/Home';

import UserProgressionElements from './components/UserProgressionElements';

import { createClient, ApolloProvider } from './graphql';
import { useUpdateSessionHold } from './api/Session-hold';
import './index.css';

const environment = process.env.REACT_APP_ENV === 'prd' ? 'www' : 'cert-selling1';
const client = createClient({
  name: 'RX-COVID-VACCINE-APP',
  uri: `https://${environment}.heb.com/graphql`,
});

const App = () => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const { currentPage, langPref, modalOpen } = useClientStore((state) => ({
    currentPage: state.uiState.currentPage,
    langPref: state.uiState.langPref,
    modalOpen: state.modalState.modalOpen,
  }));

  const messages = languagePacks[langPref];
  const updateHold = useUpdateSessionHold();
  const hold = useClientStore((state) => state.apptDetails.FS_Session_Hold__c);

  const query = useQueryClient();
  const data = query.getQueriesData({
    queryKey: ['appointment'],
    exact: false,
    type: 'active',
    stale: false,
  });

  const isApptManagement = window.location.pathname === '/manage-appointment';
  const showingYAMS = isApptManagement && data !== undefined && data[0]?.length === 2;

  useEffect(() => {
    // Handle refresh or back
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ''; // display default confirmation message (browser-specific)
    };
    const handleVisibilityChange = (e) => {
      if (!e.persisted && hold.Id) {
        updateHold.mutate();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pagehide', handleVisibilityChange);
    if (showingYAMS || isApptManagement) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handleVisibilityChange);
    };
  }, [showingYAMS, isApptManagement, hold.Id, updateHold]);

  const status =
    data !== undefined || data.length === 0 ? '' : data[0][1].data.records[0].Status__c;

  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <L10nProvider locale={langPref} messages={messages}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Header back={currentPage === 0 || showingYAMS} />
            <BodyContainer
              paddingTop={[currentPage === 0 && !isApptManagement ? 'xLarge' : 'large', '48px']}
              fullHeight
              gridColumn={[
                '1/5',
                '1/13',
                showingYAMS && status === 'Scheduled' ? '2/12' : '3/11',
                showingYAMS && status === 'Scheduled' ? '3/11' : '4/10',
              ]}
            >
              <Box gridColumn={['1/5', '1 / 13']}>
                {(!showingYAMS || (isFetching === 0 && isMutating === 0)) && (
                  <UserProgressionElements
                    currentPage={currentPage}
                    isYAMS={!!showingYAMS}
                    langPref={langPref}
                    progressBarStage={AppRoutes[currentPage].progressBarStage}
                  />
                )}
                <BrowserRouter>
                  <Suspense fallback={<div />}>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      {AppRoutes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.component} />
                      ))}
                      <Route path="*" element={AppRoutes[0].component} />
                    </Routes>
                  </Suspense>
                  <GlobalModal />
                  <ToastContainer />
                </BrowserRouter>
              </Box>
            </BodyContainer>
            {(isFetching > 0 || isMutating > 0) && !modalOpen && currentPage !== 5 && <Loader />}
            {/* fixed position box needed to retrigger stickiness for page nav https://www.stevefenton.co.uk/blog/2022/12/mobile-position-sticky-issue/#final-solution */}
            <Box position="fixed" />
            <Box id="pageNav-root" bottom={0} position="sticky" />
            <Box id="modal-root" />
            <Footer />
          </ThemeProvider>
        </L10nProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
};

export default App;
