import React from 'react';
import { L10n } from '../components/L10n';

const validateRadioInput = (
  value,
  required = true,
  error = <L10n tokenID="general.field_error.response_required_error" />
) => {
  return (!value && required && { hasError: true, error }) || { hasError: false, error: null };
};

const validateCheckboxInput = (
  value,
  required = true,
  errorMsg = <L10n tokenID="general.field_error.response_required_error" />
) => {
  let hasError = required;
  let error = errorMsg;
  value.forEach((e) => {
    if (e.checked) {
      hasError = false;
      error = '';
    }
  });
  return { hasError, error };
};

const validatePhoneNumber = (value, required = false, error) => {
  if (value && value.match(/^\d{3}-\d{3}-\d{4}$/)) {
    return { hasError: false, error: null };
  }
  if (!value && !required) {
    return { hasError: false, error: null };
  }
  return { hasError: true, error };
};

const validateZipCode = (value, required = false, error) => {
  if (value && value.match(/^\d{5}$/)) {
    return { hasError: false, error: null };
  }
  if (!value && !required) {
    return { hasError: false, error: null };
  }
  return { hasError: true, error };
};

const validateEmail = (value, required = false, error) => {
  if (
    value &&
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return { hasError: false, error: null };
  }
  if (!value && !required) {
    return { hasError: false, error: null };
  }
  return { hasError: true, error };
};

const validateName = (value, required = false, error) => {
  if (value && value.toLowerCase().match(/^([^0-9]*)$/)) {
    return { hasError: false, error: null };
  }
  if (!value && !required) {
    return { hasError: false, error: null };
  }
  return { hasError: true, error };
};

const validateDate = (value, required = false, error) => {
  if (value && value.match(/(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(19|20)\d{2}/)) {
    return { hasError: false, error: null };
  }
  if (!value && !required) {
    return { hasError: false, error: null };
  }
  return { hasError: true, error };
};

const validateSSN = (value, required = false, error) => {
  if (value && value.match(/^\d{4}$/)) {
    return { hasError: false, error: null };
  }
  if (!value && !required) {
    return { hasError: false, error: null };
  }
  return { hasError: true, error };
};

const validateInput = (input, label = null, validator = null, value = null, errorMsg = null) => {
  let hasError = false;
  let error = '';
  const isRequired = input ? input?.required || input.dataset.required : true;
  if (!validator && input?.value === '' && isRequired) {
    hasError = true;
    error = errorMsg || (
      <L10n tokenID="general.field_error.required_error" values={{ field: label }} />
    );
  } else if (validator) {
    ({ hasError, error } = validator(value, isRequired, errorMsg));
  }
  return {
    hasError,
    error,
  };
};

const validationFunctions = [
  validateDate,
  validatePhoneNumber,
  validateZipCode,
  validateRadioInput,
  validateSSN,
  validateEmail,
  validateName,
  validateInput,
  validateCheckboxInput,
];

[
  validationFunctions.validateDate,
  validationFunctions.validatePhoneNumber,
  validationFunctions.validateZipCode,
  validationFunctions.validateRadioInput,
  validationFunctions.validateSSN,
  validationFunctions.validateEmail,
  validationFunctions.validateName,
  validationFunctions.validateInput,
  validationFunctions.validateCheckboxInput,
] = validationFunctions;

export default validationFunctions;
