import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, FlexBox } from '../../Box';
import Button from '../../Button';
import Card from '../../Card';
import { Content, Footer, Header } from '../../ModalComposition';
import DateTimeSelection from '../../DateTimeSelection';
import FormatDate from '../../FormatDate';
import InfoByte from '../../InfoByte';
import { L10n } from '../../L10n';
import Modal from '../../Modal';
import Typography from '../../Typography';

const ChangeAppointmentDateTimeModal = ({
  currApptData,
  handleClose,
  handleConfirm,
  type = 'reschedule',
  filteredProducts,
}) => {
  const modalPadding = ['large', 'xLarge'];
  const headerRef = useRef();
  const footerRef = useRef();

  const [modalAndFooterHeight, setModalAndFooterHeight] = useState(0);
  const [selectedAppt, setSelectedAppt] = useState({
    Event_Session__c: '',
    Appointment_Time__c: '',
    Appointment_Date__c: '',
  });
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!headerRef?.current?.clientHeight || !footerRef?.current?.clientHeight) {
      return;
    }
    setModalAndFooterHeight(headerRef?.current?.clientHeight + footerRef?.current?.clientHeight);
  }, [headerRef?.current?.clientHeight, footerRef?.current?.clientHeight]);

  const handleChangeClick = () => {
    if (selectedAppt.Event_Session__c === '') {
      setShowError(true);
    } else {
      handleConfirm(selectedAppt);
    }
  };

  const handleApptSelection = (apptDetailsObj) => {
    setSelectedAppt((currAppt) => ({
      ...currAppt,
      ...apptDetailsObj,
    }));
  };

  return (
    <Modal
      onClose={handleClose}
      isMobileFullScreen
      isDesktopFullScreen
      canCloseOnClickOutside
      header={
        <Header withClose handleClose={handleClose} px={modalPadding} ref={headerRef}>
          {type === 'reschedule' ? (
            <L10n tokenID="modal.change_date_time.reschedule_appt" />
          ) : (
            <L10n tokenID="modal.change_date_time.update_appt" />
          )}
        </Header>
      }
      content={
        <Content heightOffset={`${modalAndFooterHeight}px`} px={modalPadding}>
          <FlexBox flexDirection="column" gridGap={['xLarge']} height="100%">
            {showError ? (
              <InfoByte
                variant="error"
                info={<L10n tokenID="modal.change_date_time.error_no_selection" />}
              />
            ) : null}
            <Box flex="0 1 auto">
              <Typography variant="secondaryBase" space={{ pb: 'small' }}>
                {type === 'reschedule' ? (
                  <L10n tokenID="modal.change_date_time.reschedule_current_appt_title" />
                ) : (
                  <L10n tokenID="modal.change_date_time.change_current_appt_title" />
                )}
              </Typography>
              <Card variant="muted" margin="none" borderRadius="small">
                <Typography variant="secondaryMedium">
                  <L10n
                    tokenID="modal.change_date_time.current_appt"
                    values={{
                      location: currApptData.locationName,
                      date: <FormatDate dateStr={currApptData.date} format="full" />,
                      time: currApptData.time,
                    }}
                  />
                </Typography>
              </Card>
            </Box>
            <DateTimeSelection
              selectedAppt={selectedAppt}
              handleApptSelection={(apptDetailsObj) => handleApptSelection(apptDetailsObj)}
              location={currApptData.event}
              filteredProducts={filteredProducts}
            />
          </FlexBox>
        </Content>
      }
      footer={
        <Footer variant="stacked" px={modalPadding} ref={footerRef}>
          <Button handleClick={handleClose} styling="outlined" size={['hug', 'secondary']}>
            {type === 'reschedule' ? (
              <L10n tokenID="modal.change_date_time.keep_existing_appt" />
            ) : (
              <L10n tokenID="modal.change_date_time.keep_current_appt" />
            )}
          </Button>
          <Button handleClick={handleChangeClick} size={['hug', 'secondary']}>
            {type === 'reschedule' ? (
              <L10n tokenID="modal.change_date_time.reschedule_appt" />
            ) : (
              <L10n tokenID="modal.change_date_time.update_appt" />
            )}
          </Button>
        </Footer>
      }
    />
  );
};

ChangeAppointmentDateTimeModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  currApptData: PropTypes.shape({
    date: PropTypes.string,
    event: PropTypes.PropTypes.shape({
      Event_Session__c: PropTypes.string,
      Appointment_Date__c: PropTypes.string,
      Appointment_Time__c: PropTypes.string,
    }).isRequired,
    locationName: PropTypes.string,
    time: PropTypes.string,
  }),
  type: PropTypes.oneOf(['change', 'reschedule']),
  // eslint-disable-next-line
  filteredProducts: PropTypes.object,
};

export default ChangeAppointmentDateTimeModal;
