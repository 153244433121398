import '../../index.css';

const fontSizes = [12, 14, 16, 20, 24, 28, 36];

[
  fontSizes.xSmall, // 12
  fontSizes.small, // 14
  fontSizes.medium, // 16
  fontSizes.large, // 20
  fontSizes.xLarge, // 24
  fontSizes.xxLarge, // 28
  fontSizes.xxxLarge, // 36
] = fontSizes;

const fontWeights = [300, 400, 500, 600, 700, 800];

[
  fontWeights.light, // 300
  fontWeights.normal, // 400
  fontWeights.medium, // 500
  fontWeights.semiBold, // 600
  fontWeights.bold, // 700
  fontWeights.black, // 800
] = fontWeights;

const lineHeights = [1, 1.25, 1.33, 1.5, 2];

lineHeights.tight = lineHeights['0']; // 1
lineHeights.condensed = lineHeights['1']; // 1.25
lineHeights.heading = lineHeights['1']; // 1.25
lineHeights.normal = lineHeights['3']; // 1.5
lineHeights.default = lineHeights['3']; // 1.5
lineHeights.expanded = lineHeights['4']; // 2

const fonts = {
  default: `'Montserrat', -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif`,
};

const typography = {
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
};

export default typography;
