import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useEventQuery } from '../../api/Event';
import { useClientStore } from '../../store/store';
import { STORE_SEARCH_QUERY } from '../../apollo/queries';
import { sortByGeolocation } from './sortByGeoLocation';

const SEARCH_RADIUS = 25; // in miles

const useSearch = (searchStr, pageSize, products) => {
  const UrlSpecifiedEvent = useClientStore((state) => state.apptDetails.UrlSpecifiedEvent);
  const [results, setResults] = useState([]);
  const [bestMatch, setBestMatch] = useState();
  const productIdList = Object.keys(products).map((key) =>
    key === 'COVID-19' && products[key].selected.Id ? products[key].selected.Id : products[key].Id
  );
  const productIdStr = productIdList.join(',');
  const { data: eventData, isLoading: eventQueryLoading } = useEventQuery(bestMatch, productIdStr);

  // GraphQL callback and setup
  const handleGraphQlSuccess = (response) => {
    const store = response.searchStoresByAddress.stores[0];
    if (store?.store?.storeNumber) {
      setBestMatch(store.store.storeNumber);
    }
  };
  const handleGraphQlError = (response) => {
    setBestMatch(null);
    setResults([]); // safely set search results to empty array if no match found.
    // eslint-disable-next-line no-console
    console.error(`Error in searchStoresByAddress: ${JSON.stringify(response)}`);
  };
  const [searchStores, { loading: graphQlLoading }] = useLazyQuery(STORE_SEARCH_QUERY, {
    onCompleted: (response) => {
      handleGraphQlSuccess(response);
    },
    onError: (response) => {
      handleGraphQlError(response);
    },
  });

  const isLoading = eventQueryLoading || graphQlLoading;
  // Process event data once returned.

  useEffect(() => {
    if (eventData) {
      const events = eventData.data.customRecords;
      const bestMatchEvent = eventData.data.bestMatch;
      if (bestMatchEvent) {
        const eventsByGeoLocation = sortByGeolocation(
          bestMatchEvent.Location__r.Geolocation__Latitude__s,
          bestMatchEvent.Location__r.Geolocation__Longitude__s,
          events,
          SEARCH_RADIUS
        );
        if (pageSize) {
          const paginatedArray = [];
          const pageCount = Math.ceil(eventsByGeoLocation.length / pageSize);
          for (let i = 0; i < pageCount; i += 1) {
            const start = i * pageSize;
            const end = start + pageSize;
            paginatedArray.push(eventsByGeoLocation.slice(start, end));
          }
          setResults(paginatedArray);
        }
        setResults(eventsByGeoLocation);
      } else {
        setResults([]);
      }
    }
  }, [eventData, pageSize, bestMatch]);

  // Update search when variables change
  useEffect(() => {
    const search = () => {
      // If we already know event, return it.
      if (UrlSpecifiedEvent) {
        setResults([UrlSpecifiedEvent]);
      } else if (searchStr) {
        // Search StoreInfoService for closest match
        searchStores({
          variables: {
            address: searchStr,
            radius: SEARCH_RADIUS,
            fulfillmentChannels: [],
          },
        });
      } else {
        setResults([]);
      }
    };
    search();
  }, [searchStr, searchStores, UrlSpecifiedEvent]);

  return {
    searchStr,
    results,
    isLoading,
  };
};

export default useSearch;
