import React from 'react';
import VaccineSelection from '../pages/VaccineSelection';
import DobPage from '../pages/DateOfBirth';
import IsPregnant from '../pages/IsPregnant';
import LocationSearch from '../pages/LocationSearch';
import LocationSelection from '../pages/LocationSelection';
import PatientInfo from '../pages/PatientInfo';
import ClinicalInfo from '../pages/ClinicalInfo';
import InsuranceSelection from '../pages/InsuranceSelection';
import MedicarePartB from '../pages/MedicarePartB';
import MedicarePartC from '../pages/MedicarePartC';
import MedicarePartD from '../pages/MedicarePartD';
import PharmacyMedical from '../pages/PharmacyMedical';
import MedicareStatement from '../pages/MedicareStatement';
import ReviewScreen from '../pages/ReviewScreen';
import AppointmentManagement from '../pages/AppointmentManagement';
import ConsentAcknowledgments from '../pages/ConsentAcknowledgments';
import CovidManufacturerSelection from '../pages/CovidManufacturerSelection';

// Mapping routes to specific components. Used to setup react-router-dom.
export const AppRoutes = [
  { path: 'dob', component: <DobPage />, progressBarStage: 0 },
  {
    path: 'select-vaccines',
    component: <VaccineSelection />,
    progressBarStage: 0,
  },
  { path: 'is-pregnant', component: <IsPregnant />, progressBarStage: 0 },
  { path: 'covid-manufacturer', component: <CovidManufacturerSelection />, progressBarStage: 0 },
  {
    path: 'search-location',
    component: <LocationSearch />,
    progressBarStage: 1,
  },
  {
    path: 'select-location',
    component: <LocationSelection />,
    progressBarStage: 1,
  },
  { path: 'patient-info', component: <PatientInfo />, progressBarStage: 2 },
  { path: 'clinical-info', component: <ClinicalInfo />, progressBarStage: 2 },
  {
    path: 'insurance-info',
    component: <InsuranceSelection />,
    progressBarStage: 2,
  },
  { path: 'medicare-part-b', component: <MedicarePartB />, progressBarStage: 2 },
  { path: 'medicare-part-c', component: <MedicarePartC />, progressBarStage: 2 },
  { path: 'medicare-part-d', component: <MedicarePartD />, progressBarStage: 2 },
  {
    path: 'pharmacy-medical',
    component: <PharmacyMedical />,
    progressBarStage: 2,
  },
  {
    path: 'medicare-statement',
    component: <MedicareStatement />,
    progressBarStage: 2,
  },
  { path: 'patient-consent', component: <ConsentAcknowledgments />, progressBarStage: 2 },
  { path: 'review-appointment', component: <ReviewScreen />, progressBarStage: 3 },
  {
    path: 'manage-appointment',
    component: <AppointmentManagement />,
    progressBarStage: 4,
  },
];
