// styling from https://www.figma.com/file/Ockcd8mwJK5I9AVEFjJ7pQ/%E2%9A%9B%EF%B8%8FTypography---Rx-web?type=design&node-id=867%3A0&mode=design&t=h1EY5o8mJIGwyZDo-1

const sizes = {
  web1: {
    fontSize: '44px',
    lineHeight: '60px',
  },
  web2: {
    fontSize: '40px',
    lineHeight: '56px',
  },
  web3: {
    fontSize: 'xxxLarge',
    lineHeight: 2,
  },
  web4: {
    fontSize: '32px',
    lineHeight: '44px',
  },
  xxLarge: {
    fontSize: 'xxLarge',
    lineHeight: '40px',
  },
  xLarge: {
    fontSize: 'xLarge',
    lineHeight: 2,
  },
  large: {
    fontSize: 'large',
    lineHeight: '28px',
  },
  base: {
    fontSize: 'medium',
    lineHeight: 3,
  },
  medium: {
    fontSize: 'small',
    lineHeight: '20px',
  },
  small: {
    fontSize: 'xSmall',
    lineHeight: '16px',
  },
  xSmall: {
    fontSize: '10px',
    lineHeight: '16px',
  },
};

const styling = {
  italic: {
    fontStyle: 'italic',
  },
  underline: {
    textDecoration: 'underline',
  },
  strikthrough: {
    textDecoration: 'line-through',
  },
  error: {
    color: 'brisket',
  },
  hyperlink: {
    color: 'rxInteractionBlue',
  },
};

// variants
const primaryTypography = {
  primaryWeb1: {
    ...sizes.web1,
    fontWeight: 'black',
  },
  primaryWeb2: {
    ...sizes.web2,
    fontWeight: 'black',
  },
  primaryWeb3: {
    ...sizes.web3,
    fontWeight: 'black',
  },
  primaryWeb4: {
    ...sizes.web4,
    fontWeight: 'black',
  },
  primaryXXLarge: {
    ...sizes.xxLarge,
    fontWeight: 'black',
  },
  primaryXLarge: {
    ...sizes.xLarge,
    fontWeight: 'black',
  },
  primaryLarge: {
    ...sizes.large,
    fontWeight: 'black',
  },
  primaryBase: {
    ...sizes.base,
    fontWeight: 'medium',
  },
  primaryMedium: {
    ...sizes.medium,
    fontWeight: 'medium',
  },
  primarySmall: {
    ...sizes.small,
    fontWeight: 'medium',
  },
  primaryXSmall: {
    ...sizes.xSmall,
    fontWeight: 'medium',
  },
};

const secondaryTypography = {
  secondaryWeb1: {
    ...sizes.web1,
    fontWeight: 'medium',
  },
  secondaryWeb2: {
    ...sizes.web2,
    fontWeight: 'medium',
  },
  secondaryWeb3: {
    ...sizes.web3,
    fontWeight: 'medium',
  },
  secondaryWeb4: {
    ...sizes.web4,
    fontWeight: 'medium',
  },
  secondaryXXLarge: {
    ...sizes.xxLarge,
    fontWeight: 'medium',
  },
  secondaryXLarge: {
    ...sizes.xLarge,
    fontWeight: 'medium',
  },
  secondaryLarge: {
    ...sizes.large,
    fontWeight: 'medium',
  },
  secondaryBase: {
    ...sizes.base,
    fontWeight: 'black',
  },
  secondaryMedium: {
    ...sizes.medium,
    fontWeight: 'black',
  },
  secondarySmall: {
    ...sizes.small,
    fontWeight: 'black',
  },
  secondaryXSmall: {
    ...sizes.xSmall,
    fontWeight: 'black',
  },
};

const boldTypography = {
  boldBase: {
    ...primaryTypography.primaryBase,
    fontWeight: 'black',
  },
  boldSmall: {
    ...primaryTypography.primarySmall,
    fontWeight: 'black',
  },
};

const italicsTypography = {
  italicsBase: {
    ...primaryTypography.primaryBase,
    ...styling.italic,
  },
  italicsMedium: {
    ...primaryTypography.primaryMedium,
    ...styling.italic,
  },
  italicsSmall: {
    ...primaryTypography.primarySmall,
    ...styling.italic,
  },
};

const underlineTypography = {
  underlineSmall: {
    ...primaryTypography.primarySmall,
    ...styling.underline,
  },
  underlineMedium: {
    ...primaryTypography.primaryMedium,
    ...styling.underline,
  },
};

const strikethroughTypography = {
  strikethroughPrimaryMedium: {
    ...primaryTypography.primaryMedium,
    ...styling.strikthrough,
  },
  strikethroughSecondaryMedium: {
    ...secondaryTypography.secondaryMedium,
    ...styling.strikthrough,
  },
  strikethroughPrimarySmall: {
    ...primaryTypography.primarySmall,
    ...styling.strikthrough,
  },
  strikethroughSecondarySmall: {
    ...secondaryTypography.secondarySmall,
    ...styling.strikthrough,
  },
};

const errorTypography = {
  errorSecondaryBase: {
    ...secondaryTypography.secondaryBase,
    ...styling.error,
  },
  errorPrimarySmall: {
    ...primaryTypography.primarySmall,
    ...styling.error,
  },
  errorPrimaryBase: {
    ...primaryTypography.primaryBase,
    ...styling.error,
  },
  errorPrimaryMedium: {
    ...primaryTypography.primaryMedium,
    ...styling.error,
  },
};

const hyperlinkTypography = {
  hyperLinkPrimaryBase: {
    ...primaryTypography.primaryBase,
    ...styling.hyperlink,
  },
  hyperLinkSecondaryBase: {
    ...secondaryTypography.secondaryBase,
    ...styling.hyperlink,
  },
};

export const typographyVariants = {
  ...primaryTypography,
  ...secondaryTypography,
  ...italicsTypography,
  ...underlineTypography,
  ...strikethroughTypography,
  ...errorTypography,
  ...hyperlinkTypography,
  ...boldTypography,
};
