import React from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useClientStore } from '../../store/store';
import { StyledHoldCard, StyledInfo, StyledLocation } from './styles';
import Icon from '../Icon';
import Card from '../Card';
import FormatDate from '../FormatDate';

const HoldCard = ({ time }) => {
  const { getApptDetails, uiState } = useClientStore((state) => ({
    getApptDetails: state.apptDetails,
    uiState: state.uiState,
  }));

  const isMobile = window.screen.width < 767;

  const formattedApptDay = getApptDetails.Appointment_Date__c
    ? format(new Date(getApptDetails.Appointment_Date__c), 'PP')
    : format(new Date(), 'PP');
  const intl = useIntl();
  return (
    <Card variant="callOut" margin="0 0 24px">
      <StyledHoldCard data-testid="hold-card">
        <Icon icon="timer" />
        <StyledInfo>
          {intl.formatMessage({ id: 'components.holdCard.time' }, { data: time })}
          {!isMobile && uiState.langPref === 'en-us' && (
            <StyledLocation>{`${getApptDetails.FSEvent__c.Name} ${intl.formatMessage({
              id: 'components.holdCard.on',
            })} ${formattedApptDay} ${intl.formatMessage({
              id: 'components.holdCard.at',
            })} ${getApptDetails.Appointment_Time__c}`}</StyledLocation>
          )}
          {!isMobile && uiState.langPref === 'es-us' && (
            <>
              <StyledLocation>
                {`${getApptDetails.FSEvent__c.Name} ${intl.formatMessage({
                  id: 'components.holdCard.on',
                })}`}{' '}
                <FormatDate dateStr={formattedApptDay} format="full" />{' '}
                {`${intl.formatMessage({
                  id: 'components.holdCard.at',
                })}
                ${getApptDetails.Appointment_Time__c}`}
              </StyledLocation>
            </>
          )}
          {isMobile && (
            <>
              <StyledLocation>{getApptDetails.FSEvent__c.Name}</StyledLocation>
              <StyledLocation>
                {`${formattedApptDay} ${intl.formatMessage({
                  id: 'components.holdCard.at',
                })} ${getApptDetails.Appointment_Time__c}`}
              </StyledLocation>
            </>
          )}
        </StyledInfo>
      </StyledHoldCard>
    </Card>
  );
};

HoldCard.propTypes = {
  time: PropTypes.string.isRequired,
};

export default HoldCard;
