import React from 'react';
import validationFunctions from '../../utils/validation';
import { L10n } from '../../components/L10n';

const functions = validationFunctions;

export const validators = {
  Email_Address__c: functions.validateEmail,
  Phone_Number__c: functions.validatePhoneNumber,
  Zip_Code__c: functions.validateZipCode,
  Race__c: functions.validateCheckboxInput,
  Gender__c: functions.validateRadioInput,
  Ethnicity__c: functions.validateRadioInput,
  sharePcpInput__c: functions.validateRadioInput,
  validateInput: functions.validateInput,
  Primary_Care_Provider_Phone__c: functions.validatePhoneNumber,
  Primary_Care_Provider_Fax__c: functions.validatePhoneNumber,
};

export const errors = {
  Phone_Number__c: (
    <L10n
      tokenID="general.field_error.valid_data_error"
      values={{ data: <L10n tokenID="general.phone_number" /> }}
    />
  ),
  Zip_Code__c: (
    <L10n
      tokenID="general.field_error.valid_data_error"
      values={{ data: <L10n tokenID="general.zip_code" /> }}
    />
  ),
  Race__c: <L10n tokenID="general.field_error.response_required_error" />,
  Gender__c: <L10n tokenID="general.field_error.response_required_error" />,
  Ethnicity__c: <L10n tokenID="general.field_error.response_required_error" />,
  sharePcpInput__c: <L10n tokenID="general.field_error.response_required_error" />,
  Email_Address__c: (
    <L10n
      tokenID="general.field_error.valid_data_error"
      values={{ data: <L10n tokenID="general.email_address" /> }}
    />
  ),
  Primary_Care_Provider_Phone__c: (
    <L10n
      tokenID="general.field_error.valid_data_error"
      values={{ data: <L10n tokenID="general.phone_number" /> }}
    />
  ),
  Primary_Care_Provider_Fax__c: (
    <L10n
      tokenID="general.field_error.valid_data_error"
      values={{ data: <L10n tokenID="general.fax_number" /> }}
    />
  ),
};
