import colors from './colors';

const borderWidths = ['1px', '2px'];

const borderStyles = {
  solid: 'solid',
};

const borders = {
  normal: `${borderWidths['0']} ${borderStyles.solid}`,
  normalMuted: `${borderWidths['0']} ${borderStyles.solid} ${colors.coldBrews[200]}`,
  normalError: `${borderWidths['0']} ${borderStyles.solid} ${colors.brisket}`,
  solidBlue: `${borderWidths['1']} ${borderStyles.solid} ${colors.rxInteractionBlue}`,
  solidBlueNormal: `${borderWidths['0']} ${borderStyles.solid} ${colors.rxInteractionBlue}`,
};

export default { borderStyles, borderWidths, borders };
