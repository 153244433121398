import { useMutation } from 'react-query';
import { api } from './config/axiosConfig';
import { useClientStore } from '../store/store';

const createSessionHold = async (sessionId) => {
  const response = await api.request({
    url: `/session-hold`,
    method: 'POST',
    data: {
      sessionId,
    },
  });
  return response;
};

const updateSessionHold = async (hold) => {
  const response = await api.request({
    url: `/session-hold`,
    method: 'PATCH',
    data: {
      holdId: hold.Id,
    },
  });
  return response;
};

export const useCreateSessionHold = () => {
  const session = useClientStore((state) => state.apptDetails.Event_Session__c);
  const updateApptDetails = useClientStore((state) => state.updateApptDetails);
  return useMutation({
    mutationFn: (sessionId) => createSessionHold(sessionId || session),
    onSuccess: (holdData) => {
      // handle return logic for new session hold
      const hold = holdData.data.records[0];
      updateApptDetails('FS_Session_Hold__c', hold);
    },
  });
};

export const useUpdateSessionHold = () => {
  const hold = useClientStore((state) => state.apptDetails.FS_Session_Hold__c);
  return useMutation({
    mutationFn: () => updateSessionHold(hold),
  });
};
