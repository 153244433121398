import React from 'react';
import PropTypes from 'prop-types';

import { StyledCheckbox, UncheckedBox } from './styles';
import Icon from '../Icon';
import { Box } from '../Box';

const Checkbox = ({ id, checked = false, label, onChange, disabled = false, error = false }) => {
  const toggleCheckbox = (e) => onChange && onChange({ checked: !checked }, e);

  return (
    <Box position="relative">
      <StyledCheckbox
        id={id}
        role="checkbox"
        type="checkbox"
        onChange={!disabled ? toggleCheckbox : undefined}
        aria-checked={checked}
        aria-label={label}
        checked={checked}
        data-label={label}
        disabled={disabled}
        className={error ? 'error' : ''}
      />
      <div>
        {checked ? (
          <Icon icon="checkboxActive" color="rxInteractionBlue" styling="plainBlue" />
        ) : (
          <UncheckedBox checked={checked} disabled={disabled} />
        )}
      </div>
    </Box>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export { Checkbox };
