import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import InfoByte from '../InfoByte';
import { Checkbox } from '../Checkbox';
import Typography from '../Typography';
import RequiredAsterisk from '../RequiredAsterisk';
import { Box } from '../Box';

import {
  CheckboxBorder,
  CheckboxGroupContainer,
  CheckboxLabel,
  CheckboxContainer,
  CheckboxFieldContainer,
  InfoContainer,
  AnimationContainer,
  StyledErrorMessage,
} from './styles';

const CheckboxGroup = ({
  label,
  options,
  onChange,
  error,
  name,
  required = false,
  variant = 'default',
  describedById,
}) => {
  const textVariant = error ? 'errorSecondaryBase' : 'secondaryBase';
  const handleCheckboxGroupChange = (index, value, e) => {
    onChange(index, value, e);
  };

  return (
    <Box
      name={name}
      className={error ? 'error' : undefined}
      role="group"
      aria-labelledby={name !== undefined ? `${name}-label` : undefined}
      aria-describedby={describedById !== undefined ? describedById : undefined}
    >
      <CheckboxGroupContainer className={variant}>
        {label && (
          <Typography variant={[textVariant]} id={name !== undefined ? `${name}-label` : undefined}>
            {label}
            {required && <RequiredAsterisk />}
          </Typography>
        )}
        <CheckboxBorder className={variant}>
          {options.map((checkbox, index) => (
            <CheckboxCore
              key={checkbox.label}
              label={checkbox.label}
              vanityLabel={checkbox.vanityLabel}
              checked={checkbox.checked}
              info={checkbox.info}
              infoVariant={checkbox.infoVariant}
              disabled={checkbox.disabled}
              showCheckbox={checkbox.showCheckbox}
              index={index}
              onChange={!checkbox.disabled ? handleCheckboxGroupChange : undefined}
              error={error}
              variant={variant}
              name={name}
            />
          ))}
        </CheckboxBorder>
      </CheckboxGroupContainer>
      {error && (
        <StyledErrorMessage>
          <Typography tag="p" variant={['errorPrimarySmall']} space={{ mt: 'xxSmall' }}>
            {error}
          </Typography>
        </StyledErrorMessage>
      )}
    </Box>
  );
};

CheckboxGroup.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      checked: PropTypes.bool.isRequired,
      disabled: PropTypes.bool,
      info: PropTypes.node,
      infoVariant: PropTypes.string,
      showCheckbox: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  variant: PropTypes.string,
  describedById: PropTypes.string,
};

const CheckboxCore = ({
  index,
  label,
  checked,
  info,
  infoVariant,
  disabled,
  onChange,
  showCheckbox = true,
  error,
  variant,
  name,
  vanityLabel,
}) => {
  const textVariant = error ? 'errorPrimaryBase' : 'primaryBase';
  const handleCheckboxChange = (newCheckedStatus, e) => {
    const isChecked = newCheckedStatus ? newCheckedStatus.checked : !checked;
    onChange(index, isChecked, e);
  };
  const uniqueID = name !== undefined ? `${name}-${label}` : label;
  return showCheckbox ? (
    <CheckboxFieldContainer
      checked={checked}
      info={info}
      disabled={disabled}
      className={variant}
      showCheckbox={showCheckbox}
    >
      <CheckboxContainer>
        <CheckboxLabel data-label={label} id={`${label}-label`} htmlFor={uniqueID}>
          <Checkbox
            id={uniqueID}
            label={label}
            checked={checked}
            disabled={disabled}
            error={error}
            onChange={!disabled ? handleCheckboxChange : undefined}
          />
          <Typography variant={[textVariant]}>{vanityLabel || label}</Typography>
        </CheckboxLabel>
      </CheckboxContainer>
      <AnimationContainer checked={checked}>
        <TransitionGroup>
          {checked && info && (
            <CSSTransition in={checked} timeout={{ enter: 500, exit: 1 }} classNames="infoByte">
              <InfoContainer role="alert">
                <InfoByte info={info} variant={infoVariant} />
              </InfoContainer>
            </CSSTransition>
          )}
        </TransitionGroup>
      </AnimationContainer>
    </CheckboxFieldContainer>
  ) : null;
};

CheckboxCore.propTypes = {
  index: PropTypes.number,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  vanityLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  info: PropTypes.node,
  infoVariant: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showCheckbox: PropTypes.bool,
  name: PropTypes.string,
};

export { CheckboxGroup };
