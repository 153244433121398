import styled from 'styled-components';
import css from '@styled-system/css';
import { Box } from '../Box';

export const StyledLink = styled.a`
  ${css({
    color: 'coldBrews.800',
    fontFamily: 'default',
    lineHeight: 'heading',
    m: 'none',
    textDecoration: 'underline',
  })}
`;

export const StyledContainer = styled(Box)(
  css({
    display: 'grid',
    gridGap: 'large',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr));',
  })
);
