import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../Input';
import RequiredAsterisk from '../RequiredAsterisk';
import Typography from '../Typography';
import { StyledFieldGroup, Label } from './styles';

const FieldGroup = forwardRef(
  (
    {
      label,
      name,
      help,
      error,
      required,
      placeholder,
      maxlength,
      onChange,
      mask,
      disabled,
      variant,
      onBlur,
      value,
      type = 'text',
      ariaLabel,
      onKeyDown,
      autocomplete,
    },
    ref
  ) => {
    const labelStyling = disabled ? 'disabled' : 'base';
    return (
      <StyledFieldGroup htmlFor={name} error={error}>
        {label && (
          <Label id={`${name}.label`} className={labelStyling}>
            {label}
            {required && <RequiredAsterisk />}
          </Label>
        )}
        <Input
          disabled={disabled}
          mask={mask}
          id={name}
          name={name}
          required={required}
          error={error}
          placeholder={placeholder}
          type={type}
          maxlength={maxlength}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          variant={variant}
          value={value}
          ariaLabel={ariaLabel}
          onKeyDown={onKeyDown}
          autocomplete={autocomplete}
        />
        {error ? (
          <Typography
            tag="p"
            variant="errorPrimarySmall"
            space={{ mt: 'xxSmall' }}
            id={`${name}.error`}
          >
            {error}
          </Typography>
        ) : (
          help && (
            <Typography tag="p" variant="primarySmall" space={{ mt: 'xxSmall' }}>
              {help}
            </Typography>
          )
        )}
      </StyledFieldGroup>
    );
  }
);

FieldGroup.propTypes = {
  mask: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  maxlength: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.arrayOf(PropTypes.oneOf(['base', 'medium'])),
  onBlur: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.oneOf(['text', 'search', 'tel']),
  ariaLabel: PropTypes.string,
  onKeyDown: PropTypes.func,
  autocomplete: PropTypes.oneOf([
    'name',
    'given-name',
    'family-name',
    'email',
    'street-address',
    'country',
    'country-name',
    'bday',
    'tel',
    'postal-code',
  ]),
};

export { FieldGroup };
