import * as React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { StyledFooter } from './styles';

const Footer = ({ children, tag }) => {
  const intl = useIntl();
  const currentYear = new Date().getFullYear();
  return (
    <StyledFooter variant={tag} data-testid="footer">
      {{ children } && <div>{children}</div>}
      {intl.formatMessage({ id: 'components.footer.copyright' }, { data: currentYear })}
    </StyledFooter>
  );
};

Footer.propTypes = {
  children: PropTypes.string,
  tag: PropTypes.string,
};

export default Footer;
