const basic = {
  border: 'borderWidths',
  borderStyle: 'solid',
  borderColor: 'coldbrew',
};

const callOut = {
  backgroundColor: 'rxColors.rxInteraction50',
  border: 'none',
};

const checkBox = {
  backgroundColor: 'buttercream',
  borderColor: 'coldbrew',
};

const error = {
  bg: 'rxColors.rxPink',
  borderColor: 'brisket',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: 'medium',
};

const errorBorder = {
  ...error,
  bg: 'buttercream',
  borderRadius: 'large',
};

const highlight = {
  backgroundColor: 'buttercream',
  borderColor: 'rxInteractionBlue',
};

const imageCard = {
  backgroundColor: 'rxColors.rxInteraction50',
};

const muted = {
  border: 'normal',
  borderColor: 'coldBrews.200',
};

const success = {
  bg: 'rxColors.rxPaleGreen',
  borderColor: 'hatch',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: 'medium',
};

const successOutline = {
  ...success,
  bg: 'buttercream',
};

const warning = {
  bg: 'alertWarningBg',
  borderColor: 'tamale',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: 'medium',
};

export const cardVariants = {
  basic,
  callOut,
  checkBox,
  error,
  errorBorder,
  imageCard,
  highlight,
  muted,
  success,
  successOutline,
  warning,
};
