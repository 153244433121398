import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import ProgressionButton from '../ProgressionButton';
import { FlexBox } from '../Box';
import Typography from '../Typography';
import { StyledList, StyledListItem, StyledNav, StyledPaginationContainer } from './styles';

const Pagination = ({
  currentPage = 0,
  numberOfItems = 5,
  pageSize = 8,
  records,
  pageCount = Math.ceil(records.length / pageSize),
  setVisibleRecords,
  setCurrentPage,
}) => {
  const setPages = (pageNumber = 0) => {
    const results = records.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize);
    setCurrentPage(pageNumber);
    setVisibleRecords(results);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };
  const availablePages = [...Array(pageCount).keys()].map((pageNumber) => {
    const selectedPage = pageNumber === currentPage;
    return (
      <StyledListItem key={pageNumber}>
        <Button
          styling="plainBlue"
          size="noPaddingTertiary"
          handleClick={() => setPages(pageNumber)}
        >
          <Typography
            tag="p"
            variant={selectedPage ? ['boldSmall'] : ['primarySmall']}
            color={{ color: 'rxInteractionBlue' }}
            space={{ px: ['medium', '14px'] }}
          >
            {pageNumber + 1}
          </Typography>
        </Button>
      </StyledListItem>
    );
  });
  return (
    <StyledNav justifyContent={['center', 'end']}>
      <StyledPaginationContainer
        alignItems="center"
        gridGap={['small', 'medium']}
        width={['100%', '']}
        justifyContent={['space-between', 'end']}
      >
        <ProgressionButton
          direction="back"
          disabled={currentPage === 0}
          buttonStyling="base"
          iconStyling="base"
          size="xxLarge"
          handleClick={() => setPages(currentPage - 1)}
        />
        <FlexBox>
          <StyledList>
            {currentPage < numberOfItems - 1 && availablePages.slice(0, numberOfItems)}
            {currentPage >= numberOfItems - 1 && availablePages[0]}
            {currentPage >= numberOfItems - 1 && (
              <StyledListItem>
                <Typography
                  tag="p"
                  variant={['primarySmall']}
                  color={{ color: 'rxInteractionBlue' }}
                >
                  ...
                </Typography>
              </StyledListItem>
            )}
            {currentPage >= numberOfItems - 1 &&
              availablePages.slice(
                currentPage + numberOfItems >= pageCount
                  ? pageCount - numberOfItems - 1 || 1
                  : currentPage,
                currentPage + numberOfItems < pageCount - 1
                  ? currentPage + numberOfItems
                  : pageCount - 1
              )}
            {currentPage + numberOfItems < pageCount - 1 && (
              <StyledListItem>
                <Typography
                  tag="p"
                  variant={['primarySmall']}
                  color={{ color: 'rxInteractionBlue' }}
                >
                  ...
                </Typography>
              </StyledListItem>
            )}
            {availablePages.length > numberOfItems && availablePages.slice(-1)}
          </StyledList>
        </FlexBox>
        <ProgressionButton
          direction="next"
          disabled={currentPage >= pageCount - 1}
          buttonStyling="base"
          iconStyling="base"
          size="xxLarge"
          handleClick={() => setPages(currentPage + 1)}
        />
      </StyledPaginationContainer>
    </StyledNav>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  numberOfItems: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setVisibleRecords: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  records: PropTypes.arrayOf(PropTypes.object),
};

export default Pagination;
