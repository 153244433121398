import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import InfoByte from '../InfoByte';
import RequiredAsterisk from '../RequiredAsterisk';
import Typography from '../Typography';
import { Box } from '../Box';

import {
  AnimationContainer,
  RadioGroupContainer,
  StyledInputContainer,
  StyledRadioField,
  RadioLabel,
  StyledRadio,
  RadioBorder,
  RadioCardContainer,
  RadioContainer,
  InfoContainer,
  StyledErrorMessage,
} from './styles';

const RadioGroup = ({
  label,
  options,
  selectedOption,
  onChange,
  error,
  name,
  required = false,
  subtext,
  variant = 'default',
  showLabel = true,
  scrollSelectedOptionIntoView = false,
  addDataLabel,
}) => {
  const textVariant = error ? 'errorSecondaryBase' : 'secondaryBase';
  useEffect(() => {
    const selectedElement = document.querySelector(`input[value="${selectedOption}"]`);
    const scrollIntoView =
      scrollSelectedOptionIntoView && selectedOption !== '' && selectedElement !== null;
    if (scrollIntoView) {
      selectedElement.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }, [selectedOption, scrollSelectedOptionIntoView]);
  return (
    <Box name={name} role="radiogroup" className={variant} data-required={required}>
      <RadioGroupContainer className={variant}>
        {label && (
          <>
            <Box display={showLabel ? 'grid' : 'none'} gridGap="small">
              <Typography tag="p" variant={[textVariant]}>
                {label}
                {required && <RequiredAsterisk />}
              </Typography>
              {subtext && (
                <Typography
                  tag="p"
                  variant={error ? ['errorPrimaryMedium'] : ['primaryMedium']}
                  space={{ mb: 'small' }}
                >
                  {subtext}
                </Typography>
              )}
            </Box>
          </>
        )}
        <RadioBorder className={variant}>
          {options.choices.map((choice) => (
            <Radio
              key={`${name}-${choice.value}`}
              id={`${name}-${choice.value}`}
              name={`${name}`}
              label={choice.label}
              checked={choice.value === selectedOption}
              value={choice.value}
              onChange={onChange}
              info={choice.info}
              infoVariant={choice.infoVariant}
              error={error}
              addDataLabel={addDataLabel}
              variant={variant}
            />
          ))}
        </RadioBorder>
      </RadioGroupContainer>
      {error && (
        <StyledErrorMessage id={`${name}.error`}>
          <Typography tag="p" variant={['errorPrimarySmall']} space={{ mt: 'xxSmall' }}>
            {error}
          </Typography>
        </StyledErrorMessage>
      )}
    </Box>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.shape({
    uniqueId: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
        value: PropTypes.string.isRequired,
        info: PropTypes.node,
      })
    ),
  }),
  selectedOption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  subtext: PropTypes.string,
  variant: PropTypes.oneOf(['card', 'default', 'simple']),
  showLabel: PropTypes.bool,
  scrollSelectedOptionIntoView: PropTypes.bool,
  addDataLabel: PropTypes.bool,
};

/**
 * Radio core component
 */
const Radio = ({
  id,
  name,
  value,
  label,
  checked,
  onChange,
  info,
  infoVariant,
  error,
  variant,
  addDataLabel,
}) => {
  const textVariant = error ? 'errorPrimaryBase' : 'primaryBase';
  const radioClass = error ? 'error' : variant;
  return (
    <RadioCardContainer checked={checked} className={radioClass}>
      <RadioContainer>
        <StyledInputContainer checked={checked}>
          <input
            id={id}
            name={name}
            type="radio"
            aria-checked={checked}
            checked={checked}
            onChange={onChange}
            value={value}
            data-label={addDataLabel ? label : null}
          />
          <StyledRadioField>
            <StyledRadio
              checked={checked}
              role="radio"
              aria-checked={checked}
              aria-label={label}
              className={radioClass}
            />
          </StyledRadioField>
          <RadioLabel id={`${id}-label`} htmlFor={id}>
            <Typography variant={[textVariant]}>{label}</Typography>
          </RadioLabel>
        </StyledInputContainer>
      </RadioContainer>
      <AnimationContainer checked={checked}>
        <TransitionGroup>
          {checked && info && (
            <CSSTransition in={checked} timeout={{ enter: 500, exit: 1 }} classNames="infoByte">
              <InfoContainer role="alert" checked={checked}>
                <InfoByte info={info} variant={infoVariant} />
              </InfoContainer>
            </CSSTransition>
          )}
        </TransitionGroup>
      </AnimationContainer>
    </RadioCardContainer>
  );
};

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  info: PropTypes.node,
  infoVariant: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  variant: PropTypes.string,
  addDataLabel: PropTypes.bool,
};

export { RadioGroup };
