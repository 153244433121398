import { variant, color, compose, space } from 'styled-system';
import styled from 'styled-components';
import css from '@styled-system/css';
import { typographyVariants } from './variants';

export const StyledTypography = styled.p(
  css({
    color: 'text',
    fontFamily: 'default',
    m: 'none',
  }),
  variant({
    variants: typographyVariants,
  }),
  compose(color, space)
);
