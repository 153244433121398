import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { formatTime } from '../../utils/formatTime';

import { Box } from '../Box';
import HoldCard from '../HoldCard';
import ProgressBar from '../ProgressBar';

const APPT_HOLD_TIME = 20;

const UserProgressionElements = ({ currentPage, isYAMS, langPref, progressBarStage }) => {
  const intl = useIntl();
  const [showHoldCard, setShowHoldCard] = useState(false);
  const [apptHoldTime, setApptHoldTime] = useState(null);
  const [progressBarSteps, setProgressBarSteps] = useState([]);

  useEffect(() => {
    setProgressBarSteps([
      intl.formatMessage({ id: `progressBar.step_one` }),
      intl.formatMessage({ id: `progressBar.step_two` }),
      intl.formatMessage({ id: `progressBar.step_three` }),
      intl.formatMessage({ id: `progressBar.step_four` }),
    ]);
  }, [intl, langPref]);

  useEffect(() => {
    if (currentPage > 5 && !isYAMS) {
      setShowHoldCard(true);
      if (apptHoldTime === null) {
        const time = new Date();
        time.setMinutes(time.getMinutes() + APPT_HOLD_TIME);
        setApptHoldTime(formatTime(time));
      }
    } else {
      setApptHoldTime(null);
      setShowHoldCard(false);
    }
  }, [currentPage, apptHoldTime, isYAMS]);

  return (
    <>
      <Box mb={['xxLarge', 'xxxxLarge']} display={currentPage === 0 && !isYAMS ? 'none' : 'block'}>
        <ProgressBar
          steps={progressBarSteps}
          activeStep={isYAMS ? progressBarSteps.length : progressBarStage}
        />
      </Box>
      {showHoldCard && <HoldCard time={apptHoldTime} />}
    </>
  );
};

UserProgressionElements.propTypes = {
  currentPage: PropTypes.number.isRequired,
  isYAMS: PropTypes.bool.isRequired,
  langPref: PropTypes.string.isRequired,
  progressBarStage: PropTypes.number.isRequired,
};

export default UserProgressionElements;
