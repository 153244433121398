import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { useClientStore } from '../../../../store/store';
import { vaccineLinks } from '../../../../utils/vaccineSpecifications/vaccineLinks';

import { FlexBox } from '../../../Box';
import Button from '../../../Button';
import { Checkbox } from '../../../Checkbox';
import { Content, Footer, Header } from '../../../ModalComposition';
import InfoByte from '../../../InfoByte';
import { L10n } from '../../../L10n';
import Link from '../../../Link';
import Modal from '../../../Modal';
import RequiredAsterisk from '../../../RequiredAsterisk';
import Typography from '../../../Typography';

const VaccineInformationModal = ({ handleConfirm, handleClose, vaccinesViewedInitial }) => {
  const { products } = useClientStore((state) => state.apptDetails);
  const intl = useIntl();

  const [vaccinesViewed, setVaccinesViewed] = useState(vaccinesViewedInitial);
  const [showError, setShowError] = useState(false);

  const L10nPref = (suffix, values) => (
    <L10n tokenID={`page.consent_ack.${suffix}`} values={{ ...values }} />
  );

  const handleConfirmClick = () => {
    if (vaccinesViewed) {
      handleConfirm();
    } else {
      setShowError(true);
    }
  };

  const handleCheckBoxClick = () => {
    if (!vaccinesViewed) {
      setShowError(false);
    }
    setVaccinesViewed(!vaccinesViewed);
  };

  return (
    <Modal
      onClose={handleClose}
      isMobileFullScreen
      header={
        <Header withClose handleClose={handleClose}>
          {L10nPref('vax_info')}
        </Header>
      }
      content={
        <Content>
          <FlexBox flexDirection="column" gridGap="xLarge">
            <FlexBox flexDirection="column" gridGap="medium">
              {showError ? (
                <InfoByte
                  alignment={['top', 'center']}
                  variant="error"
                  info={L10nPref('vax_info_error')}
                />
              ) : null}
              <Typography variant="primaryBase">{L10nPref('vax_info_text')}</Typography>
              {Object.keys(products).map((product) => (
                <Link key={product} variant="boldHighlight" href={vaccineLinks[product]} external>
                  <L10n tokenID={`vaccine.${product}`} />
                </Link>
              ))}
            </FlexBox>
            <FlexBox gridGap="large" onClick={handleCheckBoxClick} px="small">
              <FlexBox alignItems="center" m="auto 0" mt={['xSmall', 'auto']}>
                <Checkbox
                  id="vaccinesViewed"
                  checked={vaccinesViewed}
                  onChange={() => handleCheckBoxClick()}
                  label={intl.formatMessage({ id: 'page.consent_ack.vax_info_ack' })}
                />
              </FlexBox>
              <Typography tag="span" variant="primaryBase">
                {L10nPref('vax_info_ack')}
                <RequiredAsterisk variant="primaryBase" />
              </Typography>
            </FlexBox>
          </FlexBox>
        </Content>
      }
      footer={
        <Footer variant="sideBySide">
          <Button handleClick={handleClose} size={['hug', 'secondary']} styling="outlined">
            {L10nPref('close')}
          </Button>
          <Button handleClick={handleConfirmClick} size={['hug', 'secondary']}>
            {L10nPref('confirm')}
          </Button>
        </Footer>
      }
    />
  );
};

VaccineInformationModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  vaccinesViewedInitial: PropTypes.bool.isRequired,
};

export default VaccineInformationModal;
