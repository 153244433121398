export const removeSpecificVaccines = (
  productObjToModify, // { vaccineName: { vaccineInfo } }
  vaxCriteriaObj = null, // { vaccine: [vaccines, to, be, removed] }
  productsToRemoveObj = null // { vaccineToBeRemoved: true }
) => {
  const modifiedProdObj = { ...productObjToModify };
  Object.keys(productObjToModify).forEach((vaccine) => {
    if (productsToRemoveObj !== null && productsToRemoveObj[vaccine]) {
      delete modifiedProdObj[vaccine];
    }
    if (
      vaxCriteriaObj !== null &&
      vaxCriteriaObj[vaccine] &&
      (productsToRemoveObj === null || productsToRemoveObj[vaccine])
    ) {
      vaxCriteriaObj[vaccine].forEach((removedVaccine) => {
        delete modifiedProdObj[removedVaccine];
      });
    }
  });
  return modifiedProdObj;
};
