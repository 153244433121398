import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useClientStore } from '../../../store/store';

import Button from '../../Button';
import { Box } from '../../Box';
import { Content, Footer, Header } from '../../ModalComposition';
import Modal from '../../Modal';
import Typography from '../../Typography';
import { L10n } from '../../L10n';
import { medical, medicare, pharmacy } from '../../../Images/Cards';
import ImageContainer from '../../ImageContainer';

const InsuranceCardModal = ({ insuranceCard }) => {
  const { hideModal, modalOpen } = useClientStore((state) => ({
    modalOpen: state.modalState,
    hideModal: state.hideModal,
  }));

  const handleClose = () => {
    hideModal();
  };

  const insuranceCards = {
    medical: {
      image: medical,
      name: 'medical',
      description: <L10n tokenID="modal.insurance_cards.medical" />,
    },
    medicare: {
      image: medicare,
      name: 'Medicare Part B',
      description: <L10n tokenID="modal.insurance_cards.medicarePartB" />,
    },
    pharmacy: {
      image: pharmacy,
      name: 'pharmacy',
      description: <L10n tokenID="modal.insurance_cards.pharmacy" />,
    },
  };

  const intl = useIntl();

  return (
    <Modal
      gridColumns={['1/5', '4/10', '5/9']}
      canCloseOnClickOutside={false}
      open={modalOpen}
      onClose={handleClose}
      header={
        <Header withClose handleClose={handleClose}>
          {intl.formatMessage({ id: `modal.insurance_cards.${insuranceCard}_card` })}
        </Header>
      }
      content={
        <Content>
          <Box display="grid" gridGap="large">
            <Typography tag="p" variant={['primaryBase']}>
              {insuranceCards[insuranceCard].description}
            </Typography>
            <Box
              backgroundImage={`url(${insuranceCards[insuranceCard].image})`}
              backgroundPosition="center left"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            >
              <ImageContainer
                src={insuranceCards[insuranceCard].image}
                alt={`${insuranceCard}-card`}
              />
            </Box>
          </Box>
        </Content>
      }
      footer={
        <Footer variant="single">
          <Button handleClick={handleClose} size="fill">
            <L10n tokenID="general.ok" />
          </Button>
        </Footer>
      }
    />
  );
};

InsuranceCardModal.propTypes = {
  insuranceCard: PropTypes.oneOf(['medicare', 'medical', 'pharmacy']),
};

export default InsuranceCardModal;
