import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';
import { Box } from '../../components/Box';
import { L10n } from '../../components/L10n';
import { RadioGroup } from '../../components/Radio';
import { useClientStore } from '../../store/store';
import PageNavigation from '../../components/PageNavigation';
import Card from '../../components/Card';
import Link from '../../components/Link';
import { StyledSpan } from './styles';
import { vaccineLinks } from '../../utils/vaccineSpecifications/vaccineLinks';

const CovidManufacturerSelection = () => {
  const intl = useIntl();
  const { apptDetails, updateUiState, updateCovidManufacturer } = useClientStore((state) => ({
    apptDetails: state.apptDetails,
    updateUiState: state.updateUiState,
    updateCovidManufacturer: state.updateCovidManufacturer,
  }));

  const [inputState, setInputState] = useState({
    covidManufacturer: {
      value:
        apptDetails.products['COVID-19']?.selected?.Covid_Manufacturer__c.toLowerCase() || 'pfizer',
      hasError: false,
      error: '',
    },
  });

  const [covidManufacturer, setCovidManufacturer] = useState(
    apptDetails.products['COVID-19']?.selected?.Covid_Manufacturer__c.toLowerCase() || 'pfizer'
  );

  const radioOptions = {
    uniqueId: 'radio1',
    choices: [
      {
        label: <L10n tokenID="covid.pfizer" />,
        value: 'pfizer',
      },
      {
        label: <L10n tokenID="covid.moderna" />,
        value: 'moderna',
      },
      {
        label: <L10n tokenID="covid.novavax" />,
        value: 'novavax',
      },
    ],
  };

  const validateInput = (value, error = intl.formatMessage({ id: `page.covid.required` })) => {
    return (!value && { hasError: true, error }) || { hasError: false, error: null };
  };

  const handleRadioChange = (event) => {
    const { value } = event.target.value ? event.target : event.target.dataset;
    const { hasError, error } = validateInput(value);
    setInputState({
      ...inputState,
      covidManufacturer: {
        ...inputState.covidManufacturer,
        value,
        hasError,
        error,
      },
    });
    Object.values(apptDetails.products['COVID-19']?.manufacturers).forEach((manu) => {
      // sets covid manufacterer with manufacturer name and stores all manufacturer details in store
      if (manu.Covid_Manufacturer__c?.toLowerCase() === value) {
        setCovidManufacturer(value);
        updateCovidManufacturer(apptDetails.products['COVID-19']?.manufacturers[manu.Label__c]);
      }
    });
  };

  const handleNext = () => {
    if (!apptDetails.products['COVID-19']?.selected) {
      Object?.values(apptDetails.products['COVID-19']?.manufacturers).forEach((manu) => {
        // sets covid manufacterer with manufacturer name and stores all manufacturer details in store
        if (manu.Covid_Manufacturer__c?.toLowerCase() === 'pfizer') {
          updateCovidManufacturer(apptDetails.products['COVID-19']?.manufacturers[manu.Label__c]);
        }
      });
    }
    const { hasError, error } = validateInput(inputState.covidManufacturer.value);
    setInputState({
      ...inputState,
      covidManufacturer: {
        ...inputState.covidManufacturer,
        hasError,
        error,
      },
    });
    if (!hasError) {
      updateUiState('validPage', true);
    }
  };

  return (
    <>
      <RedirectHandler pageNumber={3} />
      <Box
        data-testid="covidManufacturer"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
      >
        <Box display="grid" gridGap="large">
          <Typography tag="h1" variant={['primaryXLarge', 'primaryWeb3']}>
            <L10n tokenID="page.covid.header" />
          </Typography>
        </Box>
        <Box display="grid" gridGap="xLarge">
          <RadioGroup
            label={<L10n tokenID="page.covid.radioLabel" />}
            options={radioOptions}
            onChange={handleRadioChange}
            selectedOption={covidManufacturer}
            name="covidManufacturer"
            error={inputState.covidManufacturer.error}
          />
          <Card variant="callOut" margin="none">
            <Typography tag="p" variant={['secondaryBase']}>
              <L10n tokenID="page.covid.novavax_title" />
            </Typography>
            <Typography tag="p" variant={['primaryMedium']}>
              <L10n tokenID="page.covid.novavax" />
            </Typography>
          </Card>
          <Card variant="callOut" margin="none">
            <Typography tag="p" variant={['secondaryBase']}>
              <L10n tokenID="page.covid.header" />
            </Typography>
            <Typography tag="p" variant={['primaryMedium']}>
              <L10n tokenID="page.covid.card_info" />
            </Typography>
            <StyledSpan>
              <Typography tag="p" variant={['primaryMedium']}>
                <Link href={vaccineLinks['COVID-19']} external variant="highlight">
                  {intl.formatMessage({ id: `page.covid.card_link` })}
                </Link>
              </Typography>
            </StyledSpan>
          </Card>
        </Box>
        <PageNavigation handleNext={handleNext} />
      </Box>
    </>
  );
};

export default CovidManufacturerSelection;
