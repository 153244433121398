import React from 'react';
import { useIntl } from 'react-intl';

import { useCreateAppointment } from '../../api/Appointment';

import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';
import PageNavigation from '../../components/PageNavigation';
import { Box } from '../../components/Box';
import Button from '../../components/Button';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import { useClientStore } from '../../store/store';
import List from '../../components/List';
import Card from '../../components/Card';
import CardSection from '../../components/CardSection';
import { L10n } from '../../components/L10n';
import { L10nProvider } from '../../components/L10nProvider';
import FormatDayOfWeek from '../../components/FormatDayOfWeek';
import FormatDate from '../../components/FormatDate';
import { useCreateSessionHold, useUpdateSessionHold } from '../../api/Session-hold';

const ReviewScreen = () => {
  const CURR_PAGE = 15;
  const intl = useIntl();
  const {
    langPref,
    patientInfo,
    selectedVaccines,
    updateUiState,
    hideModal,
    showModal,
    updateModal,
    apptDetails,
    updateApptDetails,
    updatePatientInfo,
    uiState,
  } = useClientStore((state) => ({
    patientInfo: state.patient,
    langPref: state.uiState.langPref,
    selectedVaccines: state.apptDetails.products,
    updateUiState: state.updateUiState,
    hideModal: state.hideModal,
    showModal: state.showModal,
    updateModal: state.updateModal,
    apptDetails: state.apptDetails,
    updateApptDetails: state.updateApptDetails,
    updatePatientInfo: state.updatePatientInfo,
    uiState: state.uiState,
  }));
  const createAppt = useCreateAppointment();
  const vaccineLabels = Object.keys(selectedVaccines).map((key) =>
    intl.formatMessage({ id: `vaccine.${key}` })
  );

  const title = <L10n tokenID="page.review_screen.header" />;
  const subtitle = <L10n tokenID="page.review_screen.subheader" />;
  const navigateToPatientInfo = () => {
    window.scrollTo(0, 0);
    updateUiState('currentPage', 6);
  };

  const handleChangeClick = (e, modalType) => {
    updateModal('changeAppointmentModal', { handleClose: hideModal, modal: modalType });
    showModal();
  };
  const hold = useUpdateSessionHold();
  const newHold = useCreateSessionHold();
  const handleChangeApptDateTime = (appt) => {
    Object.keys(appt).forEach((field) => updateApptDetails(field, appt[field]));
    hold.mutate();
    newHold.mutate(appt.Event_Session__c);
    hideModal();
  };

  const handleChangeDateTimeButtonClick = () => {
    updateModal('changeAppointmentDateTimeModal', {
      handleClose: hideModal,
      currApptData: {
        date: apptDetails.Appointment_Date__c,
        time: apptDetails.Appointment_Time__c,
        locationName: apptDetails.FSEvent__c.Name,
        event: apptDetails.FSEvent__c,
      },
      handleConfirm: handleChangeApptDateTime,
      filteredProducts: selectedVaccines,
      type: 'change',
    });
    showModal();
  };

  const handleAppointmentSuccess = (data) => {
    updateApptDetails('confirmationNum', data.data.records[0].Appointment_Hash__c);
    updatePatientInfo('Birthdate__c', data.data.records[0].Birthdate__c);
    updateUiState('isPrevAuth', true);
    updateUiState('validPage', true);
  };

  const handleNext = () => {
    createAppt.mutate(null, { onSuccess: handleAppointmentSuccess });
  };

  return (
    <>
      <RedirectHandler pageNumber={CURR_PAGE} />
      {uiState.currentPage === CURR_PAGE ? (
        <Box
          data-testid="review_screen"
          gridColumn={['1/5', '1 / 13', 'auto /span 13']}
          display="grid"
          gridGap="xLarge"
        >
          <Box display="grid" gridGap="large">
            <PageTitleSubtitle subtitle={subtitle} title={title} />
          </Box>
          <Card variant="muted" margin="0px">
            <Box display="grid" gridGap="xLarge">
              <CardSection
                hasTopLine={false}
                gridGap="xLarge"
                titleTag="span"
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography tag="h2" variant="secondaryBase">
                      <L10n tokenID="general.patient" />
                    </Typography>
                    <Button
                      handleClick={navigateToPatientInfo}
                      styling="plainBlue"
                      size="noPaddingTertiary"
                    >
                      <L10n tokenID="general.change" />
                    </Button>
                  </Box>
                }
                subtitle={
                  <>
                    <Typography tag="p" variant="primaryBase">
                      {`${patientInfo.First_Name__c} ${patientInfo.Last_Name__c}`}
                    </Typography>
                    <Typography tag="p" variant="primaryBase">
                      {`${patientInfo.Phone_Number__c}`}
                    </Typography>
                    <Typography tag="p" variant="primaryBase">
                      {`${patientInfo.Email_Address__c}`}
                    </Typography>
                  </>
                }
              />
              <CardSection
                titleTag="span"
                hasTopLine={false}
                gridGap="xLarge"
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography tag="h2" variant="secondaryBase">
                      <L10n tokenID="general.vaccines" />
                    </Typography>
                    <Button
                      handleClick={(e) => handleChangeClick(e, 'vaccine')}
                      styling="plainBlue"
                      size="noPaddingTertiary"
                    >
                      <L10n tokenID="general.change" />
                    </Button>
                  </Box>
                }
                subtitle={<List items={vaccineLabels} fontSize="10px" maxWidth="14px" />}
              />
              <CardSection
                titleTag="span"
                hasTopLine={false}
                gridGap="xLarge"
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography tag="h2" variant="secondaryBase">
                      <L10n tokenID="general.location" />
                    </Typography>
                    <Button
                      handleClick={(e) => handleChangeClick(e, 'location')}
                      styling="plainBlue"
                      size="noPaddingTertiary"
                    >
                      <L10n tokenID="general.change" />
                    </Button>
                  </Box>
                }
                subtitle={
                  <>
                    <p>{apptDetails.FSEvent__c.Name}</p>
                    {apptDetails.FSEvent__c.Location__r ? (
                      <>
                        <p>{apptDetails.FSEvent__c.Location__r.Street_One__c}</p>
                        <p>
                          {apptDetails.FSEvent__c.Location__r.Primary_City__c},{' '}
                          {apptDetails.FSEvent__c.Location__r.State_Text__c}{' '}
                          {apptDetails.FSEvent__c.Location__r.Postal_Code_Text__c}
                        </p>
                      </>
                    ) : (
                      <p>{apptDetails.FSEvent__c.Event_Address__c}</p>
                    )}
                  </>
                }
              />
              <CardSection
                titleTag="span"
                hasTopLine={false}
                hasBottomLine={false}
                gridGap="xLarge"
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography tag="h2" variant="secondaryBase">
                      <L10n tokenID="general.date_and_time" />
                    </Typography>
                    <Button
                      handleClick={handleChangeDateTimeButtonClick}
                      styling="plainBlue"
                      size="noPaddingTertiary"
                    >
                      <L10n tokenID="general.change" />
                    </Button>
                  </Box>
                }
                subtitle={
                  <>
                    <p>
                      <L10nProvider locale={langPref}>
                        <FormatDayOfWeek dateStr={apptDetails.Appointment_Date__c} format="long" />
                      </L10nProvider>
                      ,{' '}
                      <L10nProvider locale={langPref}>
                        <FormatDate dateStr={apptDetails.Appointment_Date__c} format="long" />
                      </L10nProvider>
                    </p>
                    <p>{apptDetails.Appointment_Time__c}</p>
                    {/* // TODO: format time */}
                  </>
                }
              />
            </Box>
          </Card>
        </Box>
      ) : null}
      <PageNavigation
        handleNext={handleNext}
        customNextButtonLabel={intl.formatMessage({ id: 'layout.book_appointment' })}
      />
    </>
  );
};

export default ReviewScreen;
