import React from 'react';
import PropTypes from 'prop-types';
import { StyledBodyContainer, StyledInnerGrid } from './styles';

const BodyContainer = ({
  children,
  gridColumn = ['1 / 5', '1/13', '3/11', '4/10'],
  fullHeight = false,
  paddingBottom = '48px',
  paddingTop = 'large',
  paddingLeft = '16px',
  paddingRight = '16px',
}) => {
  return (
    <StyledBodyContainer
      pb={paddingBottom}
      pt={paddingTop}
      pl={paddingLeft}
      pr={paddingRight}
      fullHeight={fullHeight}
      data-testid="body-container"
    >
      <StyledInnerGrid gridColumn={gridColumn}>{children}</StyledInnerGrid>
    </StyledBodyContainer>
  );
};

BodyContainer.propTypes = {
  children: PropTypes.element,
  fullHeight: PropTypes.bool,
  gridColumn: PropTypes.arrayOf(PropTypes.string),
  paddingBottom: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  paddingLeft: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  paddingRight: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  paddingTop: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

export default BodyContainer;
