import React from 'react';
import PropTypes from 'prop-types';

import { Box, FlexBox } from '../Box';
import Typography from '../Typography';

const PageTitleSubtitle = ({ title, subtitle, titleInlineElement, id }) => {
  return (
    <Box id={id}>
      <FlexBox justifyContent="space-between" gridGap="large">
        <Typography tag="h1" variant={['primaryXLarge', 'primaryWeb3']}>
          {title}
        </Typography>
        {titleInlineElement !== undefined ? titleInlineElement : null}
      </FlexBox>
      {subtitle && (
        <Typography variant="primaryBase" space={{ pt: 'large' }}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

PageTitleSubtitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  titleInlineElement: PropTypes.node,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
};

export default PageTitleSubtitle;
