import { useQuery } from 'react-query';
import { api } from './config/axiosConfig';

const getSession = async (eventId) => {
  const response = await api.request({
    url: `/sessions?id=${eventId}`,
    method: 'GET',
  });
  return response;
};
export const useSessionQuery = (eventId) => {
  return useQuery(
    { queryKey: ['session', eventId], queryFn: () => getSession(eventId) },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );
};
