import React from 'react';
import Typography from '../Typography';

const RequiredAsterisk = () => {
  return (
    // TODO: make this ADA compliant
    <Typography variant="errorSecondaryBase" tag="span">
      *
    </Typography>
  );
};

export default RequiredAsterisk;
