import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledLanguageSelection = styled.button(
  css({
    alignItems: 'center',
    background: 'none',
    border: 'none',
    color: 'buttercream',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'Montserrat',
    fontSize: 'small',
    fontWeight: 'bold',
    justifyContent: 'center',
    lineHeight: 'expanded',
    mr: ['large', 'xxLarge'],
    p: 'none',
    textAlign: 'left',
    textDecoration: 'underline',
  })
);
