import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, layout } from 'styled-system';

export const StyledDiv = styled.div(
  css({
    position: 'relative',
  }),
  compose(layout)
);
