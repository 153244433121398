import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Box, FlexBox } from '../../../Box';
import Button from '../../../Button';
import { Content, Footer, Header } from '../../../ModalComposition';
import { FieldGroup } from '../../../FieldGroup';
import { L10n } from '../../../L10n';
import Modal from '../../../Modal';
import Typography from '../../../Typography';
import { useClientStore } from '../../../../store/store';
import validationFunctions from '../../../../utils/validation';

const InformedConsentModal = ({ handleConfirm, handleClose, signatureInitial }) => {
  const intl = useIntl();
  const { uiState } = useClientStore((state) => ({
    uiState: state.uiState,
  }));
  const validators = {
    validateInput: validationFunctions.validateInput,
  };

  const errors = {
    Signature__c: intl.formatMessage(
      {
        id: 'general.field_error.required_error',
      },
      { field: intl.formatMessage({ id: 'page.consent_ack.your_signature' }) }
    ),
  };

  const L10nPref = (suffix, values) => (
    <L10n tokenID={`page.consent_ack.${suffix}`} values={{ ...values }} />
  );

  const [inputState, setInputState] = useState({
    Signature__c: { value: signatureInitial, hasError: false, error: '', node: null },
    date: {
      value: Intl.DateTimeFormat(uiState.langPref === 'en-us' ? 'en-US' : 'es')
        .format(new Date())
        .replace(/\//g, '-'),
      hasError: false,
      error: '',
      node: null,
    },
  });

  const validateInput = (field, label, target) => {
    return validators.validateInput(
      target,
      label,
      validators[field],
      inputState[field].value,
      errors[field]
    );
  };

  const handleConfirmClick = () => {
    let firstError = null;
    const currState = { ...inputState };
    document.querySelectorAll('input').forEach((target) => {
      const { id } = target;
      const label = target.labels[0].firstChild.firstChild.textContent;
      const { hasError, error } = validateInput(id, label, target);
      if (hasError) {
        firstError = !firstError ? target : firstError;
        currState[id] = {
          ...inputState[id],
          hasError,
          error,
          node: target,
        };
      }
    });
    setInputState({
      ...inputState,
      ...currState,
    });
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      handleConfirm(inputState.Signature__c.value);
    }
  };

  const handleInputChange = (event) => {
    setInputState({
      ...inputState,
      [event.target.id]: {
        value: event.target.value,
      },
    });
  };

  const handleBlur = (event) => {
    const { target } = event;
    const field = target.id;
    const label = target.labels[0].firstChild.firstChild.textContent;
    const { hasError, error } = validateInput(field, label, event.target);
    setInputState({
      ...inputState,
      [field]: {
        ...inputState[field],
        hasError,
        error,
        node: target,
      },
    });
  };

  const inputs = [
    {
      label: <L10n tokenID="page.consent_ack.your_signature" />,
      name: 'Signature__c',
      error: inputState.Signature__c.error,
      required: true,
      disabled: false,
    },
    {
      label: <L10n tokenID="page.consent_ack.date" />,
      name: 'date',
      variant: ['base', 'medium'],
      required: false,
      disabled: true,
    },
  ];

  return (
    <Modal
      onClose={handleClose}
      isMobileFullScreen
      header={
        <Header withClose handleClose={handleClose}>
          {L10nPref('informed_consent')}
        </Header>
      }
      content={
        <Content>
          <FlexBox flexDirection="column" gridGap="xLarge">
            <Box
              border="normal"
              borderColor="coldBrews.200"
              borderRadius="large"
              height={['30vh', '300px']}
              maxHeight="300px"
              overflowY="auto"
              p="large"
            >
              <Typography variant="primaryBase">
                {L10nPref('informed_consent_text', {
                  br: <br />,
                  bold: (str) => (
                    <Typography tag="span" variant="secondaryBase">
                      {str}
                    </Typography>
                  ),
                })}
              </Typography>
            </Box>
            <Typography variant="primaryBase">{L10nPref('informed_consent_detail')}</Typography>
            {inputs.map((input) => {
              const { label, name, required, error, variant, disabled } = input;
              return (
                <FieldGroup
                  onBlur={handleBlur}
                  onChange={handleInputChange}
                  key={name}
                  label={label}
                  required={required}
                  name={name}
                  error={error}
                  variant={variant}
                  disabled={disabled}
                  value={inputState[name].value}
                />
              );
            })}
          </FlexBox>
        </Content>
      }
      footer={
        <Footer variant="sideBySide">
          <Button handleClick={handleClose} size={['hug', 'secondary']} styling="outlined">
            {L10nPref('close')}
          </Button>
          <Button handleClick={handleConfirmClick} size={['hug', 'secondary']}>
            {L10nPref('confirm')}
          </Button>
        </Footer>
      }
    />
  );
};

InformedConsentModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  signatureInitial: PropTypes.string.isRequired,
};

export default InformedConsentModal;
