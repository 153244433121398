export const vaccinesNotGivenTogether = {
  chickenpox: ['shingles'],
  flu: ['senior flu'],
  'hepatitis A': ['hepatitis A/B'],
  'hepatitis B': ['hepatitis A/B'],
  'hepatitis A/B': ['hepatitis A', 'hepatitis B'],
  'senior flu': ['flu'],
  shingles: ['chickenpox'],
  'tetanus and diphtheria (Td)': ['tetanus, diphtheria, and pertussis (Tdap)'],
  'tetanus, diphtheria, and pertussis (Tdap)': ['tetanus and diphtheria (Td)'],
};
