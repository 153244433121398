import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
`;

const ringfull = keyframes`
    0% {
        stroke-dashoffset: 154;
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    50% {
        stroke-dashoffset: 0;
    }
    80% {
        stroke-dashoffset: -153;
        opacity: 1;
    }
    95% {
        transform: rotate(80deg);
    }
    100% {
        stroke-dashoffset: -154;
        opacity: 0;
    }
`;

const ringhalf = keyframes`
    0% {
        stroke-dashoffset: 178;
        opacity: 0;
    }
    10% {
        opacity: 0.4;
    }
    50% {
        stroke-dashoffset: 0;
    }
    80% {
        stroke-dashoffset: -177;
        opacity: 0.4;
    }
    95% {
        transform: rotate(80deg);
    }
    100% {
        stroke-dashoffset: -178;
        opacity: 0;
    }
`;

const ringdim = keyframes`
    0% {
        stroke-dashoffset: 193;
        opacity: 0;
    }
    10% {
        opacity: 0.2;
    }
    50% {
        stroke-dashoffset: 0;
    }
    80% {
        stroke-dashoffset: -192;
        opacity: 0.2;
    }
    95% {
        transform: rotate(80deg);
    }
    100% {
        stroke-dashoffset: -193;
        opacity: 0;
    }
`;

export const ProgressRingGroup = styled('g')`
  animation: ${rotate} 3s infinite linear;
  transform-origin: center center;
  transition-property: transform;
`;

export const ProgressRingFull = styled('path')`
  animation: ${ringfull} 2s infinite ease-in-out;
  stroke-dasharray: 154, 154;
  stroke-dashoffset: 154;
  transform-origin: center center;
`;

export const ProgressRingHalf = styled('path')`
  animation: ${ringhalf} 2s infinite ease-in-out;
  stroke-dasharray: 178, 178;
  stroke-dashoffset: 178;
  transform-origin: center center;
`;

export const ProgressRingDim = styled('path')`
  animation: ${ringdim} 2s infinite ease-in-out;
  stroke-dasharray: 193, 193;
  stroke-dashoffset: 193;
  transform-origin: center center;
`;

export const StyledSvg = styled.svg`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
