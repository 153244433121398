import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useClientStore } from '../../store/store';
import { routeList } from '../../routes/routeList';

const RedirectHandler = ({ pageNumber }) => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get('eventId');
  const { currentPage } = useClientStore((state) => state.uiState);

  const navigate = useNavigate();
  useEffect(() => {
    if (routeList[currentPage].path !== routeList[pageNumber].path) {
      const path = eventId
        ? `${routeList[currentPage].path}?eventId=${eventId}`
        : routeList[currentPage].path;
      navigate(path, { replace: true });
    }
  }, [currentPage, pageNumber, navigate, eventId]);
  return null;
};

RedirectHandler.propTypes = {
  pageNumber: PropTypes.number.isRequired,
};

export default RedirectHandler;
