import { typographyVariants } from '../Typography/variants';

// size variants

const primary = {
  fontSize: 'medium',
  lineHeight: 'normal',
  padding: '8.5px 20.5px',
  minWidth: ['100%', '332px'],
  width: 'auto',
};

const secondary = {
  fontSize: 'medium',
  lineHeight: 'normal',
  padding: '8.5px 20.5px',
  minWidth: ['184px'],
  width: 'auto',
};

const tertiary = {
  fontSize: 'small',
  lineHeight: 'normal',
  py: 'xxSmall',
  px: '35px',
  minWidth: ['136px'],
  width: 'auto',
};

const tertiaryFill = {
  ...typographyVariants.secondaryMedium,
  px: 'none',
  py: 'xxSmall',
  width: '100%',
};

const small = {
  ...tertiary,
  borderColor: 'transparent',
  padding: 'none',
  width: 'auto',
};

const hug = {
  ...primary,
  width: 'auto',
  minWidth: 'auto',
};

const fill = {
  ...primary,
  minWidth: '100%',
};

const square = {
  borderRadius: 'square',
  borderWidth: '0',
  fontSize: '10px',
  py: '7px',
  width: '100%',
};

const circular = {
  borderRadius: 'circular',
  display: 'block',
  py: '7px',
};

const noPadding = {
  ...primary,
  padding: '0',
  border: '0',
  minWidth: '0px',
};

const noPaddingTertiary = {
  ...tertiary,
  padding: '0',
  border: '0',
  minWidth: '0px',
};

// styling variants

const base = {
  alignItems: 'center',
  bg: 'rxInteractionBlue',
  border: 'solid',
  borderColor: 'transparent',
  borderRadius: 'circular',
  borderStyle: 'solid',
  color: 'buttercream',
  cursor: 'pointer',
  display: 'inline-flex',
  fontFamily: 'default',
  fontWeight: 'black',
  justifyContent: 'center',
  lineHeight: 'condensed',
  '&:hover': {
    bg: [null, 'rxBrandBlue'],
  },
};

const outlined = {
  bg: 'buttercream',
  border: 'solidBlue',
  color: 'rxInteractionBlue',
  '&:hover': {
    bg: [null, 'rxInteractionBlue'],
    borderColor: [null, 'rxInteractionBlue'],
    color: [null, 'buttercream'],
  },
};

const outlinedMinor = {
  ...outlined,
  ...typographyVariants.primaryMedium,
  border: 'solidBlueNormal',
  color: 'text',
  '&:hover': {
    bg: [null, 'rxInteractionBlue'],
    border: [null, 'solidBlueNormal'],
    color: [null, 'buttercream'],
  },
};

const mutedBlue = {
  bg: 'rxColors.rxInteraction50',
  color: 'rxInteractionBlue',
  '&:hover': {
    bg: [null, 'rxInteractionBlue'],
    color: [null, 'buttercream'],
  },
};

const mutedColdbrew = {
  bg: 'rxColors.rxInteraction50',
  color: 'text',
  '&:hover': {
    bg: [null, 'rxInteractionBlue'],
    color: [null, 'buttercream'],
  },
};

const plainBlue = {
  bg: 'buttercream',
  color: 'rxInteractionBlue',
  '&:hover': {
    bg: [null, 'buttercream'],
    color: [null, 'rxBrandBlue'],
  },
};

const plainColdbrew = {
  bg: 'buttercream',
  color: 'text',
  borderColor: 'coldBrews.200',
  '&:hover': {
    bg: [null, 'buttercream'],
    color: [null, 'rxInteractionBlue'],
    borderColor: [null, 'rxInteractionBlue'],
  },
};

const disableActive = {
  bg: 'coldBrews.600',
  color: 'buttercream',
  '&:hover': {
    bg: 'coldBrews.900',
  },
};

const disable = {
  bg: 'coldBrews.50',
  color: 'text',
  borderColor: 'coldBrews.200',
  ...typographyVariants.primaryMedium,
  '&:hover': {
    bg: 'coldBrews.200',
  },
};

const transparent = {
  ...plainBlue,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  backgroundColor: 'transparent',
};

const success = {
  bg: 'hatch',
};

const link = {
  ...typographyVariants.primaryMedium,
  bg: 'transparent',
  border: 'none',
  color: 'highlight',
  textDecoration: 'underline',
};

export const sizeVariants = {
  primary,
  secondary,
  tertiary,
  tertiaryFill,
  small,
  hug,
  fill,
  square,
  circular,
  noPadding,
  noPaddingTertiary,
};

export const stylingVariants = {
  base,
  disable,
  disableActive,
  link,
  outlined,
  outlinedMinor,
  mutedBlue,
  mutedColdbrew,
  plainBlue,
  plainColdbrew,
  transparent,
  success,
};
