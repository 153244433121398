import {
  borders,
  breakpoints,
  colors,
  radii,
  shadows,
  sizes,
  space,
  typography,
  zIndices,
} from './keys';

const defaultTheme = {
  borders: borders.borders,
  borderStyles: borders.borderStyles,
  borderWidths: borders.borderWidths,
  breakpoints,
  colors,
  fonts: typography.fonts,
  fontSizes: typography.fontSizes,
  fontWeights: typography.fontWeights,
  lineHeights: typography.lineHeights,
  radii,
  shadows,
  sizes,
  space,
  zIndices,
};

export default defaultTheme;
