const radii = [0, 4, 8, 12, 16, 24, 999];

// eslint-disable-next-line prettier/prettier
[
  radii.none, // 0
  radii.xSmall, // 4
  radii.small, // 8
  radii.medium, // 12
  radii.large, // 16
  radii.xLarge, // 24
] = radii;

radii.circular = radii['6'];
radii.square = '10px';

export default radii;
