export const formatTime = (dateStr) => {
  return dateStr
    .toLocaleTimeString([], {
      timeStyle: 'short',
    })
    .replace('AM', 'am')
    .replace('a. m.', 'am')
    .replace('PM', 'pm')
    .replace('p. m.', 'pm');
};
