import React from 'react';
import PropTypes from 'prop-types';
import { StyledRibbon, StyledRibbonContent, StyledIcon } from './styles';
import { FlexBox } from '../Box';
import Icon from '../Icon';
import FilterSearch from '../FilterSearch';

const FilterRibbon = ({
  expanded = false,
  setExpanded,
  icon,
  iconScale = [1],
  ribbonContent,
  action,
}) => {
  const isMobile = window.screen.width < 767;
  return (
    <StyledRibbon
      data-testid="ribbon"
      display={['grid', 'flex']}
      width={['100vw', '100%']}
      mx={['-16px', 'auto']}
      padding={['large', 'medium']}
      minHeight={['52px', '68px']}
      maxHeight={[null, '68px']}
      variant={['border', 'noBorder']}
    >
      <StyledRibbonContent width={['auto', '100%']} variant={[null, 'desktopLayout']}>
        <FlexBox alignItems="center" gridGap="medium">
          {icon && (
            <StyledIcon>
              <Icon icon={icon} scale={iconScale} styling="plainColdbrew" />
            </StyledIcon>
          )}
          {ribbonContent}
        </FlexBox>
        {!isMobile && <FilterSearch expanded={expanded} setExpanded={setExpanded} />}
        {action}
      </StyledRibbonContent>
      {isMobile && <FilterSearch expanded={expanded} setExpanded={setExpanded} />}
    </StyledRibbon>
  );
};

FilterRibbon.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  icon: PropTypes.string.isRequired,
  iconScale: PropTypes.arrayOf(PropTypes.number),
  ribbonContent: PropTypes.element,
  action: PropTypes.node,
};

export default FilterRibbon;
