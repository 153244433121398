const size = [0, 2, 4, 6, 8, 12, 16, 24, 32, 40];

[
  size.none, // 0
  size.xxSmall, // 2
  size.xSmall, // 4
  size.small, // 6
  size.medium, // 8
  size.large, // 12
  size.xLarge, // 16
  size.xxLarge, // 24
  size.xxxLarge, // 32
  size.xxxxLarge, // 40
] = size;

export default size;
