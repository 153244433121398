import styled from 'styled-components';
import css from '@styled-system/css';

export const Button = styled.button(
  css({
    border: 'none',
    bg: 'transparent',
    p: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  })
);
