import styled from 'styled-components';
import {
  compose,
  typography,
  border,
  background,
  space,
  flexbox,
  grid,
  color,
  layout,
  shadow,
  position,
  zIndex,
} from 'styled-system';

/** Base Box component */
export const Box = styled.div`
  ${compose(
    shadow,
    border,
    background,
    color,
    typography,
    space,
    flexbox,
    grid,
    layout,
    position,
    zIndex
  )}
`;

/** Base FlexBox component */
export const FlexBox = styled(Box)`
  display: flex;
`;
