import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import RedirectHandler from '../../components/RedirectHandler';
import PageNavigation from '../../components/PageNavigation';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Card from '../../components/Card';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import Icon from '../../components/Icon';
import InfoByte from '../../components/InfoByte';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import { Box } from '../../components/Box';
import { L10n } from '../../components/L10n';
import { StyledSpan } from './styles';
import Link from '../../components/Link';
import List from '../../components/List';
import { useClientStore } from '../../store/store';
import { useProductQuery } from '../../api/Product';
import { pregnancyRestrictedVaccines } from '../../utils/vaccineSpecifications/pregnancyRestrictedVaccines';

import { VaccineCheckboxList, updateVaccineCheckboxList } from '../../utils/vaccineCheckboxList';
import {
  MAX_PRODUCTS_IN_APPT,
  MIN_PRODUCTS_FOR_UPSELL,
  MIN_AGE_FOR_VACCINES,
  OTHER_VACCINE_MIN_AGE,
  AGE_FOR_ADULT_VACCINES,
  vaccinesNotGivenTogether,
  vaccineAgeRestrictions,
} from '../../utils/vaccineSpecifications';
import { removeSpecificVaccines } from '../../utils/removeSpecificVaccines';
import { groupFluCovidVaccines } from '../../utils/grouping/groupFluCovidVaccines';

const VaccineSelection = () => {
  const {
    getPatientAge,
    updateApptDetails,
    updateUiState,
    selectedProducts,
    UrlSpecifiedEvent,
    showModal,
    updateCovidManufacturer,
    updateModal,
  } = useClientStore((state) => ({
    getPatientAge: state.patient.Age,
    updateApptDetails: state.updateApptDetails,
    updateUiState: state.updateUiState,
    selectedProducts: state.apptDetails.products,
    UrlSpecifiedEvent: state.apptDetails.UrlSpecifiedEvent,
    showModal: state.showModal,
    updateModal: state.updateModal,
    updateCovidManufacturer: state.updateCovidManufacturer,
  }));
  const { data, isLoading } = useProductQuery();
  const products = data?.data.records || [];

  const intl = useIntl();
  const [showMore, setShowMore] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [checkboxGroupData, setCheckboxGroupData] = useState({});

  const listItems = [
    intl.formatMessage({ id: `page.vaccineSelection.listItemOne` }),
    intl.formatMessage({ id: `page.vaccineSelection.listItemTwo` }),
    intl.formatMessage({ id: `page.vaccineSelection.listItemThree` }),
    intl.formatMessage({ id: `page.vaccineSelection.listItemFour` }),
  ];

  const handleVaccineList = () => {
    setShowMore((current) => !current);
  };

  const populateCheckboxSelection = (checkboxObj, selectedList) => {
    // on load sets states for vaccine checkboxes
    let checkboxClone = { ...checkboxObj };
    selectedList.forEach((selected) => {
      checkboxClone = updateVaccineCheckboxList(true, selected, checkboxClone);
    });
    return checkboxClone;
  };

  const filterByUrlEvent = (productsToFilter) => {
    if (UrlSpecifiedEvent) {
      const eventOfferings = UrlSpecifiedEvent.products;
      if (eventOfferings) {
        return productsToFilter.filter((product) => eventOfferings.includes(product.Id));
      }
      return []; // no products offered at url event
    }
    return productsToFilter;
  };

  const groupedVax = VaccineCheckboxList(
    Object.values(
      // filters products by age, then groups covid vaccines into one option, also sets disabled options
      removeSpecificVaccines(
        groupFluCovidVaccines(
          filterByUrlEvent(vaccineAgeRestrictions(getPatientAge, Object.values(products)))
        )
      ),
      vaccinesNotGivenTogether
    )
  );

  if (data && Object.keys(checkboxGroupData).length === 0) {
    const vaccines = groupedVax;
    // Filter selected products by available vaccines
    const selected = {};
    Object.keys(selectedProducts).forEach((vax) => {
      const translation = intl.formatMessage({ id: `vaccine.${vax}` });
      if (vaccines[translation]) {
        selected[translation] = selectedProducts[vax];
      }
    });
    setCheckboxGroupData({
      ...checkboxGroupData,
      ...populateCheckboxSelection(vaccines, Object.keys(selected)),
    });
  }

  const afterModalCloses = () => {
    updateUiState('validPage', true);
  };

  const handleCheckboxChange = (index, value, e) => {
    setShowErrorMsg(false);
    setCheckboxGroupData(
      updateVaccineCheckboxList(
        value,
        e.currentTarget.getAttribute('data-label'),
        checkboxGroupData
      )
    );
  };

  const handleNext = () => {
    updateUiState('showCovidManufacturer', false);
    setShowErrorMsg(false);
    const selectedVax = {};
    if (checkboxGroupData) {
      Object.values(checkboxGroupData).forEach((vaccine) => {
        if (checkboxGroupData[vaccine.label].checked) {
          selectedVax[vaccine.Label__c] = checkboxGroupData[vaccine.label];
        }
      });
    }
    if (Object.keys(selectedVax).length > 0) {
      updateApptDetails('products', { ...selectedVax });
      const hasRestricted = Object.keys(selectedVax).some(
        (vax) => vax in pregnancyRestrictedVaccines
      );
      updateUiState('showIsPregnant', hasRestricted);
      if (
        Object.keys(selectedVax).length < MAX_PRODUCTS_IN_APPT &&
        Object.keys(groupedVax).length >= MIN_PRODUCTS_FOR_UPSELL &&
        Object.keys(groupedVax).length !== Object.keys(selectedVax).length
      ) {
        updateModal('upsellModal', {
          handleModalClose: afterModalCloses,
          productData: filterByUrlEvent(vaccineAgeRestrictions(getPatientAge, products)),
          showIsPregnantScreenInitial: hasRestricted,
        });
        showModal();
      } else {
        updateUiState('validPage', true);
      }
      if (Object.keys(selectedVax).includes('COVID-19')) {
        const c19 = selectedVax['COVID-19'];
        if (Object.keys(c19.manufacturers).length > 1) {
          updateUiState('showCovidManufacturer', true);
        } else {
          const manufacturerKey = Object.keys(c19.manufacturers)[0];
          updateCovidManufacturer(c19.manufacturers[manufacturerKey]);
        }
      }
    } else {
      setShowErrorMsg(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const PageContent = () => {
    if (checkboxGroupData && Object.keys(checkboxGroupData).length === 0) {
      return (
        <InfoByte
          info={<L10n tokenID="page.vaccineSelection.noVaccinesMessage" />}
          variant="error"
        />
      );
    }
    return (
      <>
        {Object.keys(groupedVax).length > 5 && (
          <Button
            handleClick={handleVaccineList}
            styling="plainBlue"
            size="noPadding"
            flexbox={{ justifyContent: 'left', textAlign: 'left' }}
            EndIcon={
              showMore ? (
                <Icon icon="expandLess" styling="base" />
              ) : (
                <Icon icon="expandMore" styling="base" />
              )
            }
          >
            <Typography tag="p" variant={['secondaryBase']} color={{ color: 'rxInteractionBlue' }}>
              {showMore
                ? intl.formatMessage({ id: `page.vaccineSelection.showLessButton` })
                : intl.formatMessage({ id: `page.vaccineSelection.showAllButton` })}
            </Typography>
          </Button>
        )}
        {/* about your vaccines card: 3 and older */}
        <Card variant="callOut" margin="none">
          <>
            <Typography tag="p" variant={['secondaryBase']}>
              <L10n tokenID="page.vaccineSelection.cardHeader" />
            </Typography>
            <Typography tag="p" variant={['primaryMedium']}>
              <L10n tokenID="page.vaccineSelection.cardInfo" />
            </Typography>
            <StyledSpan>
              <Typography tag="p" variant={['primaryMedium']}>
                <Link href="https://www.heb.com/pharmacy/vaccinations" external variant="highlight">
                  {intl.formatMessage({ id: `page.vaccineSelection.cardLink` })}
                </Link>
              </Typography>
            </StyledSpan>
          </>
        </Card>
        {/* Vaccines for travelers card:  19 and older */}
        {getPatientAge > AGE_FOR_ADULT_VACCINES && (
          <Card variant="callOut" margin="none">
            <>
              <Typography tag="p" variant={['secondaryBase']}>
                <L10n tokenID="page.vaccineSelection.cardHeaderTwo" />
              </Typography>
              <Typography tag="p" variant={['primaryMedium']}>
                <L10n tokenID="page.vaccineSelection.cardInfoTwo" />
              </Typography>
              <StyledSpan>
                <Typography tag="p" variant={['primaryMedium']}>
                  <Link href="https://www.heb.com/store-locations" external variant="highlight">
                    {intl.formatMessage({ id: `page.vaccineSelection.cardLinkTwo` })}
                  </Link>
                </Typography>
              </StyledSpan>
            </>
          </Card>
        )}
        {/* Looking for other vaccines card variant 1: 3 - 13 years old */}
        {getPatientAge < OTHER_VACCINE_MIN_AGE && getPatientAge >= MIN_AGE_FOR_VACCINES && (
          <Card variant="callOut" margin="none">
            <>
              <Typography tag="p" variant={['secondaryBase']}>
                <L10n tokenID="page.vaccineSelection.cardHeaderThree" />
              </Typography>
              <Typography tag="p" variant={['primaryMedium']}>
                <L10n tokenID="page.vaccineSelection.cardInfoThree" />
              </Typography>
            </>
          </Card>
        )}
        {/* Looking for other vaccines card variant 2: 14 - 18 years old */}
        {getPatientAge >= OTHER_VACCINE_MIN_AGE && getPatientAge <= AGE_FOR_ADULT_VACCINES && (
          <Card variant="callOut" margin="none">
            <>
              <Typography tag="p" variant={['secondaryBase']}>
                <L10n tokenID="page.vaccineSelection.cardHeaderThree" />
              </Typography>
              <Typography tag="p" variant={['primaryMedium']}>
                <L10n tokenID="page.vaccineSelection.cardInfoFour" />
              </Typography>
              <StyledSpan>
                <List items={listItems} textVariant="primaryMedium" />
              </StyledSpan>
              <StyledSpan>
                <Typography tag="p" variant={['primaryMedium']}>
                  <Link href="https://www.heb.com/store-locations" external variant="highlight">
                    {intl.formatMessage({ id: `page.vaccineSelection.cardLinkTwo` })}
                  </Link>
                </Typography>
              </StyledSpan>
            </>
          </Card>
        )}
      </>
    );
  };

  return (
    <>
      <RedirectHandler pageNumber={1} />
      <Box
        data-testid="vaccineSelection"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="large"
      >
        {showErrorMsg && (
          <InfoByte variant="error" info={<L10n tokenID="page.vaccineSelection.error" />} />
        )}
        <Box display="grid" gridGap="xLarge">
          <PageTitleSubtitle
            title={<L10n tokenID="page.vaccineSelection.header" />}
            id="vaccineSelectionPageTitle"
            subtitle={
              <>
                <>
                  <L10n tokenID="page.vaccineSelection.subheader" />
                  <StyledSpan>
                    <L10n tokenID="page.vaccineSelection.subheaderTwo" />
                  </StyledSpan>
                </>
              </>
            }
          />
          {isLoading ? null : (
            <>
              <CheckboxGroup
                options={
                  (checkboxGroupData &&
                    Object.values(checkboxGroupData).slice(0, showMore ? groupedVax.length : 6)) ||
                  []
                }
                onChange={handleCheckboxChange}
                describedById="vaccineSelectionPageTitle"
              />
              <PageContent />
            </>
          )}
        </Box>
      </Box>
      <PageNavigation
        hideNext={false}
        handleNext={handleNext}
        disableNext={checkboxGroupData && Object.keys(checkboxGroupData).length === 0}
      />
    </>
  );
};
export default VaccineSelection;
