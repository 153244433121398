import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, FlexBox } from '../../Box';
import FilterButton from '../../FilterButton';
import { L10n } from '../../L10n';
import { RadioGroup } from '../../Radio';
import Shimmer from '../../Shimmer';
import Typography from '../../Typography';

const TIMES = ['Morning', 'Afternoon', 'Evening'];

const TimeFilters = ({
  selectedTime,
  currDate,
  handleTimeSelection,
  timeslots,
  isLoading,
  displayTimeslotHold = false,
}) => {
  const [activeTimeFilter, setActiveTimeFilter] = useState(
    TIMES.find((slot) => timeslots[slot].choices.length > 0)
  );

  useEffect(() => {
    const currFilter = TIMES.find((slot) => timeslots[slot].choices.length > 0);
    setActiveTimeFilter(currFilter);
  }, [currDate, timeslots]);

  const handleChange = (e) => {
    const startTime = e.target.dataset.label.split(' -')[0];
    handleTimeSelection({
      Event_Session__c: e.target.value,
      Appointment_Time__c: startTime,
      Appointment_Date__c: currDate,
    });
  };

  return (
    <FlexBox
      flexDirection="column"
      flex="1 1 auto"
      gridGap={['20px', 'large']}
      height="100%"
      overflowY="hidden"
      mt="small"
    >
      <Box>
        {isLoading ? (
          <Shimmer height="xxxLarge" space={{ mb: ['none', 'small'] }} width="120px" />
        ) : (
          <Typography variant="secondaryBase" space={{ pb: ['none', 'small'] }}>
            <L10n tokenID="modal.dateTimeSelection.select_a_time" />
          </Typography>
        )}
        <Box display={displayTimeslotHold ? ['none', 'block'] : 'none'}>
          {isLoading ? (
            <Shimmer height="20px" width="500px" />
          ) : (
            <Typography variant="primaryMedium">
              <L10n tokenID="modal.dateTimeSelection.timeslot_hold" />
            </Typography>
          )}
        </Box>
      </Box>
      <FlexBox flexDirection="row" gridGap="small">
        {isLoading
          ? [...Array(3)].map((_, i) => (
              <FilterButton
                loading
                key={i} // eslint-disable-line react/no-array-index-key
                activeFilter=""
                filterName=""
                handleClick={() => {}}
              />
            ))
          : Object.entries(timeslots).map(([key, value]) => (
              <FilterButton
                loading={false}
                key={key}
                activeFilter={activeTimeFilter}
                filterName={value.id}
                type={value.choices.length > 0 ? 'available' : 'unavailable'}
                handleClick={() => setActiveTimeFilter(value.id)}
              >
                {value.children}
              </FilterButton>
            ))}
      </FlexBox>
      {isLoading ? null : (
        <TimeslotDisplay
          timeslots={timeslots}
          activeTimeFilter={activeTimeFilter}
          handleChange={handleChange}
          selectedTime={selectedTime}
        />
      )}
    </FlexBox>
  );
};

TimeFilters.propTypes = {
  currDate: PropTypes.string.isRequired,
  displayTimeslotHold: PropTypes.bool,
  handleTimeSelection: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  timeslots: PropTypes.shape({
    children: PropTypes.string,
    choices: PropTypes.arrayOf([
      PropTypes.shape({
        attributes: PropTypes.shape({
          type: PropTypes.string,
          url: PropTypes.string,
        }),
        Count_of_Appointments__c: PropTypes.number,
        End_Date_Time__c: PropTypes.string,
        Event__c: PropTypes.string,
        Id: PropTypes.string,
        Is_Internal__c: PropTypes.bool,
        Is_Private__c: PropTypes.bool,
        label: PropTypes.string,
        Max_Attendees__c: PropTypes.number,
        Name: PropTypes.string,
        Session_Full__c: PropTypes.bool,
        Start_Date_Time__c: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
    id: PropTypes.string,
  }),
  selectedTime: PropTypes.string.isRequired,
};

const TimeslotDisplay = ({ timeslots, activeTimeFilter, handleChange, selectedTime }) => {
  return timeslots[activeTimeFilter].choices.length > 0 ? (
    <Box>
      <RadioGroup
        label={<L10n tokenID="modal.dateTimeSelection.select_a_time" />}
        showLabel={false}
        options={{
          uniqueId: 'dateTimeSelection',
          choices: timeslots[activeTimeFilter].choices,
        }}
        addDataLabel
        onChange={handleChange}
        selectedOption={selectedTime}
        name="dateTimeSelection"
        variant="simple"
        required
      />
    </Box>
  ) : (
    <Box>
      <Typography variant="primaryMedium" space={{ pb: 'medium', pt: 'xxSmall' }}>
        <L10n tokenID="modal.dateTimeSelection.no_avail_appts" />
      </Typography>
      <Typography variant="primaryMedium">
        <L10n tokenID="modal.dateTimeSelection.choose_diff_time" />
      </Typography>
    </Box>
  );
};

TimeslotDisplay.propTypes = {
  activeTimeFilter: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  timeslots: PropTypes.shape({
    children: PropTypes.string,
    choices: PropTypes.arrayOf([
      PropTypes.shape({
        attributes: PropTypes.shape({
          type: PropTypes.string,
          url: PropTypes.string,
        }),
        Count_of_Appointments__c: PropTypes.number,
        End_Date_Time__c: PropTypes.string,
        Event__c: PropTypes.string,
        Id: PropTypes.string,
        Is_Internal__c: PropTypes.bool,
        Is_Private__c: PropTypes.bool,
        label: PropTypes.string,
        Max_Attendees__c: PropTypes.number,
        Name: PropTypes.string,
        Session_Full__c: PropTypes.bool,
        Start_Date_Time__c: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
    id: PropTypes.string,
  }),
  selectedTime: PropTypes.string.isRequired,
};

export default TimeFilters;
