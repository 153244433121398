// Defines ordered list of routes. Used by PageNavigation
export const routeList = [
  { path: '/dob' },
  { path: '/select-vaccines' },
  { path: '/is-pregnant', showPageKey: 'showIsPregnant' },
  { path: '/covid-manufacturer', showPageKey: 'showCovidManufacturer' },
  { path: '/search-location', showPageKey: 'showLocationSearch' },
  { path: '/select-location' },
  { path: '/patient-info' },
  { path: '/clinical-info' },
  { path: '/insurance-info' },
  { path: '/medicare-part-b', showPageKey: 'showMedicarePartB' },
  { path: '/medicare-part-c', showPageKey: 'showMedicarePartC' },
  { path: '/medicare-part-d', showPageKey: 'showMedicarePartD' },
  { path: '/pharmacy-medical', showPageKey: 'showPharmacyMedical' },
  {
    path: '/medicare-statement',
    showPageKey: 'showMedicareStatement',
  },
  { path: '/patient-consent' },
  { path: '/review-appointment' },
  { path: '/manage-appointment' },
];
