const validateInsuranceQuestion = (value, error) => {
  if (value === '') {
    return { hasError: true, error };
  }
  return { hasError: false, error: null };
};

const validateInsuranceTypes = (value, errorMsg) => {
  let error = true;
  let errorMessage = errorMsg;
  // eslint-disable-next-line
  value.forEach((e) => {
    if (e.checked) {
      error = false;
      errorMessage = '';
    }
  });
  return { hasError: error, error: errorMessage };
};

export const validators = {
  pharmacyInsuranceQuestion: validateInsuranceQuestion,
  insuranceTypesQuestion: validateInsuranceTypes,
};
