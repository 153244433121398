import themes from '../../themes';

export const rings = {
  full: {
    d:
      'M21.8259 71.0797C16.8309 66.9896 13.0646 61.5976 10.9433 55.5002C8.82203 49.4027 8.42856 42.8373 9.80641 36.5302C11.1843 30.223 14.2797 24.4198 18.7505 19.7624C23.2212 15.105 28.893 11.7749 35.1386 10.1404C41.3842 8.50583 47.9601 8.63058 54.1392 10.5008C60.3183 12.371 65.8597 15.9139 70.1506 20.7375C74.4415 25.5611 77.3146 31.4775 78.4523 37.8324C79.5899 44.1873 78.9478 50.733 76.5968 56.7456',
    stroke: themes.colors.buttercream,
    strokeWidth: '4',
    strokeLinecap: 'round',
  },
  half: {
    d:
      'M21.7788 71.0411C16.0219 66.3102 11.925 59.8656 10.0845 52.6451C8.24397 45.4246 8.7559 37.8051 11.5458 30.8957C14.3356 23.9863 19.2578 18.1476 25.5959 14.2294C31.934 10.3112 39.3571 8.51808 46.7849 9.11097C54.2126 9.70386 61.2573 12.6518 66.8936 17.5258C72.5299 22.3998 76.4635 28.9454 78.122 36.2098C79.7805 43.4743 79.0773 51.0785 76.1147 57.9156C73.1521 64.7528 68.0848 70.4659 61.6503 74.2236',
    stroke: themes.colors.buttercream,
    strokeWidth: '4',
    strokeLinecap: 'round',
  },
  dim: {
    d:
      'M21.4207 70.7427C15.2336 65.5189 11.0492 58.3114 9.58053 50.3482C8.11183 42.3851 9.44968 34.1591 13.3661 27.0718C17.2825 19.9845 23.5352 14.4744 31.0588 11.4804C38.5824 8.48637 46.9114 8.19368 54.6266 10.6522C62.3418 13.1107 68.9658 18.1683 73.3701 24.9632C77.7743 31.7581 79.6863 39.87 78.7802 47.9166C77.8741 55.9631 74.2059 63.4466 68.4008 69.0918C62.5957 74.7371 55.0127 78.1949 46.944 78.876',
    stroke: themes.colors.buttercream,
    strokeWidth: '4',
    strokeLinecap: 'round',
  },
  logo: {
    d:
      'M47.7859 47.9184L47.9358 57.1818L58.3188 57.1623L59.8182 30.8182H47.5047L47.6734 42.6027H40.3266L40.4765 30.8182H28.1818L29.7187 57.1818H40.0829L40.2141 47.9184H47.7859Z',
    fill: themes.colors.buttercream,
  },
};
