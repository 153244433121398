import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FlexBox } from '../../Box';

const Content = forwardRef(({ children, heightOffset = null, px = 'xLarge' }, ref) => {
  return (
    <FlexBox
      bg="buttercream"
      px={px}
      flexDirection="column"
      maxHeight={heightOffset ? `calc(100% - ${heightOffset})` : 'auto'}
      minHeight={heightOffset ? `calc(100% - ${heightOffset})` : 'auto'}
      overflowY="auto"
      ref={ref}
    >
      {children}
    </FlexBox>
  );
});

Content.propTypes = {
  children: PropTypes.node.isRequired,
  heightOffset: PropTypes.string,
  px: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

export default Content;
