import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { StyledSpan } from './styles';

import FilterButton from '../../FilterButton';
import { Box } from '../../Box';
import Shimmer from '../../Shimmer';
import Carousel from '../../CarouselComposition/Carousel';
import Typography from '../../Typography';
import FormatDate from '../../FormatDate';
import FormatDayOfWeek from '../../FormatDayOfWeek';
import { L10n } from '../../L10n';
import { useClientStore } from '../../../store/store';

const NUM_DATES_SHOWN = 7;
const ONE_DAY = 1000 * 60 * 60 * 24;

const DateFilters = ({ isLoading, activeDate, availableDates, handleClick }) => {
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState([]);
  const [currDaysDisplayed, setCurrDaysDisplayed] = useState([]);
  const { uiState } = useClientStore((state) => ({
    uiState: state.uiState,
  }));

  const getDaysToDisplay = useCallback(() => {
    const firstDayToDisplay = new Date().getDate() - new Date().getDay() - 1;
    const lastSessionDate = new Date(
      Object.keys(availableDates)[Object.keys(availableDates).length - 1]
    );
    const daysUntilLastSession = Math.floor(
      Math.round(
        lastSessionDate.getTime() - new Date(new Date().setDate(firstDayToDisplay)).getTime()
      ) / ONE_DAY
    );

    const totalDays =
      daysUntilLastSession + NUM_DATES_SHOWN - (daysUntilLastSession % NUM_DATES_SHOWN);
    const futureDates = []; // shows until end of week for last event session date
    for (let i = 0; i < totalDays; i += 1) {
      const day = new Date().getDate() - new Date().getDay() + i;
      const futureDate = new Date(new Date().setDate(day)).toDateString();
      futureDates.push(futureDate);
    }

    const paginationArr = []; // shows dates weekly for carousel
    for (let i = 0; i < totalDays; i += NUM_DATES_SHOWN) {
      const week = futureDates.splice(0, NUM_DATES_SHOWN);
      if (week.find((date) => date === activeDate) && pagination.length === 0) {
        setPage(i / NUM_DATES_SHOWN);
      }
      paginationArr.push(week);
    }
    if (availableDates && !isLoading && pagination.length === 0) {
      setPagination(paginationArr);
    }
    setCurrDaysDisplayed(paginationArr[page]);

    return paginationArr;
  }, [activeDate, availableDates, isLoading, page, pagination.length]);

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handleBackClick = () => {
    setPage(page - 1);
  };

  useEffect(() => {
    getDaysToDisplay();
  }, [isLoading, getDaysToDisplay]);

  const dataLoading =
    isLoading ||
    availableDates === undefined ||
    currDaysDisplayed === undefined ||
    currDaysDisplayed.length === 0;

  const CarouselTitle = () =>
    dataLoading ? (
      <>
        <Shimmer height="20px" width="120px" space={{ m: '0 auto', mb: 'xxSmall' }} />
        <Shimmer height="20px" width="100px" space={{ m: '0 auto' }} />
      </>
    ) : (
      <>
        <Typography variant="secondaryMedium" space={{ pb: 'xxSmall' }}>
          <FormatDate dateStr={currDaysDisplayed[0]} format="month-year" />
        </Typography>
        <Typography variant="primaryMedium">
          <L10n tokenID="modal.dateTimeSelection.week_of" />{' '}
          {new Intl.DateTimeFormat(uiState.langPref === 'en-us' ? 'en-us' : 'es', {
            month: '2-digit',
            day: 'numeric',
          }).format(new Date(currDaysDisplayed[0]))}
          {' - '}
          {new Intl.DateTimeFormat(uiState.langPref === 'en-us' ? 'en-us' : 'es', {
            month: '2-digit',
            day: 'numeric',
          }).format(new Date(currDaysDisplayed[currDaysDisplayed.length - 1]))}
        </Typography>
      </>
    );

  return (
    <Box>
      <Carousel
        currPage={page}
        numPages={pagination.length}
        handleBackClick={dataLoading ? () => {} : handleBackClick}
        handleNextClick={dataLoading ? () => {} : handleNextClick}
        title={<CarouselTitle />}
      >
        {dataLoading ? (
          <>
            {[...Array(NUM_DATES_SHOWN)].map((_, i) => (
              <FilterButton
                loading
                size="square"
                key={i} // eslint-disable-line react/no-array-index-key
                activeFilter=""
                filterName=""
                handleClick={() => {}}
              />
            ))}
          </>
        ) : (
          <Box
            display="grid"
            gridTemplateColumns="repeat(7, minmax(0, 1fr))"
            gridColumn="1"
            gridGap="xxSmall"
          >
            {currDaysDisplayed.map((day) => {
              const currDay = new Date(day);
              const today = new Date();
              currDay.setHours(0, 0, 0, 0);
              today.setHours(0, 0, 0, 0);
              const unavailable = currDay < today || availableDates[day] === undefined;
              return (
                <FilterButton
                  key={day}
                  activeFilter={activeDate}
                  filterName={day}
                  size="square"
                  handleClick={() => handleClick(day)}
                  type={unavailable ? 'unavailable' : 'available'}
                >
                  <>
                    <StyledSpan>
                      <FormatDayOfWeek dateStr={day} format="short" />
                    </StyledSpan>
                    <Typography tag="span" variant="secondaryBase" color={{ color: 'inherit' }}>
                      {new Date(day).getDate()}
                    </Typography>
                  </>
                </FilterButton>
              );
            })}
          </Box>
        )}
      </Carousel>
    </Box>
  );
};

DateFilters.propTypes = {
  activeDate: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  availableDates: PropTypes.objectOf(
    PropTypes.shape({
      children: PropTypes.string,
      choices: PropTypes.arrayOf([
        PropTypes.shape({
          attributes: PropTypes.shape({
            type: PropTypes.string,
            url: PropTypes.string,
          }),
          Count_of_Appointments__c: PropTypes.number,
          End_Date_Time__c: PropTypes.string,
          Event__c: PropTypes.string,
          Id: PropTypes.string,
          Is_Internal__c: PropTypes.bool,
          Is_Private__c: PropTypes.bool,
          label: PropTypes.string,
          Max_Attendees__c: PropTypes.number,
          Name: PropTypes.string,
          Session_Full__c: PropTypes.bool,
          Start_Date_Time__c: PropTypes.string,
          value: PropTypes.string,
        }),
      ]),
    })
  ).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default DateFilters;
