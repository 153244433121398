import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useClientStore } from '../../../store/store';
import { CheckboxGroup } from '../../CheckboxGroup';
import Button from '../../Button';
import { Box } from '../../Box';
import { Content, Footer, Header } from '../../ModalComposition';
import Modal from '../../Modal';
import { L10n } from '../../L10n';
import {
  SelectedVaccinesCheckboxList,
  updateVaccineCheckboxList,
} from '../../../utils/vaccineCheckboxList';
import { RadioGroup } from '../../Radio';

const LocationFilterModal = ({ productState, setProductState }) => {
  const intl = useIntl();
  const { selectedProducts, hideModal, modalOpen, updateCovidManufacturer } = useClientStore(
    (state) => ({
      selectedProducts: state.apptDetails.products,
      modalOpen: state.modalState,
      hideModal: state.hideModal,
      updateCovidManufacturer: state.updateCovidManufacturer,
    })
  );
  const [checkboxErrorState, setCheckboxErrorState] = useState('');
  const [checkboxGroupData, setCheckboxGroupData] = useState(
    SelectedVaccinesCheckboxList(selectedProducts, productState)
  );
  const [manufacturerState, setManufacturerState] = useState(
    productState['COVID-19']?.selected.Label__c
  );

  const [filterState, setFilterState] = useState(productState);
  const updateFilters = () => {
    const products = {};
    Object.keys(checkboxGroupData).forEach((key) => {
      const vaxName = checkboxGroupData[key].Label__c;
      if (checkboxGroupData[key].checked) {
        products[vaxName] = selectedProducts[vaxName];
        if (vaxName === 'COVID-19') {
          products[vaxName] = {
            ...products['COVID-19'],
            selected: selectedProducts['COVID-19'].manufacturers[manufacturerState],
          };
          // update store with selected manufacturer
          updateCovidManufacturer(selectedProducts['COVID-19'].manufacturers[manufacturerState]);
        }
      }
    });
    if (Object.keys(products).length <= 0) {
      setCheckboxErrorState(intl.formatMessage({ id: 'modal.location_filter.products_error' }));
    } else {
      hideModal();
      setProductState({ ...products });
    }
  };

  const handleClose = () => {
    hideModal();
  };

  const c19product = checkboxGroupData['COVID-19']?.checked;

  const radioOptions = {
    uniqueId: 'manufacturers',
    choices: selectedProducts['COVID-19']
      ? Object.keys(selectedProducts['COVID-19']?.manufacturers).map((manufacturer) => {
          return {
            label: selectedProducts['COVID-19'].manufacturers[manufacturer].Covid_Manufacturer__c,
            value: selectedProducts['COVID-19'].manufacturers[manufacturer].Label__c,
          };
        })
      : {},
  };

  const handleCheckboxChange = (index, value, e) => {
    setCheckboxErrorState();
    const vax = e.currentTarget.getAttribute('data-label');
    const checkedProducts = {};
    setCheckboxGroupData(updateVaccineCheckboxList(value, vax, checkboxGroupData));
    Object.keys(checkboxGroupData).forEach((vaccine) => {
      if ((checkboxGroupData[vaccine].checked && vax !== vaccine) || (vax === vaccine && value)) {
        checkedProducts[vaccine] = checkboxGroupData[vaccine];
      }
    });
    if (vax === 'COVID-19' && value && !filterState['COVID-19']) {
      setManufacturerState(selectedProducts['COVID-19'].selected.Label__c);
    }
  };

  const handleRadioChange = (event) => {
    setManufacturerState(event.target.value);
    setFilterState({
      ...filterState,
      'COVID-19': {
        ...filterState['COVID-19'],
        selected: selectedProducts['COVID-19'].manufacturers[event.target.value],
      },
    });
  };

  return (
    <Modal
      isMobileFullScreen
      canCloseOnClickOutside={false}
      open={modalOpen}
      onClose={handleClose}
      header={
        <Header withClose handleClose={handleClose}>
          {intl.formatMessage({ id: 'modal.location_filter.filters' })}
        </Header>
      }
      content={
        <Content>
          <Box display="grid" gridGap="xLarge">
            <CheckboxGroup
              options={Object.values(checkboxGroupData)}
              onChange={handleCheckboxChange}
              label={<L10n tokenID="general.vaccines" />}
              variant="card"
              error={checkboxErrorState}
              required
            />
            {c19product && Object.keys(checkboxGroupData['COVID-19']?.manufacturers).length > 1 && (
              <RadioGroup
                label={intl.formatMessage({ id: 'general.manufacturer' })}
                options={radioOptions}
                onChange={handleRadioChange}
                selectedOption={manufacturerState}
                name="manufacturer_preference"
                variant="card"
              />
            )}
          </Box>
        </Content>
      }
      footer={
        <Footer variant="single">
          <Button handleClick={updateFilters} size="fill">
            <L10n tokenID="modal.location_filter.apply" />
          </Button>
        </Footer>
      }
    />
  );
};

LocationFilterModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  productState: PropTypes.object,
  setProductState: PropTypes.func,
};

export default LocationFilterModal;
