import styled from 'styled-components';
import css from '@styled-system/css';
import { FlexBox } from '../Box';

export const CheckboxBorder = styled.ul`
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  display: flex;
  ${css({
    gap: 'large',
    '&.card': {
      display: 'block',
      border: 'normal',
      borderColor: 'coldBrews.200',
      borderRadius: 'small',
      py: '14px',
    },
    '.error &.card': {
      border: 'normalError',
    },
  })}
`;

export const CheckboxGroupContainer = styled(FlexBox)`
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  ${css({
    gap: 'small',
  })}
`;

export const CheckboxFieldContainer = styled.li`
  align-self: stretch;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 12px 24px;

  ${css({
    color: 'coldBrews.800',
    borderRadius: 'medium',
    '&.card': {
      padding: '10px 16px',
      border: 'none',
    },
    '.error &': {
      border: 'normalError',
    },
    '.error .card &': {
      border: 'none',
    },
  })}

  ${({ disabled }) =>
    disabled &&
    css({
      bg: 'coldBrews.50',
    })};

  ${({ checked }) =>
    css({
      borderColor: checked ? 'rxInteractionBlue' : 'coldBrews.200',
      borderWidth: checked ? '2px' : '1px',
    })};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'Checkbox' })`
  border-width: 2px;
  cursor: pointer;
  flex-shrink: 0;
  height: 18px;
  width: 18px;

  ${css({
    borderColor: 'rxInteractionBlue',
    borderRadius: 'medium',
    borderStyle: 'solid',
  })}
`;

export const CheckboxLabel = styled.label`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  user-select: none;
  ${css({
    color: 'coldBrews.800',
    fontFamily: 'default',
    fontSize: 'medium',
    fontWeight: 'medium',
  })}
`;

export const AnimationContainer = styled.div`
  overflow: hidden;
`;

export const InfoContainer = styled.div`
  margin: 10px 0px 0px;
  ${css({
    '&.infoByte-enter': {
      opacity: '0.01',
      transform: 'translateY(-100%)',
      '&&-active': { opacity: '1', transform: 'translateY(0%)' },
    },
    transition: 'transform .5s ease-in',
  })}
`;

export const StyledErrorMessage = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${css({
    '&.card': {
      px: 'large',
    },
  })}
`;
