import React, { useEffect, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavBar from '../NavBar';
import Button from '../Button';
import { L10n } from '../L10n';
import { routeList } from '../../routes/routeList';
import { useClientStore } from '../../store/store';

const PageNav = ({
  disableNext = false,
  handleNext,
  handlePrevious,
  hideNext,
  hidePrevious,
  customNextButtonLabel = null,
  customNextButtonSizing,
}) => {
  const navigate = useNavigate();
  const currentPageIndex = useClientStore((state) => state.uiState.currentPage);
  const validPage = useClientStore((state) => state.uiState.validPage);
  const updateUiState = useClientStore((state) => state.updateUiState);
  const uiState = useClientStore((state) => state.uiState);
  const UrlSpecifiedEvent = useClientStore((state) => state.apptDetails.UrlSpecifiedEvent);
  const [portalDomNode, setPortalDomNode] = useState(null);

  useEffect(() => {
    setPortalDomNode(document.getElementById('pageNav-root'));
  }, [portalDomNode]);

  const onNext = useCallback(
    (event) => {
      if (handleNext && !validPage) {
        handleNext(event);
      }
      if (validPage || !handleNext) {
        const nextRouteIndex = routeList.findIndex(
          (route, idx) =>
            idx > currentPageIndex &&
            (!route.showPageKey || (route.showPageKey && uiState[route.showPageKey]))
        );
        const nextRoute = routeList[nextRouteIndex];
        updateUiState('currentPage', nextRouteIndex);
        updateUiState('validPage', false); // reset validity for new page
        const path = UrlSpecifiedEvent
          ? `${nextRoute.path}?eventId=${UrlSpecifiedEvent.event.Id}`
          : nextRoute.path;
        navigate(path, { replace: true });
        window.scrollTo(0, 0);
      }
    },
    [validPage, currentPageIndex, handleNext, navigate, uiState, updateUiState, UrlSpecifiedEvent]
  );

  const onPrevious = (event) => {
    if (handlePrevious) {
      handlePrevious(event);
    } else {
      const prevRouteIndex = routeList.findLastIndex(
        (route, idx) =>
          idx < currentPageIndex &&
          (!route.showPageKey || (route.showPageKey && uiState[route.showPageKey]))
      );
      const prevRoute = routeList[prevRouteIndex];
      updateUiState('currentPage', prevRouteIndex);
      updateUiState('validPage', false); // reset validity for new page
      const path = UrlSpecifiedEvent
        ? `${prevRoute.path}?eventId=${UrlSpecifiedEvent.event.Id}`
        : prevRoute.path;
      navigate(path);
    }
  };

  const isNextHidden = currentPageIndex === routeList.length - 1 || hideNext;

  const isPrevHidden = currentPageIndex === 0 || hidePrevious;

  useEffect(() => {
    if (handleNext && validPage) {
      onNext();
    }
  }, [validPage, handleNext, onNext]);

  return (
    portalDomNode &&
    createPortal(
      <NavBar
        position={[isPrevHidden ? 'right' : 'spaceBetween', 'right']}
        symmetricalNav={customNextButtonSizing !== undefined}
      >
        {!isPrevHidden && (
          <Button size={['hug', 'secondary']} styling="plainBlue" handleClick={onPrevious}>
            <L10n tokenID="layout.previous" />
          </Button>
        )}
        {!isNextHidden && (
          <Button
            size={customNextButtonSizing || ['secondary']}
            handleClick={onNext}
            disabled={disableNext}
          >
            {customNextButtonLabel === null ? (
              <L10n tokenID="layout.next" />
            ) : (
              customNextButtonLabel
            )}
          </Button>
        )}
      </NavBar>,
      portalDomNode
    )
  );
};

PageNav.propTypes = {
  disableNext: PropTypes.bool,
  customNextButtonLabel: PropTypes.string,
  customNextButtonSizing: PropTypes.arrayOf(PropTypes.string),
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
  hideNext: PropTypes.bool,
  hidePrevious: PropTypes.bool,
};

export default PageNav;
