import React from 'react';
import { string, arrayOf, func } from 'prop-types';
import FilterRibbon from '../FilterRibbon';
import Button from '../Button';
import { L10n } from '../L10n';
import Typography from '../Typography';

const AppliedFilterRibbon = ({ filteredProducts, handleClick }) => {
  const button = (
    <Button handleClick={handleClick} size="noPadding" styling="transparent">
      <Typography tag="p" variant="primarySmall" color={{ color: 'highlight' }}>
        <L10n tokenID="filter_ribbon.view_all_selections" />
      </Typography>
    </Button>
  );
  return (
    <FilterRibbon
      icon="filter"
      ribbonContent={
        <Typography tag="p" variant={['primaryMedium']}>
          <L10n
            tokenID="filter_ribbon.selected_vaccines"
            values={{ vaccineCount: filteredProducts.length }}
          />
        </Typography>
      }
      action={button}
    />
  );
};

AppliedFilterRibbon.propTypes = {
  filteredProducts: arrayOf(string),
  handleClick: func,
};

export default AppliedFilterRibbon;
