import styled from 'styled-components';
import css from '@styled-system/css';
import { compose, layout, space, variant } from 'styled-system';
import { variants } from './variants';

export const StyledSearch = styled.div(
  css({
    alignItems: 'center',
    backgroundColor: 'rxColors.rxInteraction50',
    borderStyle: 'solid none',
    display: 'flex',
    gridGap: 'large',
    gridTemplateColumns: 'repeat(12,minmax(0,1fr))',
    opacity: '0',
    transition: 'transform .5s ease-in',
  }),
  compose(layout, space),
  variant({
    variants,
  })
);

export const StyledSearchContainer = styled.div(
  ({ checked }) =>
    css({
      position: checked ? 'relative' : 'absolute',
      width: 0,
      overflowY: 'hidden',
      zIndex: checked ? 0 : -1,
      '&.expanded': {
        width: '100%',
      },
    }),
  compose(layout, space)
);
