import styled from 'styled-components';
import css from '@styled-system/css';
import { FlexBox } from '../Box';

export const StyledFlexBox = styled(FlexBox)(
  css({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  })
);
