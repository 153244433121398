import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledOverlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
  ${css({
    zIndex: 'overlay',
  })}
`;
