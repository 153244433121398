import styled from 'styled-components';
import css from '@styled-system/css';
import variant from '@styled-system/variant';
import { positionVariants } from './variants';

export const StyledNav = styled.div`
  display: flex;
  width: 100%;
  bottom: 0px;
  ${css({
    backgroundColor: 'buttercream',
    borderTopColor: 'coldBrews.200',
    borderTopStyle: 'solid',
    borderWidth: '1px',
    pr: '18px',
    py: '18px',
    zIndex: ['1', '0'],
  })}

  ${variant({
    prop: 'position',
    variants: positionVariants,
  })}
  
  ${({ symmetricalNav }) =>
    css({
      pl: symmetricalNav && '18px',
    })}
`;
