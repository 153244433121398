const space = [0, 2, 4, 6, 8, 12, 16, 24, 32, 40, 64, 128];

[
  space.none, // 0
  space.xxxSmall, // 2
  space.xxSmall, // 4
  space.xSmall, // 6
  space.small, // 8
  space.medium, // 12
  space.large, // 16
  space.xLarge, // 24
  space.xxLarge, // 32
  space.xxxLarge, // 40
  space.xxxxLarge, // 64
  space.xxxxxLarge, // 128
] = space;

export default space;
