import React, { useState, useRef } from 'react';
import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { handleEnterKeyPress, focusOnNextRef } from '../../utils/common';

import { Box } from '../../components/Box';
import { FieldGroup } from '../../components/FieldGroup';
import InfoByte from '../../components/InfoByte';
import { L10n } from '../../components/L10n';
import PageNavigation from '../../components/PageNavigation';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import { validators } from './validators';

const AuthenticateUser = ({ isError, handleSetFormattedData }) => {
  const intl = useIntl();
  const inputRefs = useRef([]);

  const errors = {
    Birthdate__c: intl.formatMessage(
      {
        id: 'general.field_error.valid_data_error',
      },
      { data: intl.formatMessage({ id: 'general.date_of_birth_lowercase' }) }
    ),
  };
  const [inputState, setInputState] = useState({
    Birthdate__c: { value: '', hasError: false, error: '', node: null },
    confirmationNum: { value: '', hasError: false, error: '', node: null },
  });

  if (isError) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  const handleInputChange = (event) => {
    setInputState({
      ...inputState,
      [event.target.id]: {
        value: event.target.value,
      },
    });
  };

  const validateInput = (field, label, target) => {
    return validators.validateInput(
      target,
      label,
      validators[field],
      inputState[field].value,
      errors[field]
    );
  };

  const handleBlur = (event) => {
    const { target } = event;
    const field = target.id;
    const label = target.labels[0].firstChild.firstChild.textContent;
    const { hasError, error } = validateInput(field, label, event.target);
    setInputState({
      ...inputState,
      [field]: {
        ...inputState[field],
        hasError,
        error,
        node: target,
      },
    });
  };

  const handleSearchClick = () => {
    let firstError = null;
    const currState = { ...inputState };
    document.querySelectorAll('input').forEach((target) => {
      const { id } = target;
      const label = target.labels[0].firstChild.firstChild.textContent;
      const { hasError, error } = validateInput(id, label, target);
      if (hasError) {
        firstError = !firstError ? target : firstError;
        currState[id] = {
          ...inputState[id],
          hasError,
          error,
          node: target,
        };
      }
    });
    setInputState({
      ...inputState,
      ...currState,
    });
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      handleSetFormattedData(inputState.confirmationNum.value, inputState.Birthdate__c.value);
    }
  };

  const inputs = [
    {
      label: <L10n tokenID="general.field.patient_DOB" />,
      name: 'Birthdate__c',
      mask: '99-99-9999',
      help: (
        <L10n
          tokenID="general.field.format_helper"
          values={{ data: <L10n tokenID="general.field.format_date" /> }}
        />
      ),
      error: inputState.Birthdate__c.error,
      variant: ['base', 'medium'],
      type: 'tel',
    },
    {
      label: <L10n tokenID="general.confirmation_number" />,
      name: 'confirmationNum',
      help: <L10n tokenID="page.appointmentManagement.confirmation_number_helper" />,
      error: inputState.confirmationNum.error,
      variant: ['base', 'medium'],
      maxLength: 18,
      type: 'text',
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gridGap="xLarge" mt={['small', 'none']}>
      {isError && (
        <InfoByte
          variant="error"
          info={<L10n tokenID="page.appointmentManagement.appointment_not_found" />}
          alignment="top"
        />
      )}
      <PageTitleSubtitle
        subtitle={<L10n tokenID="page.appointmentManagement.user_auth_subtitle" />}
        title={<L10n tokenID="page.appointmentManagement.user_auth_title" />}
      />
      {inputs.map((input, index) => {
        const { label, name, mask, help, error, variant, maxLength, type } = input;
        return (
          <FieldGroup
            required
            onBlur={handleBlur}
            onChange={handleInputChange}
            key={name}
            label={label}
            name={name}
            mask={mask}
            help={help}
            error={error}
            variant={variant}
            maxlength={maxLength}
            onKeyDown={(e) =>
              handleEnterKeyPress(focusOnNextRef, e, inputRefs, index, handleSearchClick)
            }
            ref={(ref) => {
              inputRefs.current[index] = ref;
            }}
            type={type}
          />
        );
      })}
      <PageNavigation
        hidePrevious
        handleNext={handleSearchClick}
        customNextButtonSizing={['primary']}
        customNextButtonLabel={intl.formatMessage({
          id: 'page.appointmentManagement.search_appointments',
        })}
      />
    </Box>
  );
};

AuthenticateUser.propTypes = {
  isError: bool,
  handleSetFormattedData: func,
};

export default AuthenticateUser;
