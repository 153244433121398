import React from 'react';
import PropTypes from 'prop-types';
import { StyledGrid } from './styles';

const Grid = ({ children, ...rest }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledGrid {...rest}>{children}</StyledGrid>;
};

Grid.propTypes = {
  children: PropTypes.node,
};

export default Grid;
