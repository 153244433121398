import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledHoldCard = styled.div(
  css({
    alignItems: 'flex-start',
    display: 'inline-flex;',
    justifyContent: 'start',
    gap: 'large',
  })
);

export const StyledInfo = styled.span(
  css({
    color: 'coldBrews.800',
    font: 'default',
    fontSize: 'small',
    fontStyle: 'normal',
    fontWeight: 'medium',
    lineHeight: 'normal',
  })
);

export const StyledLocation = styled.p(
  css({
    color: 'coldBrews.800',
    font: 'default',
    fontSize: 'small',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 'normal',
  })
);
