import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledFooter = styled.footer(
  css({
    backgroundColor: 'coldbrew',
    color: 'buttercream',
    display: 'flex',
    flexDirection: 'column',
    height: '81px',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100vw',
  })
);
