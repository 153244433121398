import { variant } from 'styled-system';
import styled from 'styled-components';
import css from '@styled-system/css';
import { footerVariants } from './variants';
import { Box } from '../../Box';

export const StyledFooter = styled(Box)`
  ${css({
    bottom: 0,
    bg: 'buttercream',
    p: 'xLarge',
    pt: 'xxLarge',
    position: 'sticky',
  })}
  ${variant({
    variants: footerVariants,
  })}
  ${({ px }) =>
    css({
      px,
    })}
`;
