import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDateParts } from 'react-intl';

const FormatDate = ({ dateStr, format = 'long' }) => {
  const newDate = new Date(dateStr);

  switch (format) {
    case 'long':
      return (
        <FormattedDateParts value={newDate} month="long" day="2-digit" timeZone="UTC">
          {(parts) => (
            <>
              {parts[0].value}
              {parts[1].value}
              {parts[2].value}
            </>
          )}
        </FormattedDateParts>
      );
    case 'short':
      return (
        <FormattedDateParts value={newDate} timeZone="UTC">
          {(parts) => (
            <>
              {parts[0].value}
              {parts[1].value}
              {parts[2].value}
            </>
          )}
        </FormattedDateParts>
      );
    case 'month-year':
      return (
        <FormattedDateParts value={newDate} month="long" year="numeric" timeZone="UTC">
          {(parts) => (
            <>
              {parts[0].value}
              {parts[1].value}
              {parts[2].value}
            </>
          )}
        </FormattedDateParts>
      );
    case 'full':
      return (
        <FormattedDateParts
          value={newDate}
          month="long"
          day="2-digit"
          year="numeric"
          timeZone="UTC"
        >
          {(parts) => (
            <>
              {parts[0].value}
              {parts[1].value}
              {parts[2].value}
              {parts[3].value}
              {parts[4].value}
            </>
          )}
        </FormattedDateParts>
      );
    default:
      return (
        <FormattedDateParts value={newDate} month="long" day="2-digit" timeZone="UTC">
          {(parts) => (
            <>
              {parts[0].value}
              {parts[1].value}
              {parts[2].value}
            </>
          )}
        </FormattedDateParts>
      );
  }
};

FormatDate.propTypes = {
  dateStr: PropTypes.string.isRequired,
  format: PropTypes.oneOf(['long', 'short', 'month-year', 'full']),
};

export default FormatDate;
