import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, fontSize, height, width } from 'styled-system';

export const StyledList = styled.ul`
  list-style: inside;
`;

export const StyledListItem = styled.li`
  list-style: none;
`;

export const StyledItemContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledItem = styled.span`
  margin-left: 10px;
  margin-right: 10px;
  &:before {
    content: \"\\A\";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    display: block;
    ${css({
      backgroundColor: 'coldBrews.800',
    })}
  ${compose(fontSize, height, width)}
`;
