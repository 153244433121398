import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Card from '../Card';

import { StyledInfoByte, StyledIcon, StyledInfo } from './styles';

const InfoByte = ({ info, variant, icon = null, alignment = 'center' }) => {
  const displayedIcon = () => {
    if (icon !== null) return icon;
    return variant === 'callOut' ? 'info' : 'alert';
  };
  return (
    <Card variant={variant} margin="none">
      <StyledInfoByte alignment={alignment}>
        <StyledIcon>
          <Icon icon={displayedIcon()} />
        </StyledIcon>
        <StyledInfo>{info}</StyledInfo>
      </StyledInfoByte>
    </Card>
  );
};

InfoByte.propTypes = {
  alignment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  info: PropTypes.node,
  icon: PropTypes.string,
  variant: PropTypes.oneOf(['callOut', 'warning', 'error']),
};

export default InfoByte;
