/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { groupFluCovidVaccines } from '../../../utils/grouping/groupFluCovidVaccines';
import { VaccineCheckboxList, updateVaccineCheckboxList } from '../../../utils/vaccineCheckboxList';
import {
  MAX_PRODUCTS_IN_APPT,
  vaccinesNotGivenTogether,
  pregnancyRestrictedVaccines,
} from '../../../utils/vaccineSpecifications';
import { removeSpecificVaccines } from '../../../utils/removeSpecificVaccines';
import { useClientStore } from '../../../store/store';

import Button from '../../Button';
import { Box } from '../../Box';
import Card from '../../Card';
import { CheckboxGroup } from '../../CheckboxGroup';
import { Content, Footer, Header } from '../../ModalComposition';
import InfoByte from '../../InfoByte';
import { L10n } from '../../L10n';
import Modal from '../../Modal';
import Typography from '../../Typography';
import { showToast } from '../../Toast';

const MAX_VAX_IN_UPSELL = 3;

const UpsellModal = ({ handleModalClose, productData, showIsPregnantScreenInitial }) => {
  const intl = useIntl();
  const {
    hideModal,
    modalOpen,
    products,
    updateApptDetails,
    updateUiState,
    updateCovidManufacturer,
  } = useClientStore((state) => ({
    modalOpen: state.modalState,
    hideModal: state.hideModal,
    products: state.apptDetails.products,
    updateApptDetails: state.updateApptDetails,
    updateUiState: state.updateUiState,
    updateCovidManufacturer: state.updateCovidManufacturer,
  }));
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const minuend =
    Object.keys(productData).length <= MAX_PRODUCTS_IN_APPT
      ? Object.keys(productData).length
      : MAX_PRODUCTS_IN_APPT;
  const vaccinesRemaining = Math.min(MAX_VAX_IN_UPSELL, minuend - Object.keys(products).length);
  const groupedVax = removeSpecificVaccines(
    // removes previously selected vaccines, groups covid vaccines, and sets disabled options
    groupFluCovidVaccines(productData),
    vaccinesNotGivenTogether,
    products
  );

  const [checkboxGroupData, setCheckboxGroupData] = useState(
    VaccineCheckboxList(Object.values(groupedVax).slice(0, MAX_VAX_IN_UPSELL))
  );

  const handleClose = (e, selectedVax) => {
    const hasRestricted = selectedVax
      ? Object.keys(selectedVax).some((vax) => vax in pregnancyRestrictedVaccines)
      : false;
    updateUiState('showIsPregnant', hasRestricted || showIsPregnantScreenInitial);
    hideModal();
    handleModalClose();
  };

  const formattedList = (selectedVax) =>
    Object.keys(selectedVax).map((vaccine, index) => (
      <>
        {index === 0 ? ( // capitalize the first letter in the message
          intl
            .formatMessage({ id: `vaccine.${vaccine}` })
            .slice(0, 1)
            .toUpperCase() + intl.formatMessage({ id: `vaccine.${vaccine}` }).slice(1)
        ) : (
          <L10n tokenID={`vaccine.${vaccine}`} />
        )}
        {Object.keys(selectedVax).length === 2 && index === Object.keys(selectedVax).length - 2
          ? ` ${intl.formatMessage({ id: 'modal.upsell.and' })} `
          : index === Object.keys(selectedVax).length - 2
          ? `, ${intl.formatMessage({ id: 'modal.upsell.and' })} `
          : index < Object.keys(selectedVax).length - 1
          ? ', '
          : Object.keys(selectedVax).length > 1
          ? ` ${intl.formatMessage({ id: `modal.upsell.have` })}`
          : ` ${intl.formatMessage({ id: `modal.upsell.has` })}`}
      </>
    ));

  const handleCheckboxChange = (index, value, e) => {
    setShowErrorMsg(false);
    setCheckboxGroupData(
      updateVaccineCheckboxList(
        value,
        e.currentTarget.getAttribute('data-label'),
        checkboxGroupData,
        vaccinesRemaining
      )
    );
  };

  const handleAddVaccines = () => {
    setShowErrorMsg(false);
    const selectedVax = {};
    const isMobile = window.screen.width < 768;
    const toastPosition = isMobile ? 'top-center' : 'bottom-left';

    Object.values(checkboxGroupData).forEach((vaccine) => {
      if (checkboxGroupData[vaccine.label].checked) {
        selectedVax[vaccine.Label__c] = checkboxGroupData[vaccine.label];
      }
    });
    if (Object.keys(selectedVax).length > 0) {
      updateApptDetails('products', { ...products, ...selectedVax });
      handleClose(null, selectedVax);
      if (Object.keys(selectedVax).includes('COVID-19')) {
        const c19 = selectedVax['COVID-19'];
        if (Object.keys(c19.manufacturers).length > 1) {
          updateUiState('showCovidManufacturer', true);
        } else {
          const manufacturerKey = Object.keys(c19.manufacturers)[0];
          updateCovidManufacturer(c19.manufacturers[manufacturerKey]);
        }
      }
      showToast(
        intl.formatMessage({ id: `modal.upsell.successToastHeading` }),
        'success',
        toastPosition,
        <>
          <span>
            <L10n
              tokenID="modal.upsell.successToastbody"
              values={{ data: formattedList(selectedVax) }}
            />
          </span>
        </>,
        true,
        6000
      );
    } else {
      setShowErrorMsg(true);
    }
  };

  return (
    <Modal
      isMobileFullScreen
      canCloseOnClickOutside={false}
      open={modalOpen}
      onClose={handleClose}
      header={
        <Header withClose handleClose={handleClose}>
          <L10n tokenID="modal.upsell.heading" />
        </Header>
      }
      content={
        <Content>
          <Box display="grid" gridGap="xLarge">
            {showErrorMsg && (
              <InfoByte variant="error" info={<L10n tokenID="modal.upsell.error" />} />
            )}
            <Typography variant={['primaryBase']}>
              <L10n tokenID="modal.upsell.subheading" />
            </Typography>
            <CheckboxGroup
              options={Object.values(checkboxGroupData)}
              onChange={handleCheckboxChange}
              name="upsellModalVaccines"
              label={
                <L10n
                  tokenID="modal.upsell.checkbox_gp_label"
                  values={{
                    vaccinesRemaining,
                  }}
                />
              }
            />

            <Card variant="callOut" margin="none">
              <Typography variant={['secondaryBase']} pb="4px">
                <L10n tokenID="modal.upsell.module_heading" />
              </Typography>
              <Typography variant={['primaryBase']}>
                <L10n tokenID="modal.upsell.module_text" />
              </Typography>
            </Card>
          </Box>
        </Content>
      }
      footer={
        <Footer variant="stacked">
          <Button handleClick={handleClose} styling="outlined" size="secondary">
            <L10n tokenID="modal.upsell.decline" />
          </Button>
          <Button handleClick={handleAddVaccines} size="secondary">
            <L10n tokenID="modal.upsell.add_vaccines" />
          </Button>
        </Footer>
      }
    />
  );
};

UpsellModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  productData: PropTypes.arrayOf(Object),
  showIsPregnantScreenInitial: PropTypes.bool,
};

export default UpsellModal;
