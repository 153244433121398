import { typographyVariants } from '../Typography/variants';

const highlight = {
  color: 'highlight',
  textDecoration: 'none',
};

const muted = {
  color: 'coldBrews.800',
};

const image = {
  '&:hover': {
    textDecoration: 'none',
  },
};

const white = {
  color: 'white',
  fontWeight: 'black',
};

const boldHighlight = {
  ...typographyVariants.secondaryBase,
  ...highlight,
  fontWeight: 'black',
};

export const linkVariants = {
  highlight,
  image,
  muted,
  white,
  boldHighlight,
};
