import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '../../components/Box';
import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';
import { L10n } from '../../components/L10n';
import { FieldGroup } from '../../components/FieldGroup';
import validators from '../../utils/validation';
import { useClientStore } from '../../store/store';
import PageNavigation from '../../components/PageNavigation';

const MedicareStatement = () => {
  const intl = useIntl();
  const { patientStore, updatePatientStore, updateUiState, uiState } = useClientStore((state) => ({
    patientStore: state.patient,
    updatePatientStore: state.updatePatientInfo,
    updateUiState: state.updateUiState,
    uiState: state.uiState,
  }));
  const todayString = Intl.DateTimeFormat(uiState.langPref === 'en-us' ? 'en-US' : 'es')
    .format(new Date())
    .replace(/\//g, '-');
  const [inputState, setInputState] = useState({
    Name_of_Beneficiary__c: {
      value: patientStore.Name_of_Beneficiary__c,
      hasError: false,
      error: '',
      node: null,
    },
    Medicare_Signature__c: {
      value: patientStore.Medicare_Signature__c,
      hasError: false,
      error: '',
      node: null,
    },
  });

  const errorMessages = {
    Name_of_Beneficiary__c: intl.formatMessage(
      { id: 'general.field_error.valid_data_error' },
      { data: <L10n tokenID="general.name" /> }
    ),
    Medicare_Signature__c: intl.formatMessage(
      { id: 'general.field_error.valid_data_error' },
      { data: <L10n tokenID="general.signature" /> }
    ),
  };

  const validateInput = (input, label = null, validator = null, value = null, errorMsg = null) => {
    let hasError = false;
    let error = '';
    if (!validator && input?.value === '' && input?.required) {
      hasError = true;
      error = intl.formatMessage({ id: 'general.field_error.required_error' }, { field: label });
    } else if (validator) {
      ({ hasError, error } = validator(value, input?.required, errorMsg));
    }
    return {
      hasError,
      error,
    };
  };
  const handleInputValidation = (event) => {
    const field = event.target.id;
    const label = event.target.labels[0].querySelector('span').firstChild.textContent;
    const { hasError, error } = validateInput(
      event.target,
      label,
      validators.validateName,
      inputState[field].value,
      errorMessages[field]
    );
    setInputState({
      ...inputState,
      [field]: {
        ...inputState[field],
        hasError,
        error,
        node: event.target,
      },
    });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const field = event.target.id;
    setInputState({
      ...inputState,
      [field]: {
        value,
      },
    });
    if (field in patientStore) {
      updatePatientStore(field, value);
    }
  };

  const handleNext = () => {
    let firstError;
    let currentState = { ...inputState };
    document.querySelectorAll('[name$="__c"]').forEach((node) => {
      const label =
        node.nodeName === 'INPUT'
          ? node.labels[0].querySelector('span').firstChild.textContent
          : null;
      const fieldName = node.getAttribute('name');
      const validator = label ? null : validators.validateName;
      const { value } = inputState[fieldName];
      const { error, hasError } = validateInput(
        node,
        label,
        validator,
        value,
        errorMessages[fieldName]
      );
      if (hasError) {
        if (!firstError) firstError = node;
        if (!inputState[fieldName].hasError) {
          currentState = {
            ...currentState,
            [fieldName]: {
              ...inputState[fieldName],
              hasError,
              error,
              node,
            },
          };
        }
      }
    });
    setInputState({ ...currentState });
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      updateUiState('validPage', true);
    }
  };

  return (
    <>
      <RedirectHandler pageNumber={13} />
      <Box
        data-testid="medicare_statement"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
      >
        <Box display="grid" gridGap="large">
          <Typography tag="h1" variant={['primaryXLarge', 'primaryWeb3']}>
            <L10n tokenID="page.medicare_statement.header" />
          </Typography>
          <Typography tag="p" variant={['primaryBase']}>
            <L10n tokenID="page.medicare_statement.subheader" />
          </Typography>
        </Box>
        <Box display="grid" gridGap="xLarge">
          <FieldGroup
            label={<L10n tokenID="page.medicare_statement.beneficiary" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="Name_of_Beneficiary__c"
            error={inputState.Name_of_Beneficiary__c.error}
            value={inputState.Name_of_Beneficiary__c.value}
            required
          />
          <FieldGroup
            label={<L10n tokenID="page.medicare_statement.signature" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="Medicare_Signature__c"
            error={inputState.Medicare_Signature__c.error}
            value={inputState.Medicare_Signature__c.value}
            required
          />
          <FieldGroup
            label={<L10n tokenID="insurance.Todays_Date" />}
            placeholder={todayString}
            variant="medium"
            disabled
          />
        </Box>
        <PageNavigation hideNext={false} hidePrevious={false} handleNext={handleNext} />
      </Box>
    </>
  );
};

export default MedicareStatement;
