import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';
import { FieldGroup } from '../../components/FieldGroup';
import { Checkbox } from '../../components/Checkbox';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import { RadioGroup } from '../../components/Radio';
import PageNavigation from '../../components/PageNavigation';
import { Box } from '../../components/Box';
import { useClientStore } from '../../store/store';
import { validators, errors } from './validators';
import Divider from '../../components/Divider';
import { L10n } from '../../components/L10n';
import { useUpdateSessionHold } from '../../api/Session-hold';
import Button from '../../components/Button';

const PHONE_NUMBER_FIELDS = [
  'Phone_Number__c',
  'Primary_Care_Provider_Phone__c',
  'Primary_Care_Provider_Fax__c',
];

const PatientInfoPage = () => {
  const intl = useIntl();
  const hold = useUpdateSessionHold();
  const {
    patientStore,
    updatePatientStore,
    pcpStore,
    updatePcpStore,
    updateUiState,
    updateModal,
    hideModal,
    showModal,
    reset,
    modalTimer,
    uiState,
  } = useClientStore((state) => ({
    patientStore: state.patient,
    updatePatientStore: state.updatePatientInfo,
    pcpStore: state.primaryCareProvider,
    updatePcpStore: state.updatePrimaryCareProvider,
    updateUiState: state.updateUiState,
    updateModal: state.updateModal,
    hideModal: state.hideModal,
    showModal: state.showModal,
    reset: state.reset,
    modalTimer: state.uiState.modalTimer,
    uiState: state.uiState,
  }));

  const dob = patientStore.Birthdate__c;
  const optInText = intl.formatMessage({ id: 'page.patient_info.opt_in' });

  const shareWithPcpOptions = {
    uniqueId: 'radio1',
    choices: [
      {
        label: <L10n tokenID="general.yes" />,
        value: 'yes',
      },
      {
        label: <L10n tokenID="general.no" />,
        value: 'no',
      },
    ],
  };

  const raceOptions = {
    'American Indian or Alaska Native': {
      label: intl.formatMessage({
        id: 'page.patient_info.demographics_race_American_Indian_Alaska_Native',
      }),
      value: 'American Indian or Alaska Native',
      checked: false,
    },
    Asian: {
      label: intl.formatMessage({ id: 'page.patient_info.demographics_race_Asian' }),
      value: 'Asian',
      checked: false,
    },
    'Black or African American': {
      label: intl.formatMessage({
        id: 'page.patient_info.demographics_race_Black_African_American',
      }),
      value: 'Black or African American',
      checked: false,
    },
    'Native Hawaiian or Other Pacific Islander': {
      label: intl.formatMessage({
        id: 'page.patient_info.demographics_race_Native_Hawaiian_Other_Pacific_Islander',
      }),
      value: 'Native Hawaiian or Other Pacific Islander',
      checked: false,
    },
    White: {
      label: intl.formatMessage({ id: 'page.patient_info.demographics_race_White' }),
      value: 'White',
      checked: false,
    },
    Other: {
      label: intl.formatMessage({ id: 'page.patient_info.demographics_other' }),
      value: 'Other',
      checked: false,
    },
    'Prefer not to disclose': {
      label: intl.formatMessage({ id: 'page.patient_info.demographics_prefer_not_to_disclose' }),
      value: 'Prefer not to disclose',
      checked: false,
    },
  };

  const ethnicityOptions = {
    uniqueId: 'Ethnicity__c',
    choices: [
      {
        label: intl.formatMessage({ id: 'page.patient_info.demographics_ethnicity_hispanic' }),
        value: 'Hispanic',
      },
      {
        label: intl.formatMessage({ id: 'page.patient_info.demographics_ethnicity_nonhispanic' }),
        value: 'Non-Hispanic',
      },
      {
        label: intl.formatMessage({
          id: 'page.patient_info.demographics_prefer_not_to_disclose',
        }),
        value: 'Prefer not to disclose',
      },
    ],
  };

  const genderOptions = {
    uniqueId: 'Gender__c',
    choices: [
      {
        label: intl.formatMessage({ id: 'page.patient_info.demographics_gender_female' }),
        value: 'Female',
      },
      {
        label: intl.formatMessage({ id: 'page.patient_info.demographics_gender_male' }),
        value: 'Male',
      },
      {
        label: intl.formatMessage({ id: 'page.patient_info.demographics_other' }),
        value: 'Other',
      },
    ],
  };

  const populateCheckboxSelection = (checkboxObj, selectedList) => {
    const checkboxClone = { ...checkboxObj };
    selectedList.forEach((selected) => {
      checkboxClone[selected].checked = true;
    });
    return Object.values(checkboxObj);
  };

  const [inputState, setInputState] = useState({
    First_Name__c: { value: patientStore.First_Name__c, hasError: false, error: '', node: null },
    Last_Name__c: { value: patientStore.Last_Name__c, hasError: false, error: '', node: null },
    Date_Of_Birth__c: { value: dob, hasError: false, error: '', node: null },
    Email_Address__c: {
      value: patientStore.Email_Address__c,
      hasError: false,
      error: '',
      node: null,
    },
    Phone_Number__c: {
      value: patientStore.Phone_Number__c,
      hasError: false,
      error: '',
      node: null,
    },
    Street__c: { value: patientStore.Street__c, hasError: false, error: '', node: null },
    City__c: { value: patientStore.City__c, hasError: false, error: '', node: null },
    State__c: { value: patientStore.State__c, hasError: false, error: '', node: null },
    Zip_Code__c: { value: patientStore.Zip_Code__c, hasError: false, error: '', node: null },
    Country__c: { value: patientStore.Country__c, hasError: false, error: '', node: null },
    County__c: { value: patientStore.County__c, hasError: false, error: '', node: null },
    Race__c: {
      value: populateCheckboxSelection(raceOptions, patientStore.Race__c),
      hasError: false,
      error: '',
      node: null,
    },
    Gender__c: { value: patientStore.Gender__c, hasError: false, error: '', node: null },
    Ethnicity__c: { value: patientStore.Ethnicity__c, hasError: false, error: '', node: null },
    sharePcpInput__c: {
      value: pcpStore.sharePcpInput__c,
      hasError: false,
      error: '',
      node: null,
    },
    Primary_Care_Provider_Name__c: {
      value: pcpStore.Primary_Care_Provider_Name__c,
      hasError: false,
      error: '',
      node: null,
    },
    Primary_Care_Provider_Phone__c: {
      value: pcpStore.Primary_Care_Provider_Phone__c,
      hasError: false,
      error: '',
      node: null,
    },
    Primary_Care_Provider_Fax__c: {
      value: pcpStore.Primary_Care_Provider_Fax__c,
      hasError: false,
      error: '',
      node: null,
    },
    Consent_to_SMS__c: { checked: patientStore.Consent_to_SMS__c },
  });

  const handleCheckboxGroupChange = (index, isChecked, field) => {
    const updatedOptions = inputState[field].value;
    updatedOptions[index].checked = isChecked;
    setInputState({
      ...inputState,
      [field]: { ...inputState.field, value: updatedOptions },
    });
  };

  const handleSMSConsent = (event) => {
    const { checked } = event;
    setInputState({ ...inputState, Consent_to_SMS__c: { checked } });
    updatePatientStore('Consent_to_SMS__c', checked);
  };

  const handleRadioGroupChange = (event) => {
    let error = '';
    let hasError = false;
    const { value } = event.target.value ? event.target : event.target.dataset;
    const field = event.target.name;
    if (inputState[field].hasError) {
      ({ error, hasError } = validators.validateInput(null, null, validators[field], value));
    }
    setInputState({
      ...inputState,
      [field]: {
        ...inputState[field],
        value,
        hasError,
        error,
      },
    });
    if (field in patientStore) {
      updatePatientStore(field, value);
    }
    if (field in pcpStore) {
      updatePcpStore(field, value);
    }
  };

  const handleInputValidation = (event) => {
    const field = event.target.id;
    const label = event.target.labels[0].querySelector('span').firstChild.textContent;
    const { hasError, error } = validators.validateInput(
      event.target,
      label,
      validators[field],
      inputState[field].value,
      errors[field]
    );
    setInputState({
      ...inputState,
      [field]: {
        ...inputState[field],
        hasError,
        error,
        node: event.target,
      },
    });
  };

  const getFormattedPhoneNumber = (value, field) => {
    let formattedValue = value;
    if (PHONE_NUMBER_FIELDS.includes(field) && formattedValue !== '') {
      if (value.match(/[a-zA-Z]+/)) {
        return inputState[field].value;
      }
      if (formattedValue.match(/^\+1/)) {
        formattedValue.slice(2);
      }
      if (formattedValue.match(/\d{10}/)) {
        formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
          3,
          6
        )}-${formattedValue.slice(6)}`;
      } else if (formattedValue.match(/\d*/)) {
        if (formattedValue.length > 7) {
          if (formattedValue.match(/\d{3}-\d{3}-/)) {
            formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
              4,
              7
            )}-${formattedValue.slice(8)}`;
          } else {
            formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
              4,
              7
            )}-${formattedValue.slice(7)}`;
          }
        } else if (formattedValue.length > 3) {
          if (formattedValue.match(/\d{3}-/)) {
            formattedValue = `${value.slice(0, 3)}-${value.slice(4)}`;
          } else {
            formattedValue = `${value.slice(0, 3)}-${value.slice(3)}`;
          }
        }
      }
    }
    return formattedValue;
  };

  const handleInputChange = (event) => {
    const field = event.target.id;
    const value = getFormattedPhoneNumber(event.target.value, field);
    setInputState({
      ...inputState,
      [field]: {
        value,
      },
    });
    if (field in patientStore) {
      updatePatientStore(field, value);
    }
    if (field in pcpStore) {
      updatePcpStore(field, value);
    }
  };

  const handleNext = () => {
    let firstError;
    let currentState = { ...inputState };
    document.querySelectorAll('[name$="__c"]').forEach((node) => {
      if (node.type !== 'radio') {
        const label =
          node.nodeName === 'INPUT'
            ? node.labels[0].querySelector('span').firstChild.textContent
            : null;
        const fieldName = node.getAttribute('name');
        const validator = validators[fieldName];
        const { value } = inputState[fieldName];
        const { error, hasError } = validators.validateInput(
          node,
          label,
          validator,
          value,
          errors[fieldName]
        );
        if (hasError) {
          if (!firstError) firstError = node;
          if (!inputState[fieldName].hasError) {
            currentState = {
              ...currentState,
              [fieldName]: {
                ...inputState[fieldName],
                hasError,
                error,
                node,
              },
            };
          }
        }
      } else {
        const fieldName = node.getAttribute('name');
        const validator = validators[fieldName];
        const { value } = inputState[fieldName];
        const { error, hasError } = validators.validateInput(
          null,
          null,
          validator,
          value,
          errors[fieldName]
        );
        if (hasError) {
          if (!firstError) firstError = node;
          if (!inputState[fieldName].hasError) {
            currentState = {
              ...currentState,
              [fieldName]: {
                ...inputState[fieldName],
                hasError,
                error,
                node,
              },
            };
          }
        }
      }
    });
    setInputState({ ...currentState });
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      const selectedArr = [];
      inputState.Race__c.value.forEach((option) => {
        if (option.checked) {
          selectedArr.push(option.value);
        }
      });
      updatePatientStore('Race__c', selectedArr);
      updatePatientStore('Consent_to_SMS__c', patientStore.Consent_to_SMS__c);
      updateUiState('validPage', true);
    }
  };

  const handleContinue = () => {
    hold.mutate();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    updateUiState('currentPage', 0);
    updateModal('appointmentExpirationModal', {
      startTimer: false,
    });
    clearTimeout(modalTimer);
    reset();
  };

  const showDiscardChangesModal = () => {
    updateModal('discardChangesModal', {
      handleClose: hideModal,
      handleContinue,
    });
    showModal();
  };
  const L10nPref = (suffix, values) => (
    <L10n tokenID={`page.patient_info.${suffix}`} key={suffix} values={{ ...values }} />
  );

  return (
    <>
      <RedirectHandler pageNumber={6} />
      <Box
        data-testid="patientInfo"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap="xLarge"
      >
        <Box display="grid" gridGap="large">
          <Typography tag="h1" variant={['primaryXLarge', 'primaryWeb3']}>
            <L10n tokenID="page.patient_info.header" />
          </Typography>
          <Typography tag="p" variant={['primaryBase']}>
            <L10n tokenID="page.patient_info.subheader" />
          </Typography>
        </Box>
        <Box display="grid" gridGap="xLarge">
          <Typography tag="h2" variant={['primaryLarge', 'primaryXXLarge']}>
            <L10n tokenID="page.patient_info.personal_info" />
          </Typography>
          <FieldGroup
            autocomplete="given-name"
            label={<L10n tokenID="page.patient_info.first_name" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="First_Name__c"
            error={inputState.First_Name__c.error}
            value={inputState.First_Name__c.value}
            required
          />
          <FieldGroup
            autocomplete="family-name"
            label={<L10n tokenID="page.patient_info.last_name" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="Last_Name__c"
            error={inputState.Last_Name__c.error}
            value={inputState.Last_Name__c.value}
            required
          />
          <FieldGroup
            disabled
            placeholder={Intl.DateTimeFormat(uiState.langPref === 'en-us' ? 'en-US' : 'es')
              .format(new Date(inputState.Date_Of_Birth__c.value.replace(/-/g, '/')))
              .replace(/\//g, '-')}
            variant={['base', 'medium']}
            label={<L10n tokenID="page.patient_info.dob" />}
            mask="99-99-9999"
            help={
              <Typography>
                {L10nPref('dob_helper', {
                  Typography: (str) => (
                    <Typography tag="span" variant="primarySmall">
                      {str}
                    </Typography>
                  ),
                  Button: (str) => (
                    <Button
                      handleClick={showDiscardChangesModal}
                      styling="transparent"
                      size="noPadding"
                    >
                      <Typography
                        tag="span"
                        variant="primarySmall"
                        color={{ color: 'rxInteractionBlue' }}
                      >
                        {str}
                      </Typography>
                    </Button>
                  ),
                })}
              </Typography>
            }
            name="Date_Of_Birth__c"
          />
        </Box>
        <Divider />
        <Box display="grid" gridGap="xLarge">
          <Typography tag="h2" variant={['primaryLarge', 'primaryXXLarge']}>
            <L10n tokenID="page.patient_info.contact_info" />
          </Typography>
          <FieldGroup
            autocomplete="street-address"
            label={<L10n tokenID="page.patient_info.address" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="Street__c"
            error={inputState.Street__c.error}
            value={inputState.Street__c.value}
            required
          />
          <FieldGroup
            autocomplete="address-level2"
            label={<L10n tokenID="page.patient_info.city" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="City__c"
            error={inputState.City__c.error}
            value={inputState.City__c.value}
            required
          />
          <FieldGroup
            autocomplete="address-level1"
            label={<L10n tokenID="page.patient_info.state" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="State__c"
            error={inputState.State__c.error}
            value={inputState.State__c.value}
            required
          />
          <FieldGroup
            autocomplete="postal-code"
            label={<L10n tokenID="page.patient_info.zip" />}
            mask="99999"
            help={
              <L10n
                tokenID="general.field.format_helper"
                values={{ data: <L10n tokenID="general.field.format_zip_code" /> }}
              />
            }
            variant={['base', 'base', 'medium']}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="Zip_Code__c"
            error={inputState.Zip_Code__c.error}
            value={inputState.Zip_Code__c.value}
            required
            type="tel"
          />
          <FieldGroup
            autocomplete="country"
            label={<L10n tokenID="page.patient_info.country" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="Country__c"
            error={inputState.Country__c.error}
            value={inputState.Country__c.value}
            required
          />
          <FieldGroup
            autocomplete="email"
            label={<L10n tokenID="page.patient_info.email" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="Email_Address__c"
            error={inputState.Email_Address__c.error}
            value={inputState.Email_Address__c.value}
            required
          />
          <FieldGroup
            autocomplete="tel"
            required
            maxlength={12}
            label={<L10n tokenID="page.patient_info.phone" />}
            help={
              <L10n
                tokenID="general.field.format_helper"
                values={{ data: <L10n tokenID="general.field.format_phone" /> }}
              />
            }
            variant={['base', 'medium']}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="Phone_Number__c"
            error={inputState.Phone_Number__c.error}
            value={inputState.Phone_Number__c.value}
            type="tel"
          />
          <label htmlFor="consentToSMS">
            <Box
              display="grid"
              gridGap="large"
              gridTemplateColumns="min-content auto"
              padding="0px 8px"
            >
              <Box marginTop="6px">
                <Checkbox
                  id="consentToSMS"
                  label={optInText}
                  checked={inputState.Consent_to_SMS__c.checked}
                  onChange={handleSMSConsent}
                />
              </Box>
              <Typography tag="p" variant={['primaryBase']}>
                {optInText}
              </Typography>
            </Box>
          </label>
        </Box>
        <Divider />
        <Box display="grid" gridGap="xLarge">
          <Typography tag="h2" variant={['primaryLarge', 'primaryXXLarge']}>
            <L10n tokenID="page.patient_info.demographics_info" />
          </Typography>
          <FieldGroup
            autocomplete="address-level3"
            label={<L10n tokenID="page.patient_info.demographics_county" />}
            onChange={handleInputChange}
            onBlur={handleInputValidation}
            name="County__c"
            error={inputState.County__c.error}
            value={inputState.County__c.value}
            required
          />
          <CheckboxGroup
            label={<L10n tokenID="page.patient_info.demographics_race" />}
            name="Race__c"
            onChange={(event, isChecked) => handleCheckboxGroupChange(event, isChecked, 'Race__c')}
            options={Object.values(inputState.Race__c.value)}
            error={inputState.Race__c.error}
            required
          />
          <RadioGroup
            label={intl.formatMessage({ id: 'page.patient_info.demographics_ethnicity' })}
            options={ethnicityOptions}
            onChange={handleRadioGroupChange}
            selectedOption={inputState.Ethnicity__c.value}
            name="Ethnicity__c"
            error={inputState.Ethnicity__c.error}
            required
          />
          <RadioGroup
            label={intl.formatMessage({ id: 'page.patient_info.demographics_gender' })}
            options={genderOptions}
            onChange={handleRadioGroupChange}
            selectedOption={inputState.Gender__c.value}
            name="Gender__c"
            error={inputState.Gender__c.error}
            required
          />
        </Box>
        <Divider />
        <Box display="grid" gridGap="xLarge">
          <Typography tag="h2" variant={['primaryLarge', 'primaryXXLarge']}>
            <L10n tokenID="page.patient_info.pcp" />
          </Typography>
          <Typography tag="p" variant={['primaryBase']}>
            <L10n tokenID="page.patient_info.pcp_subheader" />
          </Typography>
          <RadioGroup
            label={<L10n tokenID="page.patient_info.share_info" />}
            options={shareWithPcpOptions}
            onChange={handleRadioGroupChange}
            selectedOption={inputState.sharePcpInput__c.value}
            name="sharePcpInput__c"
            error={inputState.sharePcpInput__c.error}
            required
          />
          {inputState.sharePcpInput__c.value === 'yes' && (
            <Box display="grid" gridGap="xLarge">
              <FieldGroup
                autocomplete="name"
                label={<L10n tokenID="page.patient_info.pcp_name" />}
                onChange={handleInputChange}
                name="Primary_Care_Provider_Name__c"
                value={inputState.Primary_Care_Provider_Name__c.value}
              />
              <FieldGroup
                autocomplete="tel"
                maxlength={12}
                variant={['base', 'base', 'medium']}
                label={<L10n tokenID="page.patient_info.pcp_phone" />}
                help={
                  <L10n
                    tokenID="general.field.format_helper"
                    values={{ data: <L10n tokenID="general.field.format_phone" /> }}
                  />
                }
                onChange={handleInputChange}
                onBlur={handleInputValidation}
                name="Primary_Care_Provider_Phone__c"
                error={inputState.Primary_Care_Provider_Phone__c.error}
                value={inputState.Primary_Care_Provider_Phone__c.value}
                type="tel"
              />
              <FieldGroup
                autocomplete="tel"
                maxlength={12}
                variant={['base', 'base', 'medium']}
                label={<L10n tokenID="page.patient_info.pcp_fax" />}
                help={
                  <L10n
                    tokenID="general.field.format_helper"
                    values={{ data: <L10n tokenID="general.field.format_phone" /> }}
                  />
                }
                onChange={handleInputChange}
                onBlur={handleInputValidation}
                name="Primary_Care_Provider_Fax__c"
                error={inputState.Primary_Care_Provider_Fax__c.error}
                value={inputState.Primary_Care_Provider_Fax__c.value}
                type="tel"
              />
            </Box>
          )}
        </Box>
        <PageNavigation
          hideNext={false}
          hidePrevious={false}
          handlePrevious={showDiscardChangesModal}
          handleNext={handleNext}
        />
      </Box>
    </>
  );
};

export default PatientInfoPage;
