import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledSpan = styled.span`
  display: block;
  ${css({
    pt: 'large',
  })}
`;

export const CenteredContent = styled.div`
  top: 40%;
  position: relative;
  text-align: center;
`;
