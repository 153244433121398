import { variant, margin, padding } from 'styled-system';
import styled from 'styled-components';
import css from '@styled-system/css';
import { cardVariants } from './variants';

export const StyledCard = styled.div(
  margin,
  padding,
  ({ fullHeight }) =>
    css({
      height: fullHeight ? '100%' : 'auto',
    }),
  css({
    border: 'solid',
    fontFamily: 'default',
    justifyContent: 'center',
    lineHeight: 'heading',
    width: 'auto',
  }),
  ({ borderRadius }) =>
    css({
      borderRadius,
    }),
  variant({
    variants: cardVariants,
  })
);
