import { useQuery } from 'react-query';
import { api } from './config/axiosConfig';

const getOptions = async (parentId = process.env.REACT_APP_PARENT_ID) => {
  const response = await api.request({
    url: `/options?parentEventId=${parentId}`,
    method: 'GET',
  });
  return response;
};

export const useOptionsQuery = (parentId) => {
  return useQuery('options', () => getOptions(parentId), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
