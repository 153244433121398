import styled from 'styled-components';
import { compose, space } from 'styled-system';
import css from '@styled-system/css';

export const HangingIndent = styled.span(
  css({
    display: 'block',
    pl: 'xLarge',
    textIndent: '-24px',
  }),
  compose(space)
);

export const StyledDD = styled.dd(
  css({
    pl: 'xLarge',
  })
);

export const StyledDT = styled.dt(
  css({
    pt: 'large',
  })
);
