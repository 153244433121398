import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useUpdateSessionHold } from '../../../api/Session-hold';
import { useClientStore } from '../../../store/store';
import { Content, Footer, Header } from '../../ModalComposition';
import Button from '../../Button';
import Modal from '../../Modal';
import Typography from '../../Typography';
import { L10n } from '../../L10n';

const AppointmentExpirationModal = ({ startTimer = false, msToOpen = 1200000 }) => {
  const { hideModal, showModal, updateModal, modalTimer, reset, updateUiState } = useClientStore(
    (state) => ({
      hideModal: state.hideModal,
      showModal: state.showModal,
      updateModal: state.updateModal,
      reset: state.reset,
      modalTimer: state.uiState.modalTimer,
      updateUiState: state.updateUiState,
    })
  );
  const navigate = useNavigate();
  const updateHold = useUpdateSessionHold();

  const handleClose = () => {
    hideModal();
    navigate('/dob');
    reset();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (startTimer && !modalTimer) {
      const timeout = setTimeout(() => {
        updateModal('appointmentExpirationModal');
        updateHold.mutate();
        showModal();
      }, msToOpen);
      updateUiState('modalTimer', timeout);
    } else if (!startTimer && modalTimer) {
      clearTimeout(modalTimer);
    }
  }, [startTimer, msToOpen, showModal, updateModal, updateHold, modalTimer, updateUiState]);

  return (
    <Modal
      canCloseOnClickOutside={false}
      header={
        <Header>
          <L10n tokenID="modals.apptHoldExpiration.header" />
        </Header>
      }
      content={
        <Content>
          <Typography variant={['primaryBase']} space={{ pb: 'medium' }}>
            <L10n tokenID="modals.apptHoldExpiration.warning" />
          </Typography>
          <Typography variant={['primaryBase']}>
            <L10n tokenID="modals.apptHoldExpiration.warningTwo" />
          </Typography>
        </Content>
      }
      footer={
        <Footer variant="single">
          <Button handleClick={handleClose} data-testid="start-over">
            <L10n tokenID="modals.apptHoldExpiration.startOver" />
          </Button>
        </Footer>
      }
    />
  );
};

AppointmentExpirationModal.propTypes = {
  msToOpen: PropTypes.number,
  startTimer: PropTypes.bool,
};

export default AppointmentExpirationModal;
