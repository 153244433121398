import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

const FormatDayOfWeek = ({ dateStr, format = 'long' }) => {
  return <FormattedDate value={new Date(dateStr)} timeZone="UTC" weekday={format} />;
};

FormatDayOfWeek.propTypes = {
  dateStr: PropTypes.string.isRequired,
  format: PropTypes.oneOf(['long', 'short']),
};

export default FormatDayOfWeek;
