import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Shimmer from '../Shimmer';
import Typography from '../Typography';

const FilterButton = ({
  activeFilter,
  children,
  filterName,
  handleClick,
  loading = false,
  type,
  size = 'tertiaryFill',
}) => {
  const styling = () => {
    const isActive = activeFilter === filterName && activeFilter && filterName;
    if (type === 'available') {
      return isActive ? 'base' : 'outlinedMinor';
    }
    return isActive ? 'disableActive' : 'disable';
  };
  const SquareFilter = () => {
    return loading ? (
      <Shimmer height="56px" borderRadius="square" />
    ) : (
      <Button styling={styling()} size={size} handleClick={handleClick}>
        <Typography variant="primaryXSmall" color={{ color: 'inherit' }}>
          {children}
        </Typography>
      </Button>
    );
  };

  const PillFilter = () => {
    return loading ? (
      <Shimmer height="31px" borderRadius="circular" />
    ) : (
      <Button styling={styling()} size={size} handleClick={handleClick}>
        {children}
      </Button>
    );
  };

  return size === 'tertiaryFill' ? <PillFilter /> : <SquareFilter />;
};

FilterButton.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  children: PropTypes.node,
  filterName: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['tertiaryFill', 'square']),
  type: PropTypes.oneOf(['available', 'unavailable']),
};

export default FilterButton;
