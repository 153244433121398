import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

export const L10nProvider = ({ locale, messages, children }) => (
  <IntlProvider locale={locale} messages={messages}>
    {children}
  </IntlProvider>
);

L10nProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object, // eslint-disable-line
  children: PropTypes.node.isRequired,
};
