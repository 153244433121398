import React from 'react';
import PropTypes from 'prop-types';
import { icons } from './icons';
import { StyledDiv, StyledSvg } from './styles';
import themes from '../../themes';

const Icon = ({ color, hoverColor, icon, scale = [1], styling, title }) => {
  const { d, height, viewBox, width } = icons[icon];
  const scaledDimension = (dimension) => {
    const arr = [];
    for (let i = 0; i < themes.breakpoints.length; i += 1) {
      arr[i] = dimension * (scale[i] ? scale[i] : scale[scale.length - 1]);
    }
    return arr;
  };
  return (
    <StyledDiv
      className={styling}
      height={scaledDimension(height)}
      width={scaledDimension(width)}
      $color={color}
      $hoverColor={hoverColor}
    >
      <StyledSvg
        aria-hidden={!title && true}
        clipRule="evenodd"
        fillRule="evenodd"
        role={title && 'img'}
        title={title && title}
        viewBox={viewBox}
      >
        {d.map((dVal) => (
          <path key={dVal} d={dVal} />
        ))}
      </StyledSvg>
    </StyledDiv>
  );
};

Icon.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  icon: PropTypes.oneOf([
    'calendar',
    'check',
    'chevron',
    'close',
    'clock',
    'expandLess',
    'expandMore',
    'filter',
    'search',
    'timer',
    'guardian',
    'home',
    'idCard',
    'info',
    'locationPin',
    'printer',
    'alert',
    'checkboxActive',
    'shirt',
    'timer',
    'chevronLeft',
    'camera',
    'checkCircle',
    'trashCan',
    'progressBarCheck',
    'rightProgression',
    'leftProgression',
  ]),
  scale: PropTypes.arrayOf(PropTypes.number),
  styling: PropTypes.oneOf([
    'base',
    'error',
    'outlined',
    'mutedBlue',
    'mutedColdbrew',
    'plainBlue',
    'plainColdbrew',
    'success',
  ]),
  title: PropTypes.string,
};

export default Icon;
