const desktop = {
  transform: 'translateX(-100%)',
  '&.expanded': {
    opacity: '1',
    transform: 'translateX(0%)',
  },
};

const mobile = {
  transform: 'translateY(-100%)',
  '&.expanded': {
    opacity: '1',
    transform: 'translateY(0%)',
  },
};

export const variants = {
  desktop,
  mobile,
};
