import * as React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';
import {
  StyledToastContainer,
  TitleContainer,
  ToastBody,
  ToastClose,
  ToastIcon,
  ToastTitle,
  ToastContent,
  ToastFlex,
} from './styles';
import Card from '../Card';
import Icon from '../Icon';
import Typography from '../Typography';
import themes from '../../themes';

export const ToastContainer = () => {
  return <StyledToastContainer closeButton={false} />;
};

export const Toast = ({ title, variant, message, allowClose }) => {
  const closeToast = () => {
    toast.dismiss();
  };

  return (
    <Card variant={variant} margin="none">
      <ToastFlex>
        <ToastIcon>
          {variant === 'success' ? <Icon icon="checkCircle" /> : <Icon icon="alert" />}
        </ToastIcon>
        <ToastContent>
          <TitleContainer>
            <ToastTitle>
              <Typography variant={['secondaryMedium']}>{title}</Typography>
            </ToastTitle>
            {allowClose && (
              <ToastClose aria-label="close" type="button" onClick={closeToast} autoClose={2000}>
                <Icon icon="close" color={themes.colors.rxInteractionBlue} scale={[0.8]} />
              </ToastClose>
            )}
          </TitleContainer>
          <ToastBody>
            <Typography variant={['primaryMedium']}>{message}</Typography>
          </ToastBody>
        </ToastContent>
      </ToastFlex>
    </Card>
  );
};

Toast.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  message: PropTypes.node,
  allowClose: PropTypes.bool,
};

export const showToast = (
  title,
  variant,
  position,
  message,
  allowClose = true,
  autoClose = false,
  onClose
) => {
  toast(
    <Toast
      title={title}
      variant={variant}
      message={message}
      allowClose={allowClose}
      autoClose={autoClose}
    />,
    {
      autoClose,
      position,
      closeOnClick: false,
      draggable: false,
      onClose,
    }
  );
};
