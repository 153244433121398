import css from '@styled-system/css';
import styled from 'styled-components';
import { Box } from '../Box';

export const StyledDivider = styled(Box)`
  ${css({
    height: '1px',
    bg: 'coldBrews.200',
  })}
`;
